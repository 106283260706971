import { useState, useCallback } from 'react';
import { Modal, Form } from 'react-bootstrap';

export const useFilters = (initialFilters) => {
    const [filters, setFilters] = useState(initialFilters);

    const handleFilterChange = useCallback((filterType, value, isChecked) => {
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };

            // Ensure the filterType exists and is an array
            if (!Array.isArray(prevFilters[filterType])) {
                updatedFilters[filterType] = [];
            }

            if (isChecked) {
                updatedFilters[filterType] = [...new Set([...updatedFilters[filterType], value])];
            } else {
                updatedFilters[filterType] = updatedFilters[filterType].filter((item) => item !== value);
            }

            return updatedFilters;
        });
    }, []);

    const applyFilters = useCallback((items) => {
        return items.filter((item) => {
            return Object.keys(filters).every((filterType) => {
                const filterValues = filters[filterType];
                if (!filterValues.length) return true; // No filters applied for this filterType
                const itemAttributes = item.jsonData?.attributes || [];
                const matchingAttribute = itemAttributes.find((attr) => attr.trait_type === filterType);
                return matchingAttribute && filterValues.includes(matchingAttribute.value);
            });
        });
    }, [filters]);


    const resetFilters = useCallback(() => {
        setFilters(initialFilters);
    }, [initialFilters]);

    return { filters, handleFilterChange, applyFilters, resetFilters };
};

export const useSearch = (initialQuery = '') => {
    const [query, setQuery] = useState(initialQuery);

    const handleSearchChange = useCallback((event) => setQuery(event.target.value.toLowerCase()), []);

    const filterBySearch = useCallback((data) => {
        if (!query) return data;
        return data.filter((item) => item?.metadata?.title?.toLowerCase().includes(query));
    }, [query]);

    return { query, handleSearchChange, filterBySearch };
};

export const MarketFilterModal = ({
    show,
    onHide,
    onFilterChange,
    onResetFilters,
    filters,
    tokens,
}) => {
    const tokenArray = Object.values(tokens).flat(); // Convert tokens object to array
    const attributeValues = tokenArray.reduce((acc, token) => {
        token?.nftDetails?.jsonData?.attributes?.forEach(attr => {
            if (!acc[attr.trait_type]) acc[attr.trait_type] = new Set();
            acc[attr.trait_type].add(attr.value);
        });
        return acc;
    }, {});

    // Convert Sets to Arrays and ensure we can handle empty cases gracefully
    const entries = Object.entries(attributeValues || {}).map(([key, valueSet]) => [key, Array.from(valueSet)]);

    return (
        <Modal centered keyboard={true} show={show} onHide={onHide} backdrop="static" className="exclusive-modal">
            <Modal.Header closeButton>
                <Modal.Title>Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="row-style">
                        {entries.length > 0 ? entries.map(([traitType, values]) => (
                            <Form.Group key={traitType} controlId={`formGroup-${traitType}`} className="group-style">
                                <Form.Label className="label-style">{traitType}</Form.Label>
                                <div className="filter-items">
                                    {values.map((value, index) => {
                                        const checkboxId = `checkbox-${traitType}-${index}`;
                                        return (
                                            <Form.Check
                                                key={checkboxId}
                                                id={checkboxId}
                                                type="checkbox"
                                                label={value}
                                                value={value}
                                                onChange={(e) => onFilterChange(traitType, value, e.target.checked)}
                                                checked={filters[traitType]?.includes(value)}
                                            />
                                        );
                                    })}
                                </div>
                            </Form.Group>
                        )) : <p>No attributes available for filtering.</p>}
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div className="CloseImageButton" onClick={onHide}>
                    Close
                </div>
                <div className="SubmitImageButton" onClick={onResetFilters}>
                    Reset
                </div>
            </Modal.Footer>
        </Modal>
    );
};

