import { utils as nearUtils } from 'near-api-js';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

// const BN = require("bn.js");

export const truncateAddress = (address) => {
    // Check if the address is null or undefined
    if (!address) {
        return '-'; // or any other placeholder text you prefer
    }

    // Check if the address ends with a specific domain, e.g., ".near"
    if (typeof address === 'string' && (address.endsWith('.near') || address.endsWith('.testnet'))) {
        // If it's a meaningful address, return it as is
        return address;
    } else {
        // If it's not a string, return null
        return null;
    }

    // // Check if the address ends with a specific domain, e.g., ".near"
    // if (typeof address === 'string' && (address.endsWith('.near') || address.endsWith('.testnet'))) {
    //     // If it's a meaningful address, return it as is
    //     return address;
    // } else if (typeof address === 'string') {
    //     // If it's a long alphanumeric address, truncate it
    //     return `${address.slice(0, 4)}...${address.slice(-4)}`;
    // } else {
    //     // If it's not a string, return 'N/A' or throw an error
    //     return 'N/A'; // or throw new Error('Address must be a string');
    // }
}

export const convertWeiToMegaEther = (weiValue) => {
    const bigNumberValue = new BigNumber(weiValue);
    return parseFloat(Web3.utils.fromWei(bigNumberValue.toFixed(), 'mether'));
};

// yoctoNEAR yoctoNEAR (the smallest unit of NEAR)
export const nearToYocto = (nearAmount) => {
    // Convert the nearAmount to a string if it's not already, to handle large numbers
    const amountAsString = typeof nearAmount === 'string' ? nearAmount : nearAmount.toString();

    try {
        // Convert NEAR to yoctoNEAR using near-api-js utils
        return nearUtils.format.parseNearAmount(amountAsString);
    } catch (error) {
        console.error('Error converting NEAR to yoctoNEAR:', error);
        throw error; // Re-throw the error to handle it in the calling function
    }
};

export const formatDate = (timestamp) => {
    if (typeof timestamp !== 'number') {
        return 'Invalid timestamp'; // Optionally handle this case differently
    }

    // Assuming the timestamp is in nanoseconds, convert it to milliseconds for JavaScript Date
    const date = new Date(timestamp); // milliseconds

    // Format the date to a more readable form (e.g., "Day Mon Year")
    return date.toLocaleDateString('en-US', {
        weekday: 'long', // "Monday"
        year: 'numeric', // "2021"
        month: 'long',   // "July"
        day: 'numeric'   // "20"
    });
};
