import { convertWeiToMegaEther } from "../../../utils";
import { replaceIpfsUrl } from "../../Home/Home";

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;
const MARKET_CONTRACT_NAME = process.env.MARKET_CONTRACT_NAME;

export const fetchNftSalesByContractId = async (viewMethod) => {
    try {
        const sales = await viewMethod(MARKET_CONTRACT_NAME, 'get_sales_by_nft_contract_id', {
            nft_contract_id: NFT_CONTRACT_NAME,
            from_index: '0',
            limit: 99
        });
        return sales;
    } catch (error) {
        console.error('Failed to fetch NFT sales:', error);
        return [];
    }
};

export const fetchNftSalesByownerId = async (viewMethod, signedAccountId) => {
    try {
        const sales = await viewMethod(MARKET_CONTRACT_NAME, 'get_sales_by_owner_id', {
            account_id: signedAccountId,
            from_index: '0',
            limit: 99
        });

        return sales;
    } catch (error) {
        console.error('Failed to fetch NFT sales:', error);
        return [];
    }
};

export const fetchNftPrice = async (viewMethod, token_id) => {
    try {
        const sales = await fetchNftSalesByContractId(viewMethod);
        const sale = sales.find((sale) => sale.token_id === token_id);
        if (sale) {
            const formattedListPrice = parseFloat(convertWeiToMegaEther(sale.sale_conditions)).toFixed(5);
            return { listedPrice: formattedListPrice, isListedForSale: true };
        } else {
            const seriesPrice = await viewMethod(NFT_CONTRACT_NAME, 'get_nft_price', { token_id });
            const formattedSeriesPrice = parseFloat(convertWeiToMegaEther(seriesPrice)).toFixed(5);
            return { seriesPrice: formattedSeriesPrice, isListedForSale: false };
        }
    } catch (error) {
        console.error('Failed to fetch NFT price:', error);
        return null;
    }
};

