import React, { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import { convertWeiToMegaEther, nearToYocto } from '../../utils';
import { NftContractModal } from './Components/NftModal';
import { BsCheckCircle, BsFilter, BsSearch, BsXCircle } from 'react-icons/bs';
import { MarketFilterModal, useFilters, useSearch } from './Components/useSearchFilter';
import { fetchNftDetails } from '../Home/useFetchNFTDetails';
import NearIcon from '../../assets/NearIcon.svg';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;
const MARKET_CONTRACT_NAME = process.env.MARKET_CONTRACT_NAME;

const TabsComponent = ({ activeTab, setActiveTab }) => {
    return (
        <div className="tabs-container">
            <button className={`tab-button-global ${activeTab === 'received' ? 'active' : ''}`}
                onClick={() => setActiveTab('received')}>
                Received Offers
            </button>
            <button className={`tab-button-my ${activeTab === 'sent' ? 'active' : ''}`}
                onClick={() => setActiveTab('sent')}>
                Sent Offers
            </button>
        </div>
    );
};

const OffersContent = () => {
    const initialFilters = useMemo(() => ({ Mood: [], Weather: [], City: [], Shared: [] }), []);
    const { filters, handleFilterChange, applyFilters, resetFilters } = useFilters(initialFilters);
    const { query, handleSearchChange, filterBySearch } = useSearch();
    const { signedAccountId, viewMethod, callMethod } = useWallet();
    const [receivedOffers, setReceivedOffers] = useState([]);
    const [sentOffers, setSentOffers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [nftModalVisible, setNFTModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('received');
    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);

    const [selectedNFT, setSelectedNFT] = useState(null);

    // State for modals
    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [modalTokenId, setModalTokenId] = useState('');
    const [modalBuyerId, setModalBuyerId] = useState('');
    const [modalNewAmount, setModalNewAmount] = useState(0);

    const toggleFilterModal = useCallback(() => {
        setIsFilterModalVisible(prevState => !prevState);
    }, []);

    const fetchJsonData = useCallback(async (url) => {
        if (!url) return null;
        try {
            const response = await fetch(url);
            return await response.json();
        } catch (error) {
            console.error("Error fetching JSON data:", error);
            return null;
        }
    }, []);

    const handleNFTClick = useCallback(async (nft) => {
        if (!nft.jsonData) {
            const modifiedUrl = replaceIpfsUrl(nft.metadata.reference);
            const jsonData = await fetchJsonData(modifiedUrl);
            nft = { ...nft, jsonData: jsonData || {} };
        }
        setSelectedNFT(nft);
        setNFTModalVisible(true);
    }, [fetchJsonData]);

    const handleAcceptOffer = async (callMethod, tokenId, buyerId) => {
        try {
            const result = await callMethod(MARKET_CONTRACT_NAME, 'accept_received_offer', { nft_contract_id: NFT_CONTRACT_NAME, token_id: tokenId, buyer_id: buyerId }, '300000000000000', '1');
            console.log('Offer accepted:', result);
        } catch (error) {
            console.error('Error accepting offer:', error);
        }
    };

    const handleDenyOffer = async (callMethod, tokenId, buyerId) => {
        try {
            const result = await callMethod(MARKET_CONTRACT_NAME, 'deny_received_offer', { nft_contract_id: NFT_CONTRACT_NAME, token_id: tokenId, buyer_id: buyerId }, '300000000000000', '1');
            console.log('Offer denied:', result);
        } catch (error) {
            console.error('Error denying offer:', error);
        }
    };

    const handleModifyOffer = async (callMethod, tokenId, newAmount) => {
        console.log("nearToYocto", newAmount);

        try {
            const result = await callMethod(MARKET_CONTRACT_NAME, 'modify_sent_offer', { nft_contract_id: NFT_CONTRACT_NAME, token_id: tokenId }, '300000000000000', nearToYocto(newAmount));
            console.log('Offer modified:', result);
        } catch (error) {
            console.error('Error modifying offer:', error);
        }
    };

    const handleCancelOffer = async (callMethod, tokenId) => {
        try {
            const result = await callMethod(MARKET_CONTRACT_NAME, 'cancel_sent_offer', { nft_contract_id: NFT_CONTRACT_NAME, token_id: tokenId }, '300000000000000', '1');
            console.log('Offer cancelled:', result);
        } catch (error) {
            console.error('Error cancelling offer:', error);
        }
    };

    // Function to open modal with details
    const openModal = useCallback((action, tokenId, buyerId, newAmount) => {
        setModalAction(action);
        setModalTokenId(tokenId);
        setModalBuyerId(buyerId);
        setModalNewAmount(newAmount);
        setShowModal(true);
    }, []);

    // Handling the confirm action of the modal
    const confirmModalAction = useCallback(async () => {
        setShowModal(false);
        if (modalAction === 'accept') {
            await handleAcceptOffer(callMethod, modalTokenId, modalBuyerId);
        } else if (modalAction === 'deny') {
            await handleDenyOffer(callMethod, modalTokenId, modalBuyerId);
        } else if (modalAction === 'modify') {
            await handleModifyOffer(callMethod, modalTokenId, modalNewAmount);
        } else if (modalAction === 'cancel') {
            await handleCancelOffer(callMethod, modalTokenId);
        }
    }, [callMethod, modalAction, modalTokenId, modalBuyerId, modalNewAmount, handleAcceptOffer, handleDenyOffer, handleModifyOffer, handleCancelOffer]);

    // Fetch offers using viewMethod
    useEffect(() => {
        const fetchOffers = async () => {
            setIsLoading(true);
            try {
                const [received, sent] = await Promise.all([
                    viewMethod(MARKET_CONTRACT_NAME, 'get_received_offers', { owner_id: signedAccountId }),
                    viewMethod(MARKET_CONTRACT_NAME, 'get_sent_offers', { bidder_id: signedAccountId })
                ]);

                const receivedWithDetails = await enrichOffersWithDetails(received);
                const sentWithDetails = await enrichOffersWithDetails(sent);

                setReceivedOffers(groupOffersByTokenId(receivedWithDetails));
                setSentOffers(groupOffersByTokenId(sentWithDetails));
            } catch (error) {
                console.error("Error fetching offers:", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (signedAccountId && viewMethod) {
            fetchOffers();
        }
    }, [signedAccountId, viewMethod]);

    const enrichOffersWithDetails = async (offers) => {
        return Promise.all(offers.map(async (offer) => ({
            ...offer,
            nftDetails: await fetchNftDetails(viewMethod, offer.token_id, fetchJsonData)
        })));
    };

    const groupOffersByTokenId = (offers) => {
        return offers.reduce((acc, offer) => {
            (acc[offer.token_id] = acc[offer.token_id] || []).push(offer);
            return acc;
        }, {});
    };

    // Render function for sent offers
    const renderSentOffers = useCallback((offers, filters, applyFilters, query, filterBySearch) => {
        const filteredOffers = applyFilters(filterBySearch(Object.values(offers), query), filters);
        // console.log("renderSentOffers", filteredOffers);
        return filteredOffers.length > 0 ? (
            <div className="offers-container">
                {Object.keys(filteredOffers).map(tokenId => {
                    const offersGroup = filteredOffers[tokenId];
                    const nftDetails = offersGroup[0].nftDetails;
                    const nftMedia = nftDetails?.metadata?.media;
                    const nftTitle = nftDetails?.metadata?.title;
                    const saleCondition = parseFloat(convertWeiToMegaEther(offersGroup[0].sale_conditions)).toFixed(5);

                    return (
                        <div className="offer-card" key={tokenId}>
                            <div className="offer-image">
                                <LazyLoadImage
                                    alt="NFT"
                                    effect="blur"
                                    src={nftMedia}
                                    placeholderSrc={nftMedia}
                                    className="offer-image"
                                />
                            </div>

                            <div className="offer-nft-detail">
                                <h2 className="nft-title">{nftTitle}</h2>
                                {/* No map */}
                                <p className="nft-price">{saleCondition} <LazyLoadImage alt="Near Icon" effect="blur" src={NearIcon} placeholderSrc={NearIcon} /></p>
                                <button className="view-nft-button" onClick={() => handleNFTClick(nftDetails)}>Show NFT</button>
                            </div>

                            <div className="offer-info-container">
                                {offersGroup.map((offer, index) => {
                                    const owner_id = offer.owner_id;
                                    const token_id = offer.token_id;
                                    const saleCondition = parseFloat(convertWeiToMegaEther(offer.sale_conditions)).toFixed(5);
                                    const offeredAmount = parseFloat(convertWeiToMegaEther(offer.amount)).toFixed(5);
                                    const percentageChange = ((offeredAmount - saleCondition) / saleCondition * 100).toFixed(0) + '%';
                                    const formattedOfferedTime = offer.timestamp ? moment(offer.timestamp).format("MMM. D, YYYY h:mm A") : "Not recorded";

                                    return (
                                        <div className="offers" key={index}>
                                            <p className="offer-date">{formattedOfferedTime}</p>
                                            <p className="offer-bidder">{owner_id}</p>
                                            <p className="offer-amount">{offeredAmount} <LazyLoadImage alt="Near Icon" effect="blur" src={NearIcon} placeholderSrc={NearIcon} /></p>
                                            <div className="offer-profit">
                                                {percentageChange}
                                                {/* INJA BAYAD AVAL OFFER GHABLI DELETE SHAVAD VA DEPOSIT BE USER BARGARDANDE SHAVAD */}
                                                <p className="modify-btn" onClick={() => openModal('modify', token_id, offeredAmount)}>Modify</p>
                                                <p className="deny-btn" onClick={() => openModal('cancel', token_id)}>Cancel</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        ) : <p>No Offers found</p>;
    }, [handleNFTClick, openModal]);

    // Render function for received offers
    const renderReceivedOffers = useCallback((offers, filters, applyFilters, query, filterBySearch) => {
        const filteredOffers = applyFilters(filterBySearch(Object.values(offers), query), filters);
        // console.log("renderReceivedOffers", filteredOffers);
        return filteredOffers.length > 0 ? (
            <div className="offers-container">
                {Object.keys(filteredOffers).map(tokenId => {
                    const offersGroup = filteredOffers[tokenId];
                    const nftDetails = offersGroup[0].nftDetails;
                    const nftMedia = nftDetails?.metadata?.media;
                    const nftTitle = nftDetails?.metadata?.title;
                    const saleCondition = parseFloat(convertWeiToMegaEther(offersGroup[0].sale_conditions)).toFixed(5);

                    return (
                        <div className="offer-card" key={tokenId}>
                            <div className="offer-image">
                                <img
                                    className="offer-image"
                                    src={nftMedia}
                                    alt={"NFT"}
                                    loading="lazy"
                                />
                            </div>

                            <div className="offer-nft-detail">
                                <h2 className="nft-title">{nftTitle}</h2>
                                {/* No map */}
                                <p className="nft-price">{saleCondition} <LazyLoadImage alt="Near Icon" effect="blur" src={NearIcon} placeholderSrc={NearIcon} /></p>
                                <button className="view-nft-button" onClick={() => handleNFTClick(nftDetails)}>Show NFT</button>
                            </div>

                            <div className="offer-info-container">
                                {offersGroup.map((offer, index) => {
                                    const bidder = offer.bidder;
                                    const token_id = offer.token_id;
                                    const saleCondition = parseFloat(convertWeiToMegaEther(offer.sale_conditions)).toFixed(5);
                                    const offeredAmount = parseFloat(convertWeiToMegaEther(offer.amount)).toFixed(5);
                                    const percentageChange = ((offeredAmount - saleCondition) / saleCondition * 100).toFixed(0) + '%';
                                    const formattedOfferedTime = offer.timestamp ? moment(offer.timestamp).format("MMM. D, YYYY h:mm A") : "Not recorded";

                                    return (
                                        <div className="offers" key={index}>
                                            <p className="offer-date">{formattedOfferedTime}</p>
                                            <p className="offer-bidder">{bidder}</p>
                                            <p className="offer-amount">{offeredAmount} <LazyLoadImage alt="Near Icon" effect="blur" src={NearIcon} placeholderSrc={NearIcon} /></p>
                                            <div className="offer-profit">
                                                {percentageChange}
                                                <p className="accept-btn" onClick={() => openModal('accept', token_id, bidder)}>Accept</p>
                                                <p className="deny-btn" onClick={() => openModal('deny', token_id, bidder)}>Deny</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        ) : <p>No Offers found</p>;
    }, [handleNFTClick, openModal]);

    // Confirmation Modal
    const ConfirmationModal = () => {
        return (
            <Modal centered show={showModal} onHide={() => setShowModal(false)} className="exclusive-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Confirm {modalAction}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to {modalAction} this offer?
                    {modalAction === 'modify' && (
                        <input
                            className="salePrice-form-control"
                            type="number"
                            step="0.00001"
                            id="salePrice"
                            min={0}
                            value={modalNewAmount}
                            onChange={(e) => setModalNewAmount(e.target.value)}
                            placeholder="Enter new amount"
                        />
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <div className="CloseImageButton" onClick={() => setShowModal(false)}>
                        <BsXCircle /> Close
                    </div>
                    <div className="SubmitImageButton" onClick={confirmModalAction}>
                        <BsCheckCircle /> Confirm {modalAction}
                    </div>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div className="user-container">
            <div className="user-nfts">
                <TabsComponent activeTab={activeTab} setActiveTab={setActiveTab} />
                <div className="SearchContainer">
                    <div className="SearchBox">
                        <input
                            type="text"
                            placeholder="Search for offers"
                            value={query}
                            onChange={handleSearchChange}
                        />
                        <div className="SearchButton">
                            <BsSearch />
                        </div>
                    </div>
                    <div className="FunnelBox">
                        <div className="FunnelButton" onClick={toggleFilterModal}>
                            <BsFilter />
                        </div>
                    </div>
                </div>
            </div>

            <div className="user-collections">
                <div className="container-tags">
                    <h1 className="artwork-tag">{activeTab === 'received' ? 'Received Offers' : 'Sent Offers'}</h1>
                </div>
                {isLoading ? (
                    <div className="spinner-backdrop">
                        <div className="spinner-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                ) : activeTab === 'received' ? renderReceivedOffers(receivedOffers, filters, applyFilters, query, filterBySearch) : renderSentOffers(sentOffers, filters, applyFilters, query, filterBySearch)}
            </div>

            <ConfirmationModal />

            <NftContractModal
                show={nftModalVisible}
                onHide={() => setNFTModalVisible(false)}
                nft={selectedNFT}
            />

            <MarketFilterModal
                show={isFilterModalVisible}
                onHide={toggleFilterModal}
                onFilterChange={handleFilterChange}
                onResetFilters={resetFilters}
                filters={filters}
                tokens={activeTab === 'received' ? receivedOffers : sentOffers}
            />
        </div>
    );
};

export default OffersContent;
