const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;
const MARKET_CONTRACT_NAME = process.env.MARKET_CONTRACT_NAME;

export const yoctoNear = (near) => {
    if (isNaN(near) || near <= 0) {
        throw new Error("Invalid price");
    }
    return BigInt(near * Math.pow(10, 24)).toString();
};

export const approveNFT = async (callMethod, token_id, price, marketplace) => {
    try {
        const approvalMsg = JSON.stringify({ sale_conditions: yoctoNear(price).toString() });
        const marketContract = marketplace === 'moodweather' ? MARKET_CONTRACT_NAME : marketplace;
        await callMethod(NFT_CONTRACT_NAME, "nft_approve", {
            token_id,
            account_id: marketContract,
            msg: approvalMsg,
        }, '300000000000000', yoctoNear(0.001));
    } catch (error) {
        console.error('Error approving NFT:', error);
        throw error;
    }
};

export const updateNFTPrice = async (callMethod, token_id, price) => {
    try {
        await callMethod(MARKET_CONTRACT_NAME, "update_price", {
            nft_contract_id: NFT_CONTRACT_NAME,
            token_id,
            price: yoctoNear(price).toString(),
        }, '300000000000000', '1');
    } catch (error) {
        console.error('Error updating NFT price:', error);
        throw error;
    }
};

export const removeSale = async (callMethod, token_id) => {
    try {
        await callMethod(MARKET_CONTRACT_NAME, "remove_sale", {
            nft_contract_id: NFT_CONTRACT_NAME,
            token_id,
        }, '300000000000000', '1');
    } catch (error) {
        console.error('Error cancelling sale:', error);
        throw error;
    }
};

export const makeOffer = async (callMethod, token_id, price) => {
    try {
        await callMethod(MARKET_CONTRACT_NAME, "make_offer", {
            nft_contract_id: NFT_CONTRACT_NAME,
            token_id,
        }, '300000000000000', yoctoNear(price));
    } catch (error) {
        console.error('Error making offer:', error);
        throw error;
    }
};
