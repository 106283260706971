import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { BsSearch, BsFilter, BsChevronLeft, BsPerson, BsTags } from 'react-icons/bs';
import NearIcon from '../../assets/NearIcon.svg';
import { NftContractModal } from './Components/NftModal';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import moment from 'moment';
import { useFilters, useSearch } from './Components/useSearchFilter';
import { FilterModal } from '../UserProfile/UserProfile';
import { truncateAddress } from '../../utils';
import { useFetchAllUsersInfo } from '../UserProfile/useFetchUserInfo';
import { replaceIpfsUrl } from '../Home/Home';
import { fetchNftPrice } from './Components/priceUtils';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ExclusiveSpinner from '../../Components/ExclusiveSpinner';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

const CollectionDetail = () => {
    const initialFilters = useMemo(() => ({ Mood: [], Weather: [], City: [], Shared: [], }), []);
    const navigate = useNavigate();
    const handleBack = () => { navigate(-1); };
    const { viewMethod } = useWallet();
    const [isLoading, setIsLoading] = useState(true);
    const [collectionDetails, setCollectionDetails] = useState({});
    const [collectionTokens, setCollectionTokens] = useState([]);
    const [selectedNFT, setSelectedNFT] = useState(null);
    const [nftModalVisible, setNFTModalVisible] = useState(false);
    const location = useLocation();
    const { seriesId } = location.state || {};
    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
    const [nftPrices, setNftPrices] = useState({});
    const [columns, setColumns] = useState(6);

    const { filters, handleFilterChange, applyFilters, resetFilters } = useFilters(initialFilters);
    const { query, handleSearchChange, filterBySearch } = useSearch();
    const { otherUsersPlanColor, otherUsersAvatar } = useFetchAllUsersInfo(collectionDetails.owner_id);

    const handleNFTClick = async (nft) => {
        if (!nft.jsonData) {
            const modifiedUrl = replaceIpfsUrl(nft.metadata.reference);
            const jsonData = await fetchJsonData(modifiedUrl);
            nft = { ...nft, jsonData: jsonData || {} };
        }
        setSelectedNFT(nft);
        setNFTModalVisible(true);
    };

    const toggleFilterModal = () => {
        setIsFilterModalVisible(!isFilterModalVisible);
    };

    const updateFilters = (filterType, value, isChecked) => {
        handleFilterChange(filterType, value, isChecked);
    };

    useEffect(() => {
        if (selectedNFT?.metadata?.reference) {
            const modifiedUrl = replaceIpfsUrl(selectedNFT.metadata.reference);
            fetchJsonData(modifiedUrl);
        }
    }, [selectedNFT]);

    const fetchJsonData = async (url) => {
        if (!url) return null;
        try {
            const response = await fetch(url);
            return await response.json();
        } catch (error) {
            console.error("Error fetching JSON data:", error);
            return null;
        }
    };

    useEffect(() => {
        if (viewMethod && seriesId) {
            const fetchCollectionDetails = async () => {
                setIsLoading(true);
                try {
                    const details = await viewMethod(NFT_CONTRACT_NAME, "get_series_details", { id: seriesId.toString() });
                    setCollectionDetails(details);
                } catch (error) {
                    console.error('Error fetching collection details:', error);
                } finally {
                    setIsLoading(false);
                }
            }
            fetchCollectionDetails();
        }
    }, [viewMethod, seriesId]);

    useEffect(() => {
        if (viewMethod && seriesId) {
            const fetchCollectionData = async () => {
                setIsLoading(true);
                try {
                    const tokens = await viewMethod(NFT_CONTRACT_NAME, "nft_tokens_for_series", { id: seriesId.toString(), from_index: null, limit: null });

                    // Ensure jsonData is attached to each token
                    const tokensWithJson = await Promise.all(tokens.map(async (token) => {
                        if (!token.jsonData) {
                            const modifiedUrl = replaceIpfsUrl(token.metadata.reference);
                            const jsonData = await fetchJsonData(modifiedUrl);
                            return { ...token, jsonData: jsonData || {} };
                        }
                        return token;
                    }));

                    setCollectionTokens(tokensWithJson.reverse());

                    // Fetch prices for all NFTs in the collection
                    const prices = {};
                    for (const token of tokensWithJson) {
                        const priceData = await fetchNftPrice(viewMethod, token.token_id);
                        if (priceData) {
                            prices[token.token_id] = priceData;
                        }
                    }
                    setNftPrices(prices);
                } catch (error) {
                    console.error('Error fetching serie tokens:', error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchCollectionData();
        }
    }, [viewMethod, seriesId]);

    // Apply filters to the collection tokens
    const filteredCollectionTokens = useMemo(() => {
        const searchFiltered = filterBySearch(collectionTokens);
        return applyFilters(searchFiltered);
    }, [collectionTokens, query, filters]);

    // console.log("NFTs:", serieTokens);

    const collectionTitle = collectionDetails?.metadata?.title;
    const issueDate = collectionDetails?.metadata?.issued_at;
    const convertDate = issueDate ? moment(issueDate).format("MMM. D, YYYY") : "Not recorded";
    const uniqueMinters = new Set(collectionTokens.map(token => token.owner_id));
    const numberOfItems = collectionTokens.length;
    const numberOfMinters = uniqueMinters.size;
    const modifiedUrl = replaceIpfsUrl(collectionDetails?.metadata?.media);

    // in case of nothing exists inside the collection
    if (!numberOfItems && !isLoading) {
        return <div>No items found in this collection...</div>;
    }

    const getCardWidth = () => {
        switch (columns) {
            case 3:
                return '31.25%';
            case 4:
                return '23.05%';
            case 5:
                return '18.15%';
            case 6:
                return '14.85%';
            default:
                return '31.25%'
        }
    }

    return (
        <>
            <div className="CollectionMainContainer">
                {isLoading ? (
                    <ExclusiveSpinner />
                ) : (
                    <>
                        {collectionDetails && collectionDetails?.metadata?.title && (
                            <>
                                <div className="backward-container">
                                    <div className="backward-box">
                                        <div className="backward-button" onClick={handleBack}>
                                            <BsChevronLeft />
                                        </div>
                                        <span className="collection-title">Back</span>
                                    </div>

                                    <span className="collection-title-boeard">{collectionTitle}</span>

                                </div>

                                <div className="collection-container">
                                    <div className="card">
                                        <div className="collection-image">
                                            <LazyLoadImage alt={collectionTitle} effect="blur" src={`${modifiedUrl}`} placeholderSrc={`${modifiedUrl}`} />
                                        </div>
                                        <div className="collection-separator">
                                            <div className="collection-stats">
                                                <div className="stat-container creator">
                                                    <span className="tag">Creator</span>
                                                    <Link to={`/profile/${collectionDetails.owner_id}`} className="info">
                                                        {otherUsersAvatar ? (
                                                            <LazyLoadImage
                                                                className="creator-avatar"
                                                                style={{ border: `3px solid ${otherUsersPlanColor}` }}
                                                                alt="Owner Avatar"
                                                                effect="blur"
                                                                src={otherUsersAvatar}
                                                                placeholderSrc={otherUsersAvatar}
                                                            />
                                                        ) : (
                                                            <BsPerson className="creator-avatar" alt="No Avatar" style={{ border: `3px solid ${otherUsersPlanColor}`, color: `${otherUsersPlanColor}` }} />
                                                        )}
                                                        <span className="name">{truncateAddress(collectionDetails.owner_id)}</span>
                                                    </Link>
                                                </div>

                                                <div className="stat-container date">
                                                    <span className="tag">Creation Date</span>
                                                    <span className="value">{convertDate}</span>
                                                </div>
                                                <div className="stat-container objects">
                                                    <span className="tag">Items</span>
                                                    <span className="value">{numberOfItems}</span>
                                                </div>
                                                <div className="stat-container owners">
                                                    <span className="tag">Minters</span>
                                                    <span className="value">{numberOfMinters}</span>
                                                </div>
                                            </div>

                                            <span className="description">{collectionDetails.metadata.description}</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="user-nfts">
                            <div className="SearchTag">
                                <div className="SearchContainer">
                                    <div className="SearchBox">
                                        <input
                                            type="text"
                                            placeholder="Search artwork(s)"
                                            value={query}
                                            onChange={handleSearchChange}
                                        />
                                        <div className="SearchButton">
                                            <BsSearch />
                                        </div>
                                    </div>
                                    <div className="FunnelBox">
                                        <div className="FunnelButton" onClick={toggleFilterModal}>
                                            <BsFilter />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ColumnsControl">
                                <div className="ColumnsLabel">Columns</div>
                                <input
                                    type="range"
                                    min="3"
                                    max="6"
                                    value={columns}
                                    onChange={(e) => setColumns(parseInt(e.target.value))}
                                />
                                <span className="ColumnsNumber">{columns}</span>
                            </div>
                        </div>

                        <div className="CollectionsContainer">
                            {filteredCollectionTokens.map((nft, index) => {
                                const modifiedUrl = replaceIpfsUrl(nft?.metadata?.media);
                                const priceData = nftPrices[nft.token_id];
                                const price = priceData ? (priceData.isListedForSale ? `${priceData.listedPrice}` : `${priceData.seriesPrice}`) : '∞';
                                const marketIcon = priceData?.isListedForSale;
                                return (
                                    <div
                                        className="CollectionCard"
                                        key={nft.id || index}
                                        style={{ flexBasis: getCardWidth() }}
                                        onClick={() => handleNFTClick(nft)}
                                    >
                                        <div className="ImgContainer">
                                            <LazyLoadImage className="CollectionImg" alt={nft.metadata.title} effect="blur" src={`${modifiedUrl}`} placeholderSrc={`${modifiedUrl}`} />
                                        </div>
                                        <p className="CollectionName">{nft.metadata.title}</p>
                                        <div className="CollectionInfo">
                                            {marketIcon && <BsTags />}
                                            {price}
                                            <LazyLoadImage
                                                className="CollectionIcon"
                                                alt="Near Icon"
                                                effect="blur"
                                                src={NearIcon}
                                                placeholderSrc={NearIcon}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )}
            </div >

            <NftContractModal
                show={nftModalVisible}
                onHide={() => setNFTModalVisible(false)}
                nft={selectedNFT}
            />

            <FilterModal
                show={isFilterModalVisible}
                onHide={() => setIsFilterModalVisible(false)}
                onFilterChange={updateFilters}
                onResetFilters={resetFilters}
                filters={filters}
                tokens={collectionTokens}
            />


        </>
    );
};

export default CollectionDetail;
