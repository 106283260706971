import React from 'react';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const WeatherComponent = ({ weatherData }) => {
    if (!weatherData || weatherData.DailyForecasts.length < 3) return null;

    const getImg = (iconNumber) => `https://developer.accuweather.com/sites/default/files/${iconNumber.toString().padStart(2, '0')}-s.png`;

    return (
        <div className='weather'>
            <div className="Forecast">
                {weatherData.DailyForecasts.slice(0, 5).map((forecast, index) => {

                    const dayForecastClass = index === 0 ? 'DayForecast large' : index > 0 ? 'DayForecast small' : 'DayForecast';
                    return (
                        <div key={index} className={dayForecastClass}>
                            <p className="Day">
                                {moment(new Date(forecast.Date)).format('ddd D MMM')}
                            </p>
                            <div className="WeatherIcon">
                                <span className="WeatherDescription">{forecast.Day.IconPhrase}</span>
                                <LazyLoadImage
                                    alt="Weather Icon"
                                    effect="blur"
                                    src={getImg(forecast.Day.Icon)}
                                    placeholderSrc={getImg(forecast.Day.Icon)}
                                    className="Temperature"
                                />
                            </div>
                            <p className="TemperatureRange">
                                {forecast.Temperature.Minimum.Value} / {forecast.Temperature.Maximum.Value} °{forecast.Temperature.Maximum.Unit}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default WeatherComponent;
