import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { GoogleProvider, FacebookProvider, signInWithProvider } from '../../gateway/web2Login/oAuth';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import NearIcon from '../../assets/NearIcon.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LoginComponent = () => {
    const { signedAccountId, logIn } = useWallet();
    const [action, setAction] = useState(() => { });
    const [label, setLabel] = useState('Loading...');
    const query = new URLSearchParams(useLocation().search);
    const referrerId = query.get('referralId');

    useEffect(() => {
        if (!signedAccountId) {
            setAction(() => logIn);
            setLabel('Login With Wallet');
        }
    }, [logIn, setAction, setLabel]);

    useEffect(() => {
        if (referrerId) localStorage.setItem('referrerId', referrerId);
    }, []);


    const handleGoogleSignIn = () => {
        signInWithProvider(GoogleProvider);
    };

    const handleFacebookSignIn = () => {
        signInWithProvider(FacebookProvider);
    };

    return (

        <div className="wideLogin">
            <div className="login">
                <h3 className="loginTitle">MoodWeather</h3>
                <h1 className="loginSubtitle">It's time for emotions</h1>
                <div className="wrapper">
                    <div className="left">
                        <Button
                            className="mb-2"
                            variant="outline-secondary"
                            style={{ width: '220px' }}
                            onClick={action}
                        >
                            {label}
                            <LazyLoadImage
                                alt="Near Icon"
                                effect="blur"
                                src={NearIcon}
                                placeholderSrc={NearIcon}
                                className="login-icons"
                            />
                        </Button>
                        {/* <input type="text" placeholder="Username" />
                    <input type="text" placeholder="Password" />
                    <Button>Login</Button> */}
                    </div>
                    <div className="center">
                        <div className="line" />
                        <div className="or">OR</div>
                    </div>
                    <div className="right">
                        <Button
                            className="mb-2"
                            variant="outline-danger"
                            style={{ width: '220px' }}
                            onClick={handleGoogleSignIn}
                        >
                            Login With Google
                        </Button>
                        <Button
                            className="mb-2"
                            variant="outline-primary"
                            style={{ width: '220px' }}
                            onClick={handleFacebookSignIn}
                        >
                            Login With Facebook
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginComponent;
