import React from 'react';
import { BsFillHandThumbsUpFill, BsFillHandThumbsDownFill, BsHandThumbsUp, BsHandThumbsDown } from 'react-icons/bs';

import emojiSmile from '../../../assets/emojie/home/emoji-smile.svg';
import emojiLaughing from '../../../assets/emojie/home/emoji-grimace.svg';
import emojiSunglasses from '../../../assets/emojie/home/emoji-sunglasses.svg';
import emojiExpressionless from '../../../assets/emojie/home/emoji-expressionless.svg';
import emojiTear from '../../../assets/emojie/home/emoji-tear.svg';
import emojiAngry from '../../../assets/emojie/home/emoji-angry.svg';

import cloudSun from '../../../assets/weather/home/cloud-sun.svg';
import cloudRain from '../../../assets/weather/home/rain.svg';
import cloudSnow from '../../../assets/weather/home/snow.svg';
import cloudy from '../../../assets/weather/home/cloudy.svg';
import sun from '../../../assets/weather/home/sun.svg';
import cloudLightning from '../../../assets/weather/home/lightning.svg';

import italy from '../../../assets/italian.svg';

const moodOptions = [
    { mood: "Happy", icon: <img className="emojies" src={emojiSmile} alt="Happy emoji" loading="lazy" /> },
    { mood: "Laughing", icon: <img className="emojies" src={emojiLaughing} alt="Laughing emoji" loading="lazy" /> },
    { mood: "Cool&Sunglasses", icon: <img className="emojies" src={emojiSunglasses} alt="Cool emoji" loading="lazy" /> },
    { mood: "Cool", icon: <img className="emojies" src={emojiSunglasses} alt="Cool emoji" loading="lazy" /> },
    { mood: "Sunglasses", icon: <img className="emojies" src={emojiSunglasses} alt="Cool emoji" loading="lazy" /> },
    { mood: "Expressionless", icon: <img className="emojies" src={emojiExpressionless} alt="Expressionless emoji" loading="lazy" /> },
    { mood: "Dizzy", icon: <img className="emojies" src={emojiTear} alt="Dizzy emoji" loading="lazy" /> },
    { mood: "Angry", icon: <img className="emojies" src={emojiAngry} alt="Angry emoji" loading="lazy" /> },
];

export const getMoodIcon = (mood) => {
    const foundMood = moodOptions.find(option => option.mood === mood);
    return foundMood ? foundMood.icon : null;
};

export const shareOptions = [
    { sharing: "Yes", selectedIcon: <BsFillHandThumbsUpFill />, notSelectedIcon: <BsHandThumbsUp /> },
    { sharing: "No", selectedIcon: <BsFillHandThumbsDownFill />, notSelectedIcon: <BsHandThumbsDown /> },
];

export const getShareIcon = (sharing) => {
    const foundShare = shareOptions.find(option => option.sharing === sharing);
    return foundShare ? foundShare.selectedIcon : null;
};

const weatherOptions = [
    { weather: "Cloudy", icon: <img className="emojies" src={cloudSun} alt="Cloudy weather icon" loading="lazy" /> },
    { weather: "Rain", icon: <img className="emojies" src={cloudRain} alt="Rain weather icon" loading="lazy" /> },

    { weather: "Mostly cloudy", icon: <img className="emojies" src={cloudSun} alt="Cloudy weather icon" loading="lazy" /> },
    { weather: "Light rain", icon: <img className="emojies" src={cloudRain} alt="Rain weather icon" loading="lazy" /> }, // New icon map, it needs its Icon
    { weather: "Mostly sunny", icon: <img className="emojies" src={cloudy} alt="Partly cloudy weather icon" loading="lazy" /> },
    { weather: "Partly sunny", icon: <img className="emojies" src={cloudy} alt="Partly cloudy weather icon" loading="lazy" /> },

    { weather: "Snow", icon: <img className="emojies" src={cloudSnow} alt="Snow weather icon" loading="lazy" /> },
    { weather: "Partly cloudy", icon: <img className="emojies" src={cloudy} alt="Partly cloudy weather icon" loading="lazy" /> },
    { weather: "Cloud Sunny", icon: <img className="emojies" src={cloudy} alt="Partly cloudy weather icon" loading="lazy" /> },
    { weather: "Sunny", icon: <img className="emojies" src={sun} alt="Sunny weather icon" loading="lazy" /> },
    { weather: "Lightning", icon: <img className="emojies" src={cloudLightning} alt="Lightning weather icon" loading="lazy" /> },
];

export const getWeatherIcon = (weather) => {
    const foundWeather = weatherOptions.find(option => option.weather === weather);
    return foundWeather ? foundWeather.icon : null;
};

const cityOptions = [
    { city: "Turin", icon: <img className="emojies" src={italy} alt="Italy" loading="lazy" /> },
    { city: "Rimini", icon: <img className="emojies" src={italy} alt="Italy" loading="lazy" /> },
    { city: "Rome", icon: <img className="emojies" src={italy} alt="Italy" loading="lazy" /> },
    { city: "Milan", icon: <img className="emojies" src={italy} alt="Italy" loading="lazy" /> },
];

export const getCityIcon = (city) => {
    const foundCity = cityOptions.find(option => option.city === city);
    return foundCity ? foundCity.icon : null;
};