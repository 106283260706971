import { useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

export const useGoogleMapsScript = (onLoadCallback) => {
    useEffect(() => {
        const loader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            version: "weekly",
            libraries: ["places", "marker"] // Include any additional libraries if needed
        });

        let cancel = false;

        loader.load().then(() => {
            if (!cancel) {
                // console.log('Google Maps script loaded.');
                onLoadCallback?.();
            }
        }).catch(e => {
            // Handle loading error
            console.error("The script failed to load", e);
        });

        return () => {
            cancel = true;
        };
    }, [onLoadCallback]);
};
