import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { BsFilter, BsGrid, BsList, BsSearch, BsSend } from 'react-icons/bs';
import { ImageGenerationModal, MintNFT } from './Mint';
import MapComponent from './Map/MapComponent';
import { NftContractModal } from '../Market/Components/NftModal';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import { fetchGeoInfoFromIP, getVisitorIP } from './Map/LocationUtils';
import getWeather from './Map/WeatherUtils';
import { useFetchOwnerTokens } from '../UserProfile/useFetchUserInfo';
import NFTBoard from './NFTBoard';
import { useFilters, useSearch } from '../Market/Components/useSearchFilter';
import { FilterModal } from '../UserProfile/UserProfile';
import ExclusiveSpinner from '../../Components/ExclusiveSpinner';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;
const ITEMS_PER_PAGE = 20;

export const replaceIpfsUrl = (url) => {
    if (!url) return url;
    const ipfsPrefix = 'ipfs://';
    const httpsGatewayPrefix = 'https://ipfs.io/ipfs/';
    const nftstorageatewayPrefix = 'https://nftstorage.link/ipfs/';
    const dwebGatewayPrefix = 'https://dweb.link/ipfs/';
    const w3sGatewayPrefix = 'https://w3s.link/ipfs/';
    if (url.startsWith(ipfsPrefix)) {
        return url.replace(ipfsPrefix, w3sGatewayPrefix);
    } else if (url.startsWith(httpsGatewayPrefix)) {
        return url.replace(httpsGatewayPrefix, w3sGatewayPrefix);
    } else if (url.startsWith(nftstorageatewayPrefix)) {
        return url.replace(nftstorageatewayPrefix, w3sGatewayPrefix);
    } else if (url.startsWith(dwebGatewayPrefix)) {
        return url.replace(dwebGatewayPrefix, w3sGatewayPrefix);
    }
    return url;
};

const HomeComponent = () => {
    const { viewMethod, signedAccountId, callMethod } = useWallet();
    const [userCity, setUserCity] = useState('');
    const [userWeather, setUserWeather] = useState('');
    const [generateImageModal, setGenerateImageModal] = useState(false);
    const [collectionTitleModal, setCollectionTitleModal] = useState(false);
    const [cityMoodData, setCityMoodData] = useState({});
    const [contractData, setContractData] = useState([]);
    const [nftModalVisible, setNFTModalVisible] = useState(false);
    const [selectedNFT, setSelectedNFT] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { ownerTokens } = useFetchOwnerTokens(signedAccountId);
    const storedImage = localStorage.getItem("image") || '';
    const localStorageKeys = ["image", "quote", "mood", "title", "selectedCollection"];
    const [isMainContainerExpanded, setIsMainContainerExpanded] = useState(false);
    const [activeButton, setActiveButton] = useState('list');
    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
    const [isRightSideVisible, setIsRightSideVisible] = useState(true);

    const retrieveLocalStorage = (key) => localStorage.getItem(key);

    const initialFilters = useMemo(() => ({ Mood: [], City: [], Shared: [] }), []);
    const { filters, handleFilterChange, applyFilters, resetFilters } = useFilters(initialFilters);
    const { query, handleSearchChange, filterBySearch } = useSearch();

    const removeLocalStorageItems = (...keys) => {
        keys.forEach(key => localStorage.removeItem(key));
    };

    const setLastUserMoodFunction = async (userMood) => {
        if (!userMood) {
            console.error('User mood is not valid');
            return;
        }

        if (callMethod) {
            try {
                await callMethod(NFT_CONTRACT_NAME, "set_last_user_mood", { mood: userMood });
                console.log('User mood set successfully');
            } catch (error) {
                console.error('Error setting last user mood:', error);
            }
        }
    };

    useEffect(() => {
        const fetchLocationAndWeather = async () => {
            try {
                // if call setUserCity here it repeats a lot of time to run getLocation on setUserCity
                setUserCity('Turin');
                setUserWeather('Partly sunny');
                // console.log("needs to uncomment to call accuweather API");
                // setIsLoading(true);
                // const ip = await getVisitorIP();
                // const geoData = await fetchGeoInfoFromIP(ip);
                // const weatherData = await getWeather(geoData.city);
                // setUserCity(geoData.city);
                // setUserWeather(weatherData);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchLocationAndWeather();
    }, []);

    useEffect(() => {
        // Extract transaction hash from URL
        const urlParams = new URLSearchParams(window.location.search);
        const txHash = urlParams.get('transactionHashes');

        // Setting the last user mood based on the same thing that they have choosed
        // Ensure the userMood is set after minting the NFT

        const processNFTMinting = async () => {
            if (txHash && ownerTokens && ownerTokens.length > 0) {
                // Use the first token as the last minted NFT
                const lastMintedNFT = ownerTokens[0];
                setSelectedNFT(lastMintedNFT);
                setCollectionTitleModal(false);
                setNFTModalVisible(true);

                try {
                    const userMood = localStorage.getItem('mood');
                    if (userMood) {
                        await setLastUserMoodFunction(userMood);
                        setCollectionTitleModal(false);

                        // Clean up local storage if needed
                        if (localStorageKeys.some(key => retrieveLocalStorage(key))) {
                            removeLocalStorageItems(...localStorageKeys);
                        }
                    }
                } catch (error) {
                    console.error('Error in setting last user mood:', error);
                }
            }
        };

        processNFTMinting();
    }, [ownerTokens]);

    // show the popup to develop dropdown list
    useEffect(() => {
        if (storedImage) {
            // Image already generated, display it
            setCollectionTitleModal(true);
        }
    }, [storedImage]);

    // useEffect(() => {
    //     if (selectedNFT?.metadata?.reference) {
    //         fetchJsonData(selectedNFT.metadata.reference);
    //     }
    // }, [selectedNFT]);

    const processMoodData = (nfts) => {
        const moodData = nfts.reduce((acc, nft) => {
            const shared = nft?.jsonData?.attributes?.find(attr => attr.trait_type === 'Shared');
            const cityAttribute = nft?.jsonData?.attributes?.find(attr => attr.trait_type === 'City');
            if (shared && shared.value === 'Yes' && cityAttribute) {
                const city = cityAttribute.value;
                const moodAttribute = nft.jsonData.attributes.find(attr => attr.trait_type === 'Mood');
                if (moodAttribute) {
                    const mood = moodAttribute.value;
                    if (!acc[city]) acc[city] = {};
                    if (!acc[city][mood]) acc[city][mood] = 0;
                    acc[city][mood] += 1;
                }
            }
            return acc;
        }, {});

        setCityMoodData(moodData);
    };

    // Function to fetch JSON data from a modified URL
    const fetchJsonData = async (url) => {
        const modifiedUrl = replaceIpfsUrl(url);
        try {
            const response = await fetch(modifiedUrl);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            return await response.json();
        } catch (error) {
            console.error("Error fetching JSON data:", error);
            return null;
        }
    };

    // Sort NFTs by date before setting contract data
    const fetchContractTokens = async (currentPage) => {
        setIsLoading(true); // Show spinner while loading data
        try {
            const fromIndex = (currentPage - 1) * ITEMS_PER_PAGE;
            const boardNFTs = await viewMethod(NFT_CONTRACT_NAME, "nft_tokens", { from_index: fromIndex.toString(), limit: ITEMS_PER_PAGE });
            const mapNFTs = await viewMethod(NFT_CONTRACT_NAME, "nft_tokens", { from_index: '0', limit: null });
            if (boardNFTs.length === 0 || mapNFTs.length === 0) {
                console.log("No NFTs found.");
                setIsLoading(false);
                return;
            }
            // Process NFTs for board and map
            const nftsUpdated = await Promise.all(boardNFTs.map(async (nft) => {
                const jsonData = await fetchJsonData(nft.metadata.reference);
                return { ...nft, jsonData: jsonData || {} };
            }));

            const sortedNFTs = nftsUpdated.sort((a, b) => new Date(b.metadata.issued_at) - new Date(a.metadata.issued_at));

            const mapNFTsUpdated = await Promise.all(mapNFTs.map(async (nft) => {
                const jsonData = await fetchJsonData(nft.metadata.reference);
                return { ...nft, jsonData: jsonData || {} };
            }));

            const totalNFTs = mapNFTs.length;
            setTotalPages(Math.ceil(totalNFTs / ITEMS_PER_PAGE));
            setContractData(prevData => [...prevData, ...sortedNFTs]);
            processMoodData(mapNFTsUpdated);

            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching NFT data:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (viewMethod && NFT_CONTRACT_NAME) {
            fetchContractTokens(page);
        }
    }, [page, viewMethod]);

    const handleNFTClick = async (nft) => {
        if (!nft.jsonData) {
            const jsonData = await fetchJsonData(nft?.metadata.reference);
            nft = { ...nft, jsonData: jsonData || {} };
        }
        setSelectedNFT(nft);
        setNFTModalVisible(true);
    };

    const handleStartMint = () => {
        if (storedImage) {
            // Image already generated, display it
            setCollectionTitleModal(true);
        } else {
            // Need to generate a new image
            setGenerateImageModal(true);
        }
    };

    // // define the function that finds the users geolocation
    // const getUserLocation = () => {
    //     // if geolocation is supported by the users browser
    //     if (navigator.geolocation) {
    //         // get the current users location
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 // save the geolocation coordinates in two variables
    //                 const { latitude, longitude } = position.coords;
    //                 // update the value of userlocation variable
    //                 setUserLocation({ latitude, longitude });
    //                 console.log("latitude:", latitude);
    //                 console.log("longitude:", longitude);
    //             },
    //             // if there was an error getting the users location
    //             (error) => {
    //                 console.error('Error getting user location:', error);
    //             }
    //         );
    //     }
    //     // if geolocation is not supported by the users browser
    //     else {
    //         console.error('Geolocation is not supported by this browser.');
    //     }
    // // };

    // const handleScroll = useCallback(() => {
    //     if (scrollRef.current) {
    //         const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    //         if (scrollTop + clientHeight >= scrollHeight && !isLoading) {
    //             setPage(prevPage => prevPage + 1);
    //             fetchContractTokens(page * 20); // Fetch more items
    //         }
    //     }
    // }, [isLoading, page]);

    // useEffect(() => {
    //     const scrollContainer = scrollRef.current;
    //     if (scrollContainer) {
    //         scrollContainer.addEventListener('scroll', handleScroll);
    //         return () => scrollContainer.removeEventListener('scroll', handleScroll);
    //     }
    // }, [handleScroll]);

    const toggleMainContainerDisplay = () => {
        setIsMainContainerExpanded((prev) => !prev);
        setIsRightSideVisible((prev) => !prev);
    };

    const toggleFilterModal = () => {
        setIsFilterModalVisible(!isFilterModalVisible);
    };

    const filteredNFTs = useMemo(() => {
        const searchFiltered = filterBySearch(contractData);
        return applyFilters(searchFiltered);
    }, [contractData, query, filters]);

    const handleNextPage = () => {
        if (!isLoading && page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (!isLoading && page > 1) {
            setPage(page - 1);
        }
    };

    const updateFilters = (filterType, value, isChecked) => {
        handleFilterChange(filterType, value, isChecked);
    };

    return (
        <div className="mainHomeContainer">
            <div className="ShareEmotionContainer" onClick={handleStartMint}>
                <div className="UserQuoteContainer">
                    <p className="ShareEmotionText">Capture your feelings</p>
                    <div className="UserQuoteFrame">
                        <div className="UserQuoteText">Daily Inspiration...</div>
                        <div className="SendButtonUserQuote">
                            <div className="ShapeButtonUserQuote" />
                            <BsSend className="SendIconUserQuote" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="two-sides" style={{ display: isMainContainerExpanded ? 'contents' : 'flex' }}>
                <div className="left-side">
                    <MapComponent
                        setUserCity={setUserCity}
                        cityMoodData={cityMoodData}
                        toggleMainContainerDisplay={toggleMainContainerDisplay}
                        isMainContainerExpanded={isMainContainerExpanded}
                    />
                </div>
                <div className="right-side" style={{ display: isRightSideVisible ? 'block' : 'none' }}>
                    <div className="BoardContainer">
                        <p className="BoardContainerText">Worldwide Noticeboard</p>
                        <div className="search-container">
                            <div className="search-profile">
                                <div className="search-box">
                                    <input
                                        type="text"
                                        placeholder="Search artwork(s)"
                                        value={query || ''}
                                        onChange={handleSearchChange}
                                    />
                                    <div className="search-button">
                                        <BsSearch />
                                    </div>
                                </div>
                                <div className="funnel-box">
                                    <div className="funnel-button" onClick={toggleFilterModal}>
                                        <BsFilter /> Filters
                                    </div>
                                </div>

                                <div className="ChangeMarkersButtonsBox">
                                    <button
                                        className={`WatherButton ${activeButton === 'grid' ? 'active' : ''}`}
                                        onClick={() => setActiveButton('grid')}>
                                        <BsGrid className="WeatherIcon" />
                                    </button>
                                    <button
                                        className={`EmojiButton ${activeButton === 'list' ? 'active' : ''}`}
                                        onClick={() => setActiveButton('list')}>
                                        <BsList className="EmojiIcon" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="BoardInnerContainer">
                            {isLoading ? (
                                <ExclusiveSpinner />
                            ) : filteredNFTs.length > 0 ? (
                                <div className="Scrolling">
                                    {filteredNFTs
                                        .slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
                                        .map(nft => (
                                            <NFTBoard
                                                key={`${nft.token_id}-${nft.metadata.issued_at}`}
                                                nft={nft}
                                                handleNFTClick={handleNFTClick}
                                                viewType={activeButton === 'grid' ? 'grid-view' : 'list-view'}
                                            />
                                        ))}
                                </div>
                            ) : (
                                <div className="BoardInnerContainer">
                                    <p>No NFTs found</p>
                                </div>
                            )}
                        </div>

                        <div className="PaginationContainer">
                            <button className="PaginationButtons" onClick={handlePrevPage} disabled={page === 1}>
                                {"<"}
                            </button>
                            <span className="PaginationTag">
                                Page {page} of {totalPages}
                            </span>
                            <button className="PaginationButtons" onClick={handleNextPage} disabled={page === totalPages}>
                                {">"}
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <ImageGenerationModal
                show={generateImageModal}
                onHide={() => setGenerateImageModal(false)}
                generateImageModal={generateImageModal}
                setGenerateImageModal={setGenerateImageModal}
                collectionTitleModal={collectionTitleModal}
                setCollectionTitleModal={setCollectionTitleModal}
                userCity={userCity}
                setUserCity={setUserCity}
                userWeather={userWeather}
                setUserWeather={setUserWeather}
            />

            <MintNFT fetchContractTokens={fetchContractTokens} />

            <NftContractModal
                show={nftModalVisible}
                onHide={() => setNFTModalVisible(false)}
                nft={selectedNFT}
            />

            <FilterModal
                show={isFilterModalVisible}
                onHide={() => setIsFilterModalVisible(false)}
                onFilterChange={updateFilters}
                onResetFilters={resetFilters}
                filters={filters}
                tokens={contractData}
            />
        </div>
    );
};

export default HomeComponent;
