import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Icons import
import { BsChevronBarUp, BsFacebook, BsInstagram, BsLinkedin, BsTwitterX } from 'react-icons/bs';

// Assets import
import MoodWeatherLogo from '../assets/MoodWeatherLogo.svg';
import MoodWeatherWideLogo from '../assets/MoodWeather.svg';

// Auth Hooks
import { useWallet } from '../gateway/web3Login/walletAuth';
import { useAuth } from '../gateway/web2Login/oAuth';

const Footer = () => {
    const { signedAccountId } = useWallet();
    const { isWeb2LoggedIn } = useAuth();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <footer className="Footer">
            <div className="footerMain">
                <div className="logoSection">
                    <Link to="/">
                        <LazyLoadImage
                            alt="MoodWeather Logo"
                            effect="blur"
                            src={MoodWeatherWideLogo}
                            placeholderSrc={MoodWeatherWideLogo}
                            className="logo"
                        />
                        <LazyLoadImage
                            alt="MoodWeather Logo"
                            effect="blur"
                            src={MoodWeatherLogo}
                            placeholderSrc={MoodWeatherLogo}
                            className="mobileLogo"
                        />
                    </Link>
                </div>
                <div className="socialIcons">
                    <a href="https://www.facebook.com/moodweather.io/" target="_blank"><BsFacebook /></a>
                    <a href="https://twitter.com/moodweather_io" target="_blank"><BsTwitterX /></a>
                    <a href="https://www.instagram.com/moodweather.io/" target="_blank"><BsInstagram /></a>
                    <a href="https://www.linkedin.com/showcase/moodweather/" target="_blank"><BsLinkedin /></a>
                </div>
            </div>
            <div className="footerEnd">
                <div className="companyInfo">
                    <span>CGM Consulting S.r.l.</span>
                    <span>P.IVA 10770600012</span>
                    <span>Copyright © 2014 All Rights Reserved</span>
                </div>
                <div className="linkSection">
                    <div className="mainLinks">
                        <Link to="/">Board</Link>
                        <Link to="/activity">Activity</Link>
                        <Link to={isWeb2LoggedIn ? `/profile/${isWeb2LoggedIn.email}` : `/profile/${signedAccountId}`}>Profile</Link>
                    </div>
                    <div className="marketLinks">
                        <Link to="/market?tab=explore">Collections</Link>
                        <Link to="/market?tab=sales">Sales</Link>
                        <Link to="/market?tab=offers">Offers</Link>
                    </div>
                    <div className="secondaryLinks">
                        <Link to="/about">About</Link>
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/contact">Contacts</Link>
                    </div>
                </div>
                <div className="backToTopSection" onClick={scrollToTop}>
                    <p className="backToTopTag">Back To Top</p>
                    <BsChevronBarUp />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
