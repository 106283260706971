import React from 'react';
import { getMoodIcon } from './IconOptions';

const MoodComponent = ({ moods, totalMoods }) => {
    // Merge the statistics for "Cool" and exclude "Sunglasses"
    const mergedMoods = { ...moods, "Cool&Sunglasses": (moods["Cool"] || 0) + (moods["Sunglasses"] || 0) };
    delete mergedMoods["Sunglasses"];
    delete mergedMoods["Cool"];

    // Convert moods object into an array, sort it by count, then map to JSX
    const sortedMoods = Object.entries(mergedMoods)
        .sort((a, b) => b[1] - a[1]) // This will sort the moods based on count in descending order
        .map(([mood, count], index) => {
            // Apply 'first' class to the element with the highest count
            const boxClass = index === 0 ? "MoodCastBox first" : "MoodCastBox";
            return (
                <div key={mood} className={boxClass}>
                    <p className="Mood">{mood}</p>
                    <div className="MoodInfo">
                        <span className="MoodPercentage">{((count / totalMoods) * 100).toFixed(2)}%</span>
                        <span className="MoodIcon">{getMoodIcon(mood)}</span>
                    </div>
                    <p className="CountRange">{count}/{totalMoods}</p>
                </div>
            );
        });

    return (
        <div className="MoodCast">
            {sortedMoods}
        </div>
    );
};

export default MoodComponent;
