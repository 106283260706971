import React, { useEffect, useState } from 'react';
import { AddSeriesModal, CloseMintModal, ConfirmMintModal, FinalMintModal } from '.';
import { useWallet } from '../../../gateway/web3Login/walletAuth';
import CollectionDetail from '../../Market/CollectionDetail';
import { useNavigate } from 'react-router-dom';
const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

const NFTsCollection = ({
    show, onHide, imageUrl, isLoading, setIsLoading,
    imageTitle, setImageTitle, nftPrice,
    setNftPrice, isShared, setIsShared,
    handleMintNFT, handleRegenerateClick,
}) => {
    const { signedAccountId, viewMethod, callMethod } = useWallet();
    const [seriesData, setSeriesData] = useState([]);
    const [isApprovedCreator, setIsApprovedCreator] = useState(false);
    const [isApprovedMinter, setIsApprovedMinter] = useState(false);
    const [selectedSeries, setSelectedSeries] = useState(localStorage.getItem("selectedCollection") || '');
    const [showAddSeriesModal, setShowAddSeriesModal] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
    const [inputErrors, setInputErrors] = useState({ nftTitle: false, series: false, share: false });

    const toggleShare = (sharing) => {
        setIsShared(isShared === sharing ? '' : sharing);
    };

    useEffect(() => {
        if (viewMethod && NFT_CONTRACT_NAME) {
            fetchSeriesDetails();
        }

    }, [viewMethod]);

    const fetchSeriesDetails = async () => {
        try {
            // Fetch the series IDs first
            const seriesIDs = await viewMethod(NFT_CONTRACT_NAME, "get_series", { limit: null });
            // console.log('Series IDs:', seriesIDs);
            // console.log(seriesIDs[36]);

            // Only proceed if seriesIDs is not empty
            if (seriesIDs.length === 0) {
                console.log("No series IDs found.");
                return; // Exit the function if no series IDs
            }

            // Then fetch details for each series
            const seriesDetails = await Promise.all(seriesIDs.map(id =>
                viewMethod(NFT_CONTRACT_NAME, "get_series_details", { id: id.series_id.toString() })
            ));

            setSeriesData(seriesDetails);
            // console.log('Series Details:', seriesDetails);
        } catch (error) {
            console.error("Error fetching series data:", error);
        }
    };

    const checkCreatorStatus = async () => {
        if (signedAccountId) {
            try {
                const isApproved = await viewMethod(NFT_CONTRACT_NAME, "is_approved_creator", { account_id: signedAccountId });
                setIsApprovedCreator(isApproved);
            } catch (error) {
                console.error("Error checking creator status:", error);
                setIsApprovedCreator(false);
            }
        }
    };

    const handleApproveCreator = async () => {
        if (signedAccountId) {
            try {
                await callMethod(NFT_CONTRACT_NAME, "add_approved_creator", { account_id: signedAccountId });
                setIsApprovedCreator(true);
                console.log(`${signedAccountId} has been added as an approved creator.`);
            } catch (error) {
                console.error("Error in approving creator:", error);
            }
        }
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        setImageTitle(newTitle); // This updates the state
        localStorage.setItem("title", newTitle); // This updates localStorage

        // Reset the title error if the new title is not empty or whitespace
        if (newTitle.trim()) {
            setInputErrors({ ...inputErrors, nftTitle: false });
        }
    };

    const handlePriceChange = (e) => {
        const newPrice = e.target.value;
        // Ensure the input is not empty, is a number, and is at least 0.1
        if (newPrice === '' || /^\d*\.?\d*$/.test(newPrice)) {
            const priceValue = newPrice === '' ? '' : Math.max(0.1, parseFloat(newPrice));
            if (priceValue >= 0.1) {
                setNftPrice(priceValue.toFixed(0.1).toString()); // Convert the number to string before setting it
                setInputErrors({ ...inputErrors, nftPrice: false }); // Reset the price error if the condition is met
            } else {
                setInputErrors({ ...inputErrors, nftPrice: 'Price must be at least 0.1' }); // Set the price error if the condition is not met
            }
        } else {
            setInputErrors({ ...inputErrors, nftPrice: 'Please enter a valid price' }); // Set the price error for invalid input
        }
    };

    const handleSeriesChange = (series) => {
        setSelectedSeries(series);
        localStorage.setItem("selectedCollection", series?.series_id); // Save to localStorage
        // Reset the series error if a valid series is selected
        if (series?.series_id) {
            setInputErrors({ ...inputErrors, series: false });
        }
    };

    const validateInputs = () => {
        const imageError = typeof imageTitle === 'string' ? !imageTitle.trim() : true;
        const seriesError = typeof selectedSeries === 'object' ? false : true;
        const shareError = typeof isShared === 'string' ? !isShared.trim() : true;

        const errors = {
            nftTitle: imageError,
            series: seriesError,
            share: shareError,
        };

        setInputErrors(errors);
        return !errors.nftTitle && !errors.series && !errors.share;
    };

    const handleMintClickWrapper = () => {
        if (validateInputs()) {
            setShowConfirmation(true); // Ensure this is the only place setting it to true
        }
    };

    const handleCancelMint = () => {
        // Logic to cancel minting (if any)
        setShowCloseConfirmation(true);
    };

    const handleCloseConfirm = async () => {
        setIsLoading(true); // Show spinner
        setShowCloseConfirmation(false); // Close the CloseMintModal
        onHide(); // Close the NFTsCollection
        setIsLoading(false); // Hide spinner
        localStorage.removeItem("image"); // Delete image parameter from localStorage
        localStorage.removeItem("quote"); // Delete quote parameter from localStorage
        localStorage.removeItem("mood"); // Delete mood parameter from localStorage
    };

    const handleCreateNearWallet = () => {
        window.open('https://testnet.mynearwallet.com', '_blank', 'noreferrer');
    };

    return (
        <>
            <FinalMintModal
                show={show}
                handleApproveCreator={handleApproveCreator}
                onHide={() => setShowCloseConfirmation(true)}

                // onHide={handleCancelMint}
                handleCancelMint={handleCancelMint}

                imageUrl={imageUrl}
                isLoading={isLoading}
                title={imageTitle}
                setShowAddSeriesModal={setShowAddSeriesModal}
                handleRegenerateClick={handleRegenerateClick}
                seriesData={seriesData}
                selectedSeries={selectedSeries}
                inputErrors={inputErrors}
                isApprovedCreator={isApprovedCreator}
                handleSeriesChange={handleSeriesChange}
                handleTitleChange={handleTitleChange}
                handlePriceChange={handlePriceChange}
                handleMintClickWrapper={handleMintClickWrapper}

                nftPrice={nftPrice}
                setNftPrice={setNftPrice}

                toggleShare={toggleShare}
                isShared={isShared}
                setIsShared={setIsShared}
            />

            {/* <CollectionDetail
                seriesData={seriesData}
            /> */}

            <AddSeriesModal
                show={showAddSeriesModal}
                onHide={() => setShowAddSeriesModal(false)}
                handleApproveCreator={handleApproveCreator}
                isApprovedCreator={isApprovedCreator}
                fetchSeriesDetails={fetchSeriesDetails}
            />

            <ConfirmMintModal
                show={showConfirmation}
                onHide={() => setShowConfirmation(false)}
                handleMintNFT={handleMintNFT}
                handleCreateNearWallet={handleCreateNearWallet}
            />

            <CloseMintModal
                show={showCloseConfirmation}
                onHide={() => setShowCloseConfirmation(false)}
                onConfirmClose={handleCloseConfirm}
            />
        </>
    );
};

export default NFTsCollection;