import { useEffect, useState } from 'react';
import { create as createStore } from 'zustand';
import { distinctUntilChanged, map } from 'rxjs/operators';

// near api js
import { providers } from 'near-api-js';

// wallet selector UI
import '@near-wallet-selector/modal-ui/styles.css';
import { setupModal } from '@near-wallet-selector/modal-ui';

// wallet selector options
import { setupWalletSelector } from '@near-wallet-selector/core';
import { setupModal } from '@near-wallet-selector/modal-ui';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';

// // Initialize Firebase
// import { initializeApp } from "firebase/app";
// import { getAuth } from 'firebase/auth';

// import { getDatabase, ref, set, get } from 'firebase/database';
// import { firebaseConfig } from '../web2Login/oAuth';

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const database = getDatabase(app);


export const useWallet = createStore(set => ({
  signedAccountId: '',
  logOut: undefined,
  logIn: undefined,
  selector: undefined,
  viewMethod: undefined,
  callMethod: undefined,
  setLogActions: ({ logOut, logIn }) => set({ logOut, logIn }),
  setAuth: ({ signedAccountId }) => set({ signedAccountId }),
  setMethods: ({ viewMethod, callMethod }) => set({ viewMethod, callMethod }),
  setStoreSelector: ({ selector }) => set({ selector }),
}));

export function useInitWallet({ createAccessKeyFor, network }) {
  const setAuth = useWallet(store => store.setAuth);
  const setLogActions = useWallet(store => store.setLogActions);
  const setMethods = useWallet(store => store.setMethods);
  const setStoreSelector = useWallet(store => store.setStoreSelector);
  const [selector, setSelector] = useState(undefined);

  useEffect(() => {
    const initializeSelector = async () => {
      try {
        const walletSelector = await setupWalletSelector({
          network: network,
          modules: [setupMyNearWallet()],
        });
        setSelector(walletSelector);
        setStoreSelector({ selector: walletSelector });
      } catch (error) {
        console.error('Error initializing wallet selector:', error);
      }
    };

    initializeSelector();
  }, [network, setStoreSelector]);

  useEffect(() => {
    if (!selector) return;

    const accounts = selector.store.getState().accounts;
    const signedAccountId = accounts.find(account => account.active)?.accountId || '';
    setAuth({ signedAccountId });

    const subscription = selector.store.observable
      .pipe(
        map(state => state.accounts),
        distinctUntilChanged()
      )
      .subscribe(accounts => {
        const signedAccountId = accounts.find(account => account.active)?.accountId || '';
        setAuth({ signedAccountId });
      });

    return () => subscription.unsubscribe();
  }, [selector, setAuth]);

  useEffect(() => {
    if (!selector) return;

    const logOut = async () => {
      try {
        const wallet = await selector.wallet();
        await wallet.signOut();
        setAuth({ signedAccountId: '' });
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    const logIn = async () => {

      // const referrerId = localStorage.getItem('referrerId');
      // if (referrerId) {
      //   const userRef = ref(database, 'referrals/' + result.user.uid);
      //   try {
      //     const snapshot = await get(userRef);
      //     if (snapshot.exists()) {
      //       alert('This referral link is no longer valid');
      //     } else {
      //       // Set first login and last login dates
      //       set(userRef, {
      //         username: result.user.displayName || 'Anonymous',
      //         email: result.user.email,
      //         referredBy: referrerId,
      //         id: result.user.uid,
      //         firstLogin: Date.now()
      //       });
      //       localStorage.removeItem('referrerId');
      //     }
      //   } catch (error) {
      //     console.error('Error accessing referral data:', error);
      //   }
      // }

      try {
        const modal = setupModal(selector, { contractId: createAccessKeyFor });
        modal.show();
      } catch (error) {
        console.error('Error logging in:', error);
      }
    };

    setLogActions({ logOut, logIn });
  }, [createAccessKeyFor, selector, setAuth, setLogActions]);

  useEffect(() => {
    if (!selector) return;

    const viewMethod = async (contractId, method, args = {}) => {
      try {
        const { network } = selector.options;
        const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });

        const res = await provider.query({
          request_type: 'call_function',
          account_id: contractId,
          method_name: method,
          args_base64: Buffer.from(JSON.stringify(args)).toString('base64'),
          finality: 'optimistic',
        });

        return JSON.parse(Buffer.from(res.result).toString());
      } catch (error) {
        console.error('Error executing view method:', error);
        throw error;
      }
    };

    const callMethod = async (contractId, method, args = {}, gas = '30000000000000', deposit = 0) => {
      try {
        const wallet = await selector.wallet();

        const outcome = await wallet.signAndSendTransaction({
          receiverId: contractId,
          actions: [
            {
              type: 'FunctionCall',
              params: {
                methodName: method,
                args,
                gas,
                deposit,
              },
            },
          ],
        });

        return providers.getTransactionLastResult(outcome);
      } catch (error) {
        console.error('Error executing call method:', error);
        throw error;
      }
    };

    setMethods({ viewMethod, callMethod });
  }, [selector, setMethods]);
}
