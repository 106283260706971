const proxyUrl = process.env.REACT_APP_MOODWEATHER_URL;
const PINAKEY = process.env.REACT_APP_PINATA_API_KEY;

// Function to convert a given image URL to a blob
async function fetchImageAsBlob(imageUrl, isLocal) {
    try {
        let response;

        if (isLocal) {
            // For local images, directly fetch the image
            response = await fetch(imageUrl);
        } else {

            // Convert IPFS URL to HTTPS URL using the fastest IPFS gateway
            const proxyGatewayUrl = imageUrl.replace("ipfs://", "https://w3s.link/ipfs/");
            // Fetch through your proxy to manage CORS
            const postData = { url: proxyGatewayUrl };

            response = await fetch(proxyUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(postData)
            });
        }

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Check if the response is an image
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("image")) {
            throw new Error("Fetched resource is not an image");
        }

        return await response.blob();
    } catch (error) {
        console.error('Error fetching image:', error);
        throw error;
    }
}

// Utility function to convert blob to File object
async function blobToFile(blob, filename = "image.png") {
    const arrayBuffer = await blob.arrayBuffer();
    const imageData = new Uint8Array(arrayBuffer);
    return new File([imageData], filename, { type: 'image/png' });
}

// Function to upload files to Pinata using the fastest gateway
async function uploadToPinata(file, metadataName) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('pinataMetadata', JSON.stringify({ name: metadataName }));
        formData.append("pinataOptions", JSON.stringify({ cidVersion: 0 }));

        // Pin the file to IPFS using Pinata's API endpoint
        const response = await fetch('https://api.pinata.cloud/pinning/pinFileToIPFS', {
            method: "POST",
            headers: { Authorization: `Bearer ${PINAKEY}` },
            body: formData
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return await response.json().then(data => data.IpfsHash);
    } catch (error) {
        console.error('Error uploading to Pinata:', error);
        throw error;
    }
}

async function createMetadataFile(metadata) {
    const metadataBlob = new Blob([JSON.stringify(metadata)], { type: 'application/json' });
    return new File([metadataBlob], 'metadata.json', { type: 'application/json' });
}

// Modified function to handle both local and remote images
export async function uploadNFTMetadata(imageTitle, userQuote, imageUrl, userDate, userMood, userWeather, userCity, isShared, isLocal = false) {
    try {
        console.log("NFT | needs to uncomment to set isLocal = false if use OpenAI");
        const blob = await fetchImageAsBlob(imageUrl, isLocal);
        const imageFile = await blobToFile(blob);

        // First upload the image to get its IPFS CID
        const imageCID = await uploadToPinata(imageFile, imageTitle);

        // Create the metadata JSON structure
        const metadata = {
            name: imageTitle,
            description: userQuote,
            image: `https://w3s.link/ipfs/${imageCID}`,
            date: userDate,
            attributes: [
                { "trait_type": "Mood", "value": userMood },
                { "trait_type": "Weather", "value": userWeather },
                { "trait_type": "City", "value": userCity },
                { "trait_type": "Shared", "value": isShared }
            ]
        };

        // Then, convert the metadata object into a blob and upload it
        const metadataFile = await createMetadataFile(metadata);
        const metadataCID = await uploadToPinata(metadataFile, imageTitle + " Metadata");

        // Return both metadata and image URLs using the fastest gateway
        return { imageUrl: `https://w3s.link/ipfs/${imageCID}`, metadataUrl: `https://w3s.link/ipfs/${metadataCID}` };
    } catch (error) {
        console.error('Error uploading NFT metadata:', error);
        return null;
    }
}

export async function uploadSeriesMetadata(collectionName, collectionDescription, selectedImage, userDate, isLocal = false) {
    try {
        // Create the metadata JSON structure directly using the provided IPFS URL
        const metadata = {
            name: collectionName,
            description: collectionDescription,
            image: selectedImage,
            date: userDate
        };

        // Convert the metadata object into a blob and upload it
        const metadataFile = await createMetadataFile(metadata);
        const metadataCID = await uploadToPinata(metadataFile, collectionName + " Metadata");

        // Return both metadata and image URLs using the fastest gateway
        return { seriesImageUrl: selectedImage, seriesMetadataUrl: `https://w3s.link/ipfs/${metadataCID}` };
    } catch (error) {
        console.error('Error storing series metadata:', error);
        return null;
    }
}
