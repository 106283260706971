[
    { "city": "Rome", "lat": 41.9028, "lng": 12.4964 },
    { "city": "Milan", "lat": 45.4642, "lng": 9.1900 },
    { "city": "Naples", "lat": 40.8518, "lng": 14.2681 },
    { "city": "Turin", "lat": 45.0703, "lng": 7.6869 },
    { "city": "Palermo", "lat": 38.1157, "lng": 13.3615 },
    { "city": "Genoa", "lat": 44.4056, "lng": 8.9463 },
    { "city": "Bologna", "lat": 44.4949, "lng": 11.3426 },
    { "city": "Florence", "lat": 43.7696, "lng": 11.2558 },
    { "city": "Bari", "lat": 41.1171, "lng": 16.8719 },
    { "city": "Catania", "lat": 37.5079, "lng": 15.0830 },
    { "city": "Verona", "lat": 45.4384, "lng": 10.9916 },
    { "city": "Venice", "lat": 45.4408, "lng": 12.3155 },
    { "city": "Messina", "lat": 38.1938, "lng": 15.5542 },
    { "city": "Padua", "lat": 45.4064, "lng": 11.8768 },
    { "city": "Prato", "lat": 43.8777, "lng": 11.1022 },
    { "city": "Trieste", "lat": 45.6495, "lng": 13.7768 },
    { "city": "Brescia", "lat": 45.5416, "lng": 10.2118 },
    { "city": "Parma", "lat": 44.8015, "lng": 10.3279 },
    { "city": "Taranto", "lat": 40.4644, "lng": 17.2470 },
    { "city": "Modena", "lat": 44.6471, "lng": 10.9252 },
    { "city": "Reggio Calabria", "lat": 38.1105, "lng": 15.6613 },
    { "city": "Reggio Emilia", "lat": 44.6983, "lng": 10.6312 },
    { "city": "Perugia", "lat": 43.1107, "lng": 12.3908 },
    { "city": "Ravenna", "lat": 44.4184, "lng": 12.2035 },
    { "city": "Livorno", "lat": 43.5485, "lng": 10.3106 },
    { "city": "Rimini", "lat": 44.0678, "lng": 12.5695 },
    { "city": "Cagliari", "lat": 39.2238, "lng": 9.1217 },
    { "city": "Foggia", "lat": 41.4622, "lng": 15.5446 },
    { "city": "Ferrara", "lat": 44.8381, "lng": 11.6198 },
    { "city": "Salerno", "lat": 40.6824, "lng": 14.7681 },
    { "city": "Latina", "lat": 41.4676, "lng": 12.9037 },
    { "city": "Giugliano in Campania", "lat": 40.9260, "lng": 14.1930 },
    { "city": "Monza", "lat": 45.5845, "lng": 9.2744 },
    { "city": "Sassari", "lat": 40.7267, "lng": 8.5592 },
    { "city": "Bergamo", "lat": 45.6983, "lng": 9.6773 },
    { "city": "Pescara", "lat": 42.4643, "lng": 14.2142 },
    { "city": "Trento", "lat": 46.0664, "lng": 11.1258 },
    { "city": "Forlì", "lat": 44.2227, "lng": 12.0407 },
    { "city": "Syracuse", "lat": 37.0755, "lng": 15.2866 },
    { "city": "Vicenza", "lat": 45.5455, "lng": 11.5354 },
    { "city": "Terni", "lat": 42.5624, "lng": 12.6462 },
    { "city": "Bolzano-Bozen", "lat": 46.4983, "lng": 11.3548 },
    { "city": "Piacenza", "lat": 45.0526, "lng": 9.6922 },
    { "city": "Novara", "lat": 45.4469, "lng": 8.6222 },
    { "city": "Ancona", "lat": 43.6158, "lng": 13.5189 },
    { "city": "Udine", "lat": 46.0626, "lng": 13.2378 },
    { "city": "Andria", "lat": 41.2306, "lng": 16.2934 },
    { "city": "Arezzo", "lat": 43.4633, "lng": 11.8783 },
    { "city": "Cesena", "lat": 44.1396, "lng": 12.2470 },
    { "city": "Pesaro", "lat": 43.9101, "lng": 12.9130 },
    { "city": "Lecce", "lat": 40.3515, "lng": 18.1750 },
    { "city": "Barletta", "lat": 41.3206, "lng": 16.2700 },
    { "city": "La Spezia", "lat": 44.1025, "lng": 9.8241 },
    { "city": "Alessandria", "lat": 44.9120, "lng": 8.6153 },
    { "city": "Pisa", "lat": 43.7228, "lng": 10.4017 },
    { "city": "Pistoia", "lat": 43.9302, "lng": 10.9078 },
    { "city": "Lucca", "lat": 43.8429, "lng": 10.5027 },
    { "city": "Guidonia Montecelio", "lat": 41.9936, "lng": 12.7224 },
    { "city": "Catanzaro", "lat": 38.9098, "lng": 16.5876 },
    { "city": "Treviso", "lat": 45.6669, "lng": 12.2430 },
    { "city": "Como", "lat": 45.8081, "lng": 9.0852 },
    { "city": "Brindisi", "lat": 40.6362, "lng": 17.9380 },
    { "city": "Busto Arsizio", "lat": 45.6113, "lng": 8.8512 },
    { "city": "Grosseto", "lat": 42.7635, "lng": 11.1124 },
    { "city": "Torre del Greco", "lat": 40.7899, "lng": 14.3664 },
    { "city": "Fiumicino", "lat": 41.7676, "lng": 12.2291 },
    { "city": "Marsala", "lat": 37.7965, "lng": 12.4352 },
    { "city": "Sesto San Giovanni", "lat": 45.5326, "lng": 9.2348 },
    { "city": "Varese", "lat": 45.8176, "lng": 8.8264 },
    { "city": "Pozzuoli", "lat": 40.8279, "lng": 14.1363 },
    { "city": "Cinisello Balsamo", "lat": 45.5571, "lng": 9.2214 },
    { "city": "Corigliano-Rossano", "lat": 39.5757, "lng": 16.7662 },
    { "city": "Aprilia", "lat": 41.5898, "lng": 12.6501 },
    { "city": "Asti", "lat": 44.9009, "lng": 8.2069 },
    { "city": "Casoria", "lat": 40.9063, "lng": 14.2959 },
    { "city": "Caserta", "lat": 41.0723, "lng": 14.3323 },
    { "city": "Ragusa", "lat": 36.9251, "lng": 14.7307 },
    { "city": "Carpi", "lat": 44.7835, "lng": 10.8850 },
    { "city": "Gela", "lat": 37.0650, "lng": 14.2307 },
    { "city": "Pavia", "lat": 45.1847, "lng": 9.1615 },
    { "city": "Cremona", "lat": 45.1333, "lng": 10.0227 },
    { "city": "Altamura", "lat": 40.8296, "lng": 16.5538 },
    { "city": "Imola", "lat": 44.3541, "lng": 11.7132 },
    { "city": "L’Aquila", "lat": 42.3498, "lng": 13.3999 },
    { "city": "Quartu Sant'Elena", "lat": 39.2455, "lng": 9.1835 },
    { "city": "Lamezia Terme", "lat": 38.9659, "lng": 16.3092 },
    { "city": "Massa", "lat": 44.0220, "lng": 10.1408 },
    { "city": "Viterbo", "lat": 42.4202, "lng": 12.1048 },
    { "city": "Potenza", "lat": 40.6405, "lng": 15.8056 },
    { "city": "Trapani", "lat": 38.0186, "lng": 12.5137 },
    { "city": "Cosenza", "lat": 39.3090, "lng": 16.2502 },
    { "city": "Pomezia", "lat": 41.6676, "lng": 12.5023 },
    { "city": "Castellammare di Stabia", "lat": 40.7006, "lng": 14.4812 },
    { "city": "Vigevano", "lat": 45.3167, "lng": 8.8606 },
    { "city": "Vittoria", "lat": 36.9537, "lng": 14.5332 },
    { "city": "Afragola", "lat": 40.9220, "lng": 14.3090 },
    { "city": "Viareggio", "lat": 43.8735, "lng": 10.2558 },
    { "city": "Carrara", "lat": 44.0774, "lng": 10.1004 },
    { "city": "Olbia", "lat": 40.9236, "lng": 9.4964 },
    { "city": "Matera", "lat": 40.6664, "lng": 16.6043 },
    { "city": "Fano", "lat": 43.8406, "lng": 13.0194 },
    { "city": "Legnano", "lat": 45.5970, "lng": 8.9153 },
    { "city": "Caltanissetta", "lat": 37.4922, "lng": 14.0449 },
    { "city": "Faenza", "lat": 44.2863, "lng": 11.8832 },
    { "city": "Savona", "lat": 44.3091, "lng": 8.4772 },
    { "city": "Crotone", "lat": 39.0804, "lng": 17.1252 },
    { "city": "Anzio", "lat": 41.4896, "lng": 12.6309 },
    { "city": "Acerra", "lat": 40.9540, "lng": 14.3662 },
    { "city": "Marano di Napoli", "lat": 40.9003, "lng": 14.1923 },
    { "city": "Molfetta", "lat": 41.2009, "lng": 16.5975 },
    { "city": "Cerignola", "lat": 41.2645, "lng": 15.9015 },
    { "city": "Benevento", "lat": 41.1298, "lng": 14.781 },
    { "city": "Moncalieri", "lat": 44.9998, "lng": 7.6825 },
    { "city": "Cuneo", "lat": 44.3845, "lng": 7.5426 },
    { "city": "Agrigento", "lat": 37.3111, "lng": 13.5765 },
    { "city": "Foligno", "lat": 42.9509, "lng": 12.7016 },
    { "city": "Tivoli", "lat": 41.9594, "lng": 12.8022 },
    { "city": "Trani", "lat": 41.2795, "lng": 16.4172 },
    { "city": "Manfredonia", "lat": 41.6290, "lng": 15.9072 },
    { "city": "Siena", "lat": 43.3188, "lng": 11.3308 },
    { "city": "Bisceglie", "lat": 41.2381, "lng": 16.5044 },
    { "city": "Modica", "lat": 36.8459, "lng": 14.7696 },
    { "city": "Bitonto", "lat": 41.1061, "lng": 16.6898 },
    { "city": "Montesilvano", "lat": 42.5075, "lng": 14.1505 },
    { "city": "Sanremo", "lat": 43.8156, "lng": 7.7761 },
    { "city": "Gallarate", "lat": 45.6603, "lng": 8.7916 },
    { "city": "Bagheria", "lat": 38.0789, "lng": 13.5123 },
    { "city": "Avellino", "lat": 40.9142, "lng": 14.7928 },
    { "city": "Portici", "lat": 40.8146, "lng": 14.3372 },
    { "city": "Velletri", "lat": 41.6868, "lng": 12.7772 },
    { "city": "Teramo", "lat": 42.6612, "lng": 13.6986 },
    { "city": "Civitavecchia", "lat": 42.0924, "lng": 11.7954 },
    { "city": "Pordenone", "lat": 45.9564, "lng": 12.6616 },
    { "city": "Cava de' Tirreni", "lat": 40.7004, "lng": 14.7070 },
    { "city": "Rho", "lat": 45.5345, "lng": 9.0363 },
    { "city": "Acireale", "lat": 37.6076, "lng": 15.1647 },
    { "city": "Rovigo", "lat": 45.0711, "lng": 11.7901 },
    { "city": "Aversa", "lat": 40.9715, "lng": 14.2078 },
    { "city": "Mazara del Vallo", "lat": 37.6579, "lng": 12.5923 },
    { "city": "Ercolano", "lat": 40.8050, "lng": 14.3601 }
]