import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import NearIcon from '../../assets/NearIcon.svg';
import { replaceIpfsUrl } from '../Home/Home';
import { BsFilter, BsSearch } from 'react-icons/bs';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

const ExploreContent = ({ onSearchChange, searchQuery }) => {
    const navigate = useNavigate();
    const { viewMethod } = useWallet();
    const [collectionDetails, setCollectionDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleCollectionClick = (series) => {
        const seriesId = series.series_id;
        // Pass additional data needed for the CollectionDetail component
        navigate(`/market/collection/${seriesId}`, { state: { seriesId } });
    };
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const updateSize = () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    const handleInputChange = (event) => {
        onSearchChange(event.target.value);
    };

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    });

    useEffect(() => {
        if (viewMethod) {
            const fetchCollectionsList = async () => {
                setIsLoading(true);
                try {
                    const seriesIDs = await viewMethod(NFT_CONTRACT_NAME, "get_series", { limit: null });
                    if (seriesIDs.length === 0) {
                        console.log("No series IDs found!");
                        return;
                    }

                    setCollectionDetails(seriesIDs);
                } catch (error) {
                    console.error('Error fetching series details:', error);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchCollectionsList();
        }
    }, [viewMethod]);

    const filteredCollectionDetails = collectionDetails.filter(series =>
        series.metadata.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="ExploreMainContainer">
            <div className="SearchTag">
                <div className="SearchContainer">
                    <div className="SearchBox">
                        <input
                            type="text"
                            placeholder="Search items, collections"
                            onChange={handleInputChange}  // Added onChange handler
                        />
                        <div className="SearchButton">
                            <BsSearch />
                        </div>
                    </div>
                    {/* <div className="FunnelBox">
                        <div className="FunnelButton">
                            <BsFilter />
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="exp-collections">

                <div className="container-tagExp">
                    <h1 className="ExploreTag">Explore Collections</h1>
                </div>

                {isLoading ? (
                    <div className="spinner-backdrop">
                        <div className="spinner-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                ) : filteredCollectionDetails.length > 0 ? (
                    <div className="ExploreCollections">
                        {filteredCollectionDetails.map((series, index) => {
                            const seriesModifiedUrl = replaceIpfsUrl(series?.metadata?.media);

                            return (
                                <div className="ExplorerCollectionCard" key={index} onClick={() => handleCollectionClick(series)}>
                                    <div className="ImgContainer">
                                        <LazyLoadImage className="CollectionImg" alt={series.metadata.title} effect="blur" src={`${seriesModifiedUrl}`} placeholderSrc={`${seriesModifiedUrl}`} />
                                    </div>

                                    <p className="CollectionName">{series.metadata.title}</p>

                                    {/* <div className="CollectionInfo">
                                        <p className="CollectionBasePrice">Price: ?</p>
                                        <img className="CollectionIcon" src={NearIcon} alt="Near Icon" />
                                    </div> */}
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <p>No Collection found</p>
                )}
            </div>
        </div>
    );
};

export default ExploreContent;
