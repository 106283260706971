import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { BsEmojiSmile, BsEmojiLaughing, BsEmojiSunglasses, BsEmojiExpressionless, BsEmojiDizzy, BsEmojiAngry, BsXCircle } from 'react-icons/bs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { MintNFT, generateImage, containsForbiddenKeyword, moderateContent } from '.';
import { useWallet } from '../../../gateway/web3Login/walletAuth';

import verse from '../../../assets/verseAD.jpg';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

const moodOptions = [
    { mood: "Happy", icon: <BsEmojiSmile /> },
    { mood: "Laughing", icon: <BsEmojiLaughing /> },
    { mood: "Cool", icon: <BsEmojiSunglasses /> },
    { mood: "Indifferent", icon: <BsEmojiExpressionless /> },
    { mood: "Dazed", icon: <BsEmojiDizzy /> },
    { mood: "Angry", icon: <BsEmojiAngry /> },
];

const AdModal = ({ isLoading, setIsLoading, show, onHide, setGenerateImageModal, fetchAdState, onAdClosed }) => {
    const { callMethod } = useWallet();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [countdown, setCountdown] = useState(3);

    useEffect(() => {
        // Reset the countdown and button state whenever the modal is shown
        if (show) {
            setCountdown(3);
            setIsButtonEnabled(false);
        }
    }, [show]);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        } else {
            setIsButtonEnabled(true);
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    const handleCloseAd = async () => {
        if (!isButtonEnabled) return;
        setIsLoading(true);
        try {
            await callMethod(NFT_CONTRACT_NAME, "increment_user_ad_counter");
            await fetchAdState(); // Refresh ad state
            onAdClosed(); // Call the callback function after ad is closed
        } catch (error) {
            console.error("Error incrementing counter: ", error);
        } finally {
            onHide(); // Hide current modal
            setGenerateImageModal(false);
        }
    };

    return (
        <>
            {isLoading ? (
                <div className="spinner-backdrop">
                    <div className="spinner-container">
                        <div className="spinner"></div>
                    </div>
                </div>
            ) : (
                <Modal centered show={show} onHide={handleCloseAd} className="exclusive-modal">
                    <Modal.Header closeButton={isButtonEnabled}>
                        <Modal.Title>Watch Advertisement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LazyLoadImage
                            alt="Advertisement"
                            effect="blur"
                            src={verse}
                            placeholderSrc={verse}
                            style={{ width: '100%' }}
                        />
                        <p style={{ padding: '0px', margin: '10px' }}>{countdown > 0 ? `You can close this ad in ${countdown} seconds.` : 'You can now close this ad.'}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="CloseImageButton" onClick={handleCloseAd} disabled={!isButtonEnabled}>
                            <BsXCircle />
                            Close
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

const ImageGenerationModal = ({ userCity, userWeather, show, onHide, setGenerateImageModal, collectionTitleModal, setCollectionTitleModal }) => {
    const { signedAccountId, viewMethod, callMethod } = useWallet();
    const [imageUrl, setImageUrl] = useState('');
    const [userQuote, setUserQuote] = useState('');
    const [userMood, setUserMood] = useState('');
    const [isAdState, setIsAdState] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showAdModal, setShowAdModal] = useState(false);
    const [imageGeneratedWithoutLogin, setImageGeneratedWithoutLogin] = useState(false);
    const [inputErrors, setInputErrors] = useState({ quote: false, mood: false });

    const fetchAdState = async () => {
        try {
            const adsUserCount = await viewMethod(NFT_CONTRACT_NAME, "get_user_ad_counter", { account_id: signedAccountId });
            const adsTotalCount = await viewMethod(NFT_CONTRACT_NAME, "get_total_ad_counter", {});
            const adsState = await viewMethod(NFT_CONTRACT_NAME, "get_user_ad_state", { account_id: signedAccountId });
            setIsAdState(adsState);
            return adsState;
        } catch (error) {
            console.error("Error fetching ads state: ", error);
            return false;
        }
    };

    // Fetch the current ad state from the smart contract
    useEffect(() => {
        if (viewMethod && signedAccountId && NFT_CONTRACT_NAME) {
            fetchAdState();
        }
    }, [signedAccountId, viewMethod]);

    const toggleMood = mood => {
        setUserMood(mood);
        setInputErrors({ ...inputErrors, mood: false });
    };

    const handleQuoteChange = (event) => {
        setInputErrors({ ...inputErrors, quote: false, forbiddenKeyword: false, openaiModeration: false });
        setUserQuote(event.target.value);
    };

    const validateInputs = () => {
        // Check if the quote is empty
        const isQuoteEmpty = !userQuote.trim();
        const isMoodEmpty = !userMood;
        setInputErrors({ quote: isQuoteEmpty, mood: isMoodEmpty });
        return !isQuoteEmpty && !isMoodEmpty;
    };

    const handleGenerateImage = async () => {
        if (!validateInputs()) return;

        console.log("isAdState:", isAdState);
        // Check for monuments & famoun person moderation
        if (containsForbiddenKeyword(userQuote)) {
            setInputErrors(errors => ({ ...errors, forbiddenKeyword: true }));
            return;
        }

        // Check for content violations using OpenAI moderation
        const moderationResult = await moderateContent(userQuote);
        if (moderationResult.error) {
            setInputErrors(errors => ({ ...errors, openaiModeration: true }));
            return;
        }

        // Only set isLoading to true if signed in, otherwise handle it inside the handleImageGenerationWithoutLogin
        if (signedAccountId) {
            if (isAdState <= 0) {  // Check if there are available ad credits
                setShowAdModal(true); // Show the ad modal if the user is signed in
            }
            onHide(); // Hide current modal
            await handleImageGenerationWithLogin();
        } else {
            await handleImageGenerationWithoutLogin();
        }
    };

    const handleImageGenerationWithoutLogin = async () => {
        if (imageGeneratedWithoutLogin) {
            alert('Please log in with your wallet to generate more images.');
            return null;
        }

        setIsLoading(true);
        // Implement this function to generate the image
        // console.log("needs to uncomment to use OpenAi");
        const prompt = `the image must represent the ${userMood} emotion, the ${userWeather} weather forecast and the following thought ${userQuote}`;

        try {
            const result = await generateImage(prompt);
            // const result = image19;

            if (result.url && !signedAccountId) {
                setImageUrl(result.url);
                // setImageUrl(result);
                localStorage.setItem("image", result.url);
                localStorage.setItem("mood", userMood);
                localStorage.setItem("quote", userQuote);
                setImageGeneratedWithoutLogin(true);
                setCollectionTitleModal(true); // Prepare to open the MintNFT modal
                setGenerateImageModal(false); // Close the image generation modal
            } else {
                setInputErrors(errors => ({ ...errors, quote: true })); // General error handling
            }
        } catch (error) {
            console.error('Error during image generation:', error);
            setInputErrors(errors => ({ ...errors, quote: true })); // General error handling
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageGenerationWithLogin = async () => {
        // If not adState, just return without setting isLoading
        let adState = false;
        if (signedAccountId) {
            adState = await fetchAdState();
            // If not adState, just return without setting isLoading
            if (adState === 0) {
                setShowAdModal(true);
                return;
            }
        }

        setIsLoading(true);
        // This function will be called after ad has been closed, so we should set isLoading here
        // Implement this function to generate the image
        // console.log("needs to uncomment to use OpenAi");
        const prompt = `the image must represent the ${userMood} emotion, the ${userWeather} weather forecast and the following thought ${userQuote}`;

        try {
            const result = await generateImage(prompt);
            // const result = image19;

            if (!result.error) {
                // Directly pass the generated image URL to the MintNFT component
                // console.log("needs to uncomment to use OpenAi");
                setImageUrl(result.url);
                // setImageUrl(result);
                localStorage.setItem("image", result.url);
                localStorage.setItem("mood", userMood);
                localStorage.setItem("quote", userQuote);
                setUserQuote(userQuote);
                setUserMood(userMood);
                setCollectionTitleModal(true); // Prepare to open the MintNFT modal
                setGenerateImageModal(false); // Close the image generation modal
                await callMethod(NFT_CONTRACT_NAME, "decrement_user_ad_state");
                await fetchAdState(); // Refresh ad state        
            } else {
                setInputErrors(errors => ({ ...errors, quote: true })); // General error handling
            }
        } catch (error) {
            console.error('Error during image generation:', error);
            setInputErrors(errors => ({ ...errors, quote: true })); // General error handling
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? (
                <div className="spinner-backdrop">
                    <div className="spinner-container">
                        <div className="spinner"></div>
                    </div>
                </div>
            ) : (
                <Modal centered show={show} onHide={onHide} className="exclusive-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Capture your feelings in a digital masterpiece!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="generated-image-container">
                            <Form.Control
                                as="textarea"
                                rows={2}
                                placeholder="Daily Inspiration..."
                                value={userQuote}
                                onChange={handleQuoteChange}
                                className="quote-input"
                                autoFocus
                            />
                            {inputErrors.quote && <p className="error-message">Please enter a quote.</p>}

                            <h1 className="how-do-you-feel">What's your mood like right now?</h1>
                            <div className="emoji-selection">
                                {moodOptions.map((option, index) => (
                                    <div
                                        key={index}
                                        className={`emoji-button ${userMood === option.mood ? 'selected' : ''}`}
                                        onClick={() => toggleMood(option.mood)}
                                        title={option.mood}
                                    >
                                        {option.icon}
                                        <span>{option.mood}</span>
                                    </div>
                                ))}
                            </div>
                            {inputErrors.mood && <p className="error-message">Please select a mood.</p>}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={onHide} className="CloseImageButton">
                            Close
                        </button>
                        <button onClick={handleGenerateImage} className="SubmitImageButton">
                            Generate
                        </button>
                    </Modal.Footer>
                </Modal>
            )}

            <MintNFT
                show={collectionTitleModal}
                onHide={() => { setCollectionTitleModal(false) }}
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                userMood={userMood}
                setUserMood={setUserMood}
                userQuote={userQuote}
                setUserQuote={setUserQuote}
                userWeather={userWeather}
                userCity={userCity}
                setCollectionTitleModal={setCollectionTitleModal}
                setGenerateImageModal={setGenerateImageModal}
            // isLoading={isLoading}
            // setIsLoading={setIsLoading}
            />

            <AdModal
                show={showAdModal}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                onHide={() => { setShowAdModal(false) }}
                setCollectionTitleModal={setCollectionTitleModal}
                setGenerateImageModal={setGenerateImageModal}
                isAdState={isAdState}
                fetchAdState={fetchAdState}
                onAdClosed={handleImageGenerationWithLogin}
            />

        </>
    );
};

export default ImageGenerationModal;
