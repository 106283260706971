import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BsBoxSeam, BsCalendar3, BsCheckCircle, BsHeart, BsHeartFill, BsHearts, BsPerson, BsTags, BsWindowStack, BsWindowX, BsXCircle } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { convertWeiToMegaEther, truncateAddress } from '../../../utils';
import { getCityIcon, getMoodIcon, getShareIcon, getWeatherIcon } from '../../Home/Map/IconOptions';
import { useWallet } from '../../../gateway/web3Login/walletAuth';
import moment from 'moment';
import { useFetchAllUsersInfo } from '../../UserProfile/useFetchUserInfo';
import { replaceIpfsUrl } from '../../Home/Home';
import { fetchNftPrice } from './priceUtils';
import { approveNFT, updateNFTPrice, removeSale, makeOffer } from './nftActions';
import NearIcon from '../../../assets/NearIcon.svg';
import { useLikes } from './LikeContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

export const NftContractModal = ({ show, onHide, nft }) => {
    const [saleConditions, setSaleConditions] = useState('');
    const [approveModalVisible, setApproveModalVisible] = useState(false);
    const [updatePriceModalVisible, setUpdatePriceModalVisible] = useState(false);
    const [offerModalVisible, setOfferModalVisible] = useState(false);
    const [confirmRemoveSaleModalVisible, setConfirmRemoveSaleModalVisible] = useState(false);
    const [marketplace, setMarketplace] = useState('moodweather');
    const [isListedForSale, setIsListedForSale] = useState(false);
    const [currentSaleConditions, setCurrentSaleConditions] = useState(null);
    const [seriesPrice, setSeriesPrice] = useState(null);
    const navigate = useNavigate();
    const { signedAccountId, viewMethod, callMethod } = useWallet();
    const { otherUsersPlanColor, otherUsersAvatar } = useFetchAllUsersInfo(nft?.owner_id);
    const { likes, getLikes, hasUserLiked } = useLikes();
    const [collectionDetails, setCollectionDetails] = useState({});

    const normalizeTokenId = (id) => {
        if (!id) return '';
        const [normalizedId] = id.split(':');
        return normalizedId;
    };

    useEffect(() => {
        if (nft && nft.token_id) {
            getLikes(nft.token_id);
            if (signedAccountId) {
                hasUserLiked(signedAccountId, nft.token_id);
            }
        }
    }, [nft, getLikes, hasUserLiked, signedAccountId]);

    useEffect(() => {
        const fetchPrice = async () => {
            const priceData = await fetchNftPrice(viewMethod, nft.token_id);
            if (priceData) {
                setIsListedForSale(priceData.isListedForSale);
                setCurrentSaleConditions(priceData.listedPrice);
                setSeriesPrice(priceData.seriesPrice);
                setSaleConditions(priceData.isListedForSale ? priceData.listedPrice : priceData.seriesPrice);
            }
        };

        if (nft && nft.token_id) {
            fetchPrice();
        }
    }, [nft, viewMethod]);

    // Update useEffect that fetches global sales
    useEffect(() => {
        if (viewMethod && nft?.token_id) {
            const normalizedId = normalizeTokenId(nft.token_id);
            if (normalizedId) {
                const fetchCollection = async () => {
                    try {
                        const details = await viewMethod(NFT_CONTRACT_NAME, "get_series_details", { id: normalizedId.toString() });
                        setCollectionDetails(details);
                    } catch (error) {
                        console.error('Error fetching collection details:', error);
                        throw error;
                    }
                };
                fetchCollection();
            }
        }
    }, [viewMethod, nft]);

    if (!nft || !nft.metadata || !nft.jsonData) return null;

    const issueDate = nft?.metadata?.issued_at;
    const convertDate = issueDate ? moment(issueDate).format("MMM. D, YYYY") : "Not recorded";
    const mediaUrl = replaceIpfsUrl(nft.metadata.media);
    const handleCollectionClick = () => navigate(`/market/collection/${nft.series_id}`, { state: { seriesId: nft.series_id } });
    const handleInputChange = (e) => setSaleConditions(e.target.value);
    const handleApproveClick = () => setApproveModalVisible(true);

    const handleApproveConfirm = async () => {
        try {
            const price = parseFloat(saleConditions);
            if (isNaN(price) || price <= 0) {
                alert("Please enter a valid price");
                return;
            }
            await approveNFT(callMethod, nft.token_id, price, marketplace);
            setApproveModalVisible(false);
        } catch (error) {
            console.error('Error during approval process:', error);
        }
    };

    const handleUpdatePriceClick = () => {
        setUpdatePriceModalVisible(true);
    };

    const handleUpdatePriceConfirm = async () => {
        try {
            const price = parseFloat(saleConditions);
            if (isNaN(price) || price <= 0) {
                alert("Please enter a valid price");
                return;
            }
            await updateNFTPrice(callMethod, nft.token_id, price);
            setUpdatePriceModalVisible(false);
        } catch (error) {
            console.error('Error during price update process:', error);
        }
    };

    const handleCancelSale = async () => {
        try {
            await removeSale(callMethod, nft.token_id);
            setConfirmRemoveSaleModalVisible(false);
        } catch (error) {
            console.error('Error cancelling sale:', error);
            throw error;
        }
    };

    const handleMakeOffer = async () => {
        try {
            const price = parseFloat(saleConditions);
            if (isNaN(price) || price <= 0) {
                alert("Please enter a valid offer price");
                return;
            }
            await makeOffer(callMethod, nft.token_id, price);
            setOfferModalVisible(false);
        } catch (error) {
            console.error('Error making offer:', error);
            throw error;
        }
    };

    const collectionTitle = collectionDetails?.metadata?.title;

    return (
        <>
            <Modal size="lg" centered show={show} onHide={onHide} backdrop="static" key={nft?.owner_id} className="exclusive-modal">
                <Modal.Header closeButton />
                <Modal.Body>
                    {nft.jsonData && nft.jsonData.attributes && (
                        <div className="modal-container">
                            <div className="two-sides">
                                <div className="left-side">
                                    <div className="nft-modal">
                                        <LazyLoadImage
                                            alt={nft.metadata.title}
                                            effect="blur"
                                            src={mediaUrl}
                                            placeholderSrc={mediaUrl}
                                            className="nft-image"
                                        />
                                    </div>

                                    <div className="attribute-activity-container">
                                        <div className="attribute-container">
                                            <div className="attribute-header">
                                                <span className="attribute-tag">Traits</span>
                                            </div>
                                            <div className="attribute-space-evenly">
                                                {nft.jsonData.attributes.map((attribute, attrIndex) => (
                                                    <div key={`attribute-${nft.token_id}-${attrIndex}`} className="attribute-box">
                                                        <div className="attribute-title">{attribute.trait_type}</div>
                                                        <div className="attribute-value-box">
                                                            {attribute.trait_type === 'Mood' && getMoodIcon(attribute.value)}
                                                            {attribute.trait_type === 'Weather' && getWeatherIcon(attribute.value)}
                                                            {attribute.trait_type === 'City' && getCityIcon(attribute.value)}
                                                            {attribute.trait_type === 'Shared' && getShareIcon(attribute.value)}
                                                            <span className="attribute-value">{attribute.value}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="right-side">
                                    <div className="details-container">
                                        <div className="owner-container">
                                            <Link to={`/profile/${nft.owner_id}`} className="owner-details" style={{ backgroundColor: `${otherUsersPlanColor}` }}>
                                                {otherUsersAvatar ? (
                                                    <LazyLoadImage
                                                        alt="Owner Avatar"
                                                        effect="blur"
                                                        src={otherUsersAvatar}
                                                        placeholderSrc={otherUsersAvatar}
                                                        className="owner-avatar"
                                                    />
                                                ) : (
                                                    <BsPerson className="owner-avatar" alt="No Avatar" />
                                                )}
                                                <span className="owner-name">{truncateAddress(nft.owner_id)}</span>
                                            </Link>
                                            <div className="nft-like">
                                                {signedAccountId && (
                                                    <>
                                                        <p className="follow-user" style={{ border: `3px solid ${otherUsersPlanColor}` }} >FOLLOW</p>
                                                        {/* <p className="follow-user" style={{ backgroundColor: `${otherUsersPlanColor}` }} >FOLLOW</p> */}
                                                        <LikeButton nft={nft} onLike={(liked) => console.log('Liked:', liked)} />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="description-area">
                                            <h2>{nft.metadata.title}</h2>
                                            <div className="typing-text">{nft.metadata.description}</div>
                                            <div className="nft-statistic">
                                                <div className="nft-date-container">
                                                    <BsCalendar3 className="nft-date-icon" />
                                                    <div className="nft-date">{convertDate}</div>
                                                </div>
                                                <div className="nft-likes">
                                                    <BsHearts />
                                                    {likes[nft.token_id] || 0}
                                                </div>
                                                {/* <div className="nft-comments">COMMENTS</div> */}
                                            </div>
                                        </div>

                                        <div className="offer-price">
                                            {signedAccountId && (
                                                <>
                                                    {isListedForSale ? (
                                                        <div className="buttons-container">
                                                            {signedAccountId === nft.owner_id ? (
                                                                <>
                                                                    <div className="cancel-button" onClick={() => setConfirmRemoveSaleModalVisible(true)}>
                                                                        <BsWindowX className="tag-icon" alt="Cancel Sale" />
                                                                        <span>Cancel Sale</span>
                                                                    </div>
                                                                    <div className="buy-button" style={{ backgroundColor: `${otherUsersPlanColor}` }} onClick={handleUpdatePriceClick}>
                                                                        <BsTags className="tag-icon" alt="Update Price" />
                                                                        <span>Update Price</span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="offer-button" style={{ backgroundColor: `${otherUsersPlanColor}` }} onClick={() => setOfferModalVisible(true)}>
                                                                    <BsBoxSeam className="tag-icon" alt="Make Offer" />
                                                                    <span>Make Offer</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        signedAccountId === nft.owner_id && (
                                                            <div className="buttons-container">
                                                                <div className="sell-button" style={{ backgroundColor: `${otherUsersPlanColor}` }} onClick={handleApproveClick}>
                                                                    <BsWindowStack className="tag-icon" alt="Sell" />
                                                                    <span>Sell</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}

                                                    <div className="price-details" style={{ backgroundColor: `${otherUsersPlanColor}` }}>
                                                        {isListedForSale ? (
                                                            <span>
                                                                <img className="price-image" src={NearIcon} alt="Near Icon" />
                                                                <p className="price-condition">{currentSaleConditions}</p>
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                <img className="price-image" src={NearIcon} alt="Near Icon" />
                                                                <p className="price-condition">{seriesPrice ? `${seriesPrice}` : '?'}</p>
                                                            </span>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className="collection-token" onClick={handleCollectionClick}>
                                            <div className="collection">
                                                <h1>Collection</h1>
                                                <span className="collection-name">{collectionTitle}</span>
                                            </div>
                                            <div className="token">
                                                <h1>Token ID</h1>
                                                <span className="token-id">{nft.token_id}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                </Modal.Body>
            </Modal >

            {approveModalVisible && (
                <Modal centered show={approveModalVisible} onHide={() => setApproveModalVisible(false)} backdrop="static" className="exclusive-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>List NFT for Sale</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ flex: 1, marginRight: '10px' }}>
                                <label htmlFor="salePrice">Price (in NEAR):</label>
                                <input
                                    className="salePrice-form-control"
                                    type="number"
                                    step="0.00001"
                                    id="salePrice"
                                    min={0.01}
                                    value={saleConditions}
                                    onChange={handleInputChange}
                                    placeholder={`Enter a price`}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <label htmlFor="marketplace">Marketplace:</label>
                                <select id="marketplace" value={marketplace} onChange={(e) => setMarketplace(e.target.value)} className="salePrice-form-control">
                                    <option value="moodweather">MoodWeather</option>
                                    <option value="paras" disabled>Paras</option>
                                    <option value="opensea" disabled>OpenSea</option>
                                </select>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className="CloseImageButton" onClick={() => setApproveModalVisible(false)}>
                            <BsXCircle /> Cancel
                        </div>
                        <div className="SubmitImageButton" onClick={handleApproveConfirm}>
                            <BsCheckCircle /> List
                        </div>
                    </Modal.Footer>
                </Modal>
            )}

            {updatePriceModalVisible && (
                <Modal centered show={updatePriceModalVisible} onHide={() => setUpdatePriceModalVisible(false)} backdrop="static" className="exclusive-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Update NFT Price</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="newSalePrice">New Price (in NEAR):</label>
                            <input
                                className="salePrice-form-control"
                                type="number"
                                step="0.00001"
                                id="newSalePrice"
                                min={0.01}
                                value={saleConditions}
                                onChange={handleInputChange}
                                placeholder={`Enter new price`}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="CloseImageButton" onClick={() => setUpdatePriceModalVisible(false)}>
                            <BsXCircle /> Cancel
                        </div>
                        <div className="SubmitImageButton" onClick={handleUpdatePriceConfirm}>
                            <BsCheckCircle /> Update Price
                        </div>
                    </Modal.Footer>
                </Modal>
            )}

            {offerModalVisible && (
                <Modal centered show={offerModalVisible} onHide={() => setOfferModalVisible(false)} backdrop="static" className="exclusive-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Make an Offer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="offerPrice">Offer Price (in NEAR):</label>
                            <input
                                className="salePrice-form-control"
                                type="number"
                                step="0.00001"
                                id="offerPrice"
                                min={0.01}
                                value={saleConditions}
                                onChange={handleInputChange}
                                placeholder={`Enter a price`}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="CloseImageButton" onClick={() => setOfferModalVisible(false)}>
                            <BsXCircle /> Cancel
                        </div>
                        <div className="SubmitImageButton" onClick={handleMakeOffer}>
                            <BsCheckCircle /> Confirm Offer
                        </div>
                    </Modal.Footer>
                </Modal>
            )}

            {confirmRemoveSaleModalVisible && (
                <Modal centered show={confirmRemoveSaleModalVisible} onHide={() => setConfirmRemoveSaleModalVisible(false)} backdrop="static" className="exclusive-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Sale Cancellation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to cancel the sale of this NFT?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="CloseImageButton" onClick={() => setConfirmRemoveSaleModalVisible(false)}>
                            <BsXCircle /> No
                        </div>
                        <div className="SubmitImageButton" onClick={handleCancelSale}>
                            <BsCheckCircle /> Yes
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export const LikeButton = ({ nft }) => {
    const [liked, setLiked] = useState(false);
    const { signedAccountId, viewMethod, callMethod } = useWallet();
    const { userLikes, updateLikes, likes } = useLikes();
    const { otherUsersPlanColor } = useFetchAllUsersInfo(nft?.owner_id);
    const args = { account_id: signedAccountId, token_id: nft.token_id };

    useEffect(() => {
        async function fetchLikeStatus() {
            if (nft && nft.token_id && signedAccountId) {
                const liked = await viewMethod(NFT_CONTRACT_NAME, "has_user_liked", args);
                setLiked(liked);
            }
        }
        fetchLikeStatus();
    }, [nft, viewMethod, signedAccountId]);

    const toggleLike = async () => {
        if (!signedAccountId) return;

        const method = liked ? "unset_user_like" : "set_user_like";

        try {
            await callMethod(NFT_CONTRACT_NAME, method, args);
            const newLikeCount = liked ? (likes[nft.token_id] || 0) - 1 : (likes[nft.token_id] || 0) + 1;
            setLiked(!liked);
            updateLikes(nft.token_id, newLikeCount);
        } catch (error) {
            console.error("Error toggling like status:", error);
        }
    };

    return (
        signedAccountId && (
            <div
                className="nft-modal-button"
                style={{ backgroundColor: `${otherUsersPlanColor}` }}
                onClick={toggleLike}
            >
                {liked ? <BsHeartFill /> : <BsHeart />}
            </div>
        )
    );
};
