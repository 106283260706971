import axios from 'axios';

// Function to fetch the visitor's IP address
export const getVisitorIP = async () => {
    try {
        const response = await axios.get('https://api.ipify.org');
        return response.data; // ipify just returns the IP address in the response body
    } catch (error) {
        console.error('Failed to fetch IP:', error);
        throw error;
    }
};

// Function to fetch geographic information using the IP address
export const fetchGeoInfoFromIP = async (ipAddress) => {
    try {
        if (navigator.geolocation) {
            // get the current users location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // save the geolocation coordinates in two variables
                    const { latitude, longitude } = position.coords;
                    // update the value of userlocation variable
                    console.log("latitude:", latitude);
                    console.log("longitude:", longitude);
                },
                // if there was an error getting the users location
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        }

        const response = await axios.get(`https://ip-api.com/json/${ipAddress}?fields=status,country,countryCode,region,regionName,city,zip`);
        if (response.data.status !== 'success') {
            throw new Error(`API error: ${response.data.message}`);
        }
        return response.data;
    } catch (error) {
        console.error('Failed to fetch location info:', error);
        throw error;
    }
};
