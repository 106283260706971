import React, { createContext, useState, useContext, useCallback } from 'react';
import { useWallet } from '../../../gateway/web3Login/walletAuth';

const LikeContext = createContext(null);
const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

export const LikeProvider = ({ children }) => {
    const { viewMethod } = useWallet();
    const [likes, setLikes] = useState({});
    const [userLikes, setUserLikes] = useState({});

    const getLikes = useCallback(async (tokenId) => {
        if (viewMethod && tokenId) {
            try {
                const numLikes = await viewMethod(NFT_CONTRACT_NAME, "get_user_likes", { token_id: tokenId });
                setLikes((prevLikes) => ({ ...prevLikes, [tokenId]: numLikes }));
            } catch (error) {
                console.error('Error getting likes:', error);
            }
        }
    }, [viewMethod]);

    const hasUserLiked = useCallback(async (accountId, tokenId) => {
        if (viewMethod && accountId && tokenId) {
            try {
                const liked = await viewMethod(NFT_CONTRACT_NAME, "has_user_liked", { account_id: accountId, token_id: tokenId });
                setUserLikes((prevUserLikes) => ({ ...prevUserLikes, [tokenId]: liked }));
            } catch (error) {
                console.error('Failed to fetch user like status:', error);
            }
        }
    }, [viewMethod]);

    const updateLikes = useCallback((tokenId, likeCount) => {
        setLikes((prevLikes) => ({ ...prevLikes, [tokenId]: likeCount }));
    }, []);

    return (
        <LikeContext.Provider value={{ likes, userLikes, getLikes, hasUserLiked, updateLikes }}>
            {children}
        </LikeContext.Provider>
    );
};

export const useLikes = () => useContext(LikeContext);
