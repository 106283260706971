import { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import {
    getAuth, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider,
    onAuthStateChanged
} from 'firebase/auth';
import { getDatabase, ref, set, update, get } from 'firebase/database';

const REACT_APP_FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const REACT_APP_FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const REACT_APP_FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;

// Ensure environment variables are properly loaded
if (!REACT_APP_FIREBASE_API_KEY || !REACT_APP_FIREBASE_MESSAGING_SENDER_ID || !REACT_APP_FIREBASE_APP_ID) {
    console.error('Missing Firebase configuration environment variables.');
}

export const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_API_KEY,
    authDomain: "nft-sentimeteo.firebaseapp.com",
    databaseURL: "https://nft-sentimeteo-default-rtdb.firebaseio.com",
    projectId: "nft-sentimeteo",
    storageBucket: "nft-sentimeteo.appspot.com",
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const GoogleProvider = new GoogleAuthProvider();
const FacebookProvider = new FacebookAuthProvider();

export { auth, GoogleProvider, FacebookProvider, database }

export function useAuth() {
    const [isWeb2LoggedIn, setIsWeb2LoggedIn] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setIsWeb2LoggedIn(!!user);
            if (user) {
                const userRef = ref(database, 'users/' + user.uid);
                try {
                    const snapshot = await get(userRef);
                    if (snapshot.exists()) {
                        // Update last login date
                        update(userRef, {
                            lastLogin: Date.now()
                        });
                    } else {
                        // Set first login and last login dates
                        set(userRef, {
                            username: user.displayName || 'Anonymous',
                            email: user.email,
                            id: user.uid,
                            firstLogin: Date.now(),
                            lastLogin: Date.now()
                        });
                    }

                    // Handle referral logic
                    const referrerId = localStorage.getItem('referrerId');
                    if (referrerId) {
                        const referralRef = ref(database, 'referrals/' + user.uid);
                        const referralSnapshot = await get(referralRef);
                        if (referralSnapshot.exists()) {
                            alert('This referral link is no longer valid');
                        } else {
                            set(referralRef, {
                                username: user.displayName || 'Anonymous',
                                email: user.email,
                                referredBy: referrerId,
                                id: user.uid,
                                firstLogin: Date.now()
                            });
                            localStorage.removeItem('referrerId');
                        }
                    }
                } catch (error) {
                    console.error('Error accessing user data:', error);
                }
            }
        });
        return () => unsubscribe();
    }, []);

    return { isWeb2LoggedIn, setIsWeb2LoggedIn };
}

export async function signInWithProvider(provider, setProfilePicture) {
    try {
        const result = await signInWithPopup(auth, provider);
        if (provider instanceof FacebookAuthProvider && result.user) {
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;

            fetchFacebookProfilePicture(result.user.providerData[0].uid, accessToken, setProfilePicture);
        }
    } catch (error) {
        handleAuthenticationError(error);
    }
}

function fetchFacebookProfilePicture(uid, accessToken, setProfilePicture) {
    fetch(`https://graph.facebook.com/${uid}/picture?type=large&access_token=${accessToken}`)
        .then(response => response.blob())
        .then(blob => {
            setProfilePicture(URL.createObjectURL(blob));
        })
        .catch(error => {
            console.error('Error fetching Facebook profile picture:', error);
        });
}

function handleAuthenticationError(error) {
    switch (error.code) {
        case 'auth/cancelled-popup-request':
            console.log('Authentication popup closed by user.');
            break;
        case 'auth/popup-closed-by-user':
            console.log('Authentication popup was closed before completion.');
            break;
        default:
            console.error('Authentication error:', error);
            break;
    }
}
