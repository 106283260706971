import React, { createContext, useState, useContext, useEffect } from 'react';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import { useFetchNFTDetails } from '../Home/useFetchNFTDetails';
import { replaceIpfsUrl } from '../Home/Home';

const AvatarContext = createContext(null);
const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

export const AvatarProvider = ({ children }) => {
    const { signedAccountId, viewMethod } = useWallet();
    const [avatarId, setAvatarId] = useState(null);
    const { nftDetails } = useFetchNFTDetails(avatarId);
    const [avatarUrl, setAvatarUrl] = useState(null);

    useEffect(() => {
        if (nftDetails?.metadata?.media) {
            setAvatarUrl(replaceIpfsUrl(nftDetails.metadata.media));
        }
    }, [nftDetails]);

    useEffect(() => {
        const fetchAvatar = async () => {
            if (signedAccountId) {
                try {
                    const fetchedAvatarId = await viewMethod(NFT_CONTRACT_NAME, "get_user_avatar", { account_id: signedAccountId });
                    setAvatarId(fetchedAvatarId);
                } catch (error) {
                    console.error('Failed to fetch avatar:', error);
                }
            }
        };
        fetchAvatar();
    }, [signedAccountId, viewMethod]);

    const updateAvatar = (newAvatarUrl) => {
        setAvatarUrl(newAvatarUrl);
    };

    return (
        <AvatarContext.Provider value={{ avatar: avatarUrl, setAvatar: setAvatarId, updateAvatar }}>
            {children}
        </AvatarContext.Provider>
    );
};

export const useAvatar = () => useContext(AvatarContext);
