import OpenAI from 'openai';

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    organization: process.env.REACT_APP_OPENAI_ORGANIZATION,
    dangerouslyAllowBrowser: true
});

const forbiddenKeywords = [
    // Famous Persons
    "Einstein", "Shakespeare", "Mozart", "Da Vinci", "Newton", "Galileo", "Beethoven", "Michelangelo", "Van Gogh", "Freud",
    "Marie Curie", "Edison", "Darwin", "Tesla", "Aristotle", "Confucius", "Mandela", "Gandhi", "Cleopatra", "Caesar",
    "Plato", "Socrates", "Homer", "Chopin", "Tchaikovsky", "Bach", "Dostoevsky", "Tolstoy", "Hemingway", "Fitzgerald",
    "Picasso", "Monet", "Rembrandt", "Brahms", "Handel", "Verdi", "Wagner", "Renoir", "Cervantes", "Shelley",
    "Dickens", "Twain", "Hawking", "Feynman", "Euler", "Archimedes", "Pythagoras", "Euclid", "Descartes", "Leibniz",
    "Kant", "Nietzsche", "Kafka", "Orwell", "Salinger", "Woolf", "Austen", "Bronte", "Tolkien", "Rowling",
    "King", "Faulkner", "Hugo", "Dumas", "Voltaire", "Rousseau", "Locke", "Hobbes", "Machiavelli", "Aquinas",
    "Milton", "Keats", "Byron", "Yeats", "Poe", "Blake", "Wordsworth", "Browning", "Eliot", "Frost",
    "Whitman", "Angelou", "Plath", "Neruda", "Garcia Marquez", "Borges", "Camus", "Sartre", "Dali", "Warhol",
    "Pollock", "Klimt", "Gauguin", "Turner", "Hopper", "O'Keeffe", "Rockwell", "Matisse", "Lichtenstein", "Rothko",
    "Bellini", "Berlioz", "Debussy", "Liszt", "Mendelssohn", "Paganini", "Rachmaninoff", "Schoenberg", "Stravinsky", "Vivaldi",
    "Copernicus", "Curie", "Faraday", "Franklin", "Heisenberg", "Maxwell", "Mendel", "Oppenheimer", "Pasteur", "Pauling",
    "Pavlov", "Rutherford", "Salk", "Schrodinger", "Watson", "Crick", "Bohr", "Dirac", "Fermi", "Heidegger", "Russell",
    "Wittgenstein", "Zola", "Balzac", "Baudelaire", "Goethe", "Mann", "Nabokov", "Pushkin", "Rilke", "Tagore", "Yeats",

    // Italian Cities
    "Rome", "Venice", "Milan", "Florence", "Naples", "Turin", "Palermo", "Genoa", "Bologna", "Verona", "Pisa", "Padua",
    "Lecce", "Trieste", "Modena", "Parma", "Perugia", "Ravenna", "Cagliari", "Trento", "Catania", "Bari",
    "Vicenza", "Bergamo", "Messina", "Reggio Calabria", "Ferrara", "Siracusa", "Pavia", "Ancona", "Udine", "Livorno",
    "Salerno", "Foggia", "Latina", "Monza", "Rimini", "Como", "Lucca", "Terni", "Sassari", "Pescara",
    "Arezzo", "Brindisi", "Treviso", "Bolzano", "Pistoia", "Grosseto", "Matera", "Alessandria", "Novara", "Asti",
    "Caserta", "Urbino", "Ragusa", "Cremona", "Taranto", "L'Aquila", "Benevento", "Viterbo", "Trapani", "Cosenza",
    "Potenza", "Avellino", "Campobasso", "Isernia", "Vibo Valentia", "Olbia", "Oristano", "Nuoro", "Lanusei", "Tortolì",
    "Siena", "Frosinone", "Varese", "Pordenone", "Vasto", "Gubbio", "Spoleto", "Orvieto", "Narni", "Todi",
    "Assisi", "Gubbio", "Spello", "Montefalco", "Foligno", "Norcia", "Cascia", "Città di Castello", "Sansepolcro", "Anghiari",
    "Volterra", "San Gimignano", "Colle di Val d'Elsa", "Massa Marittima", "Fiesole", "Certaldo", "Greve in Chianti", "Radda in Chianti", "Gaiole in Chianti", "Castellina in Chianti",
    "Montepulciano", "Pienza", "Cortona", "Chiusi", "Montalcino", "San Quirico d'Orcia", "Bagno Vignoni", "Bagni di San Filippo", "Sarteano", "Radicondoli",
    "Pitigliano", "Sovana", "Sorano", "Manciano", "Saturnia", "Capalbio", "Orbetello", "Porto Santo Stefano", "Porto Ercole", "Talamone",
    "Civitavecchia", "Tarquinia", "Tuscania", "Bolsena", "Montefiascone", "Viterbo", "Bagnoregio", "Ronciglione", "Sutri", "Caprarola",
    "Tivoli", "Frascati", "Grottaferrata", "Castel Gandolfo", "Nemi", "Genzano di Roma", "Ariccia", "Albano Laziale", "Velletri", "Anzio",
    "Nettuno", "Terracina", "Sperlonga", "Gaeta", "Formia", "Minturno", "Fondi", "Lenola", "Itri", "Castelnuovo Parano",
    "Caserta", "Sant'Agata de' Goti", "Piedimonte Matese", "Alife", "Caiazzo", "Sessa Aurunca", "Maddaloni", "Marcianise", "Aversa", "Orta di Atella",
    "Benevento", "Sant'Agata dei Goti", "Montesarchio", "Morcone", "Guardia Sanframondi", "Cerreto Sannita", "San Bartolomeo in Galdo", "Telese Terme", "Pietrelcina", "San Giorgio del Sannio",
    "Avellino", "Atripalda", "Benevento", "Grottaminarda", "Monteforte Irpino", "Solofra", "Montella", "Ariano Irpino", "Sant'Angelo dei Lombardi", "Lacedonia",
    "Salerno", "Amalfi", "Ravello", "Positano", "Praiano", "Furore", "Conca dei Marini", "Minori", "Maiori", "Vietri sul Mare",
    "Paestum", "Agropoli", "Castellabate", "Santa Maria di Castellabate", "Acciaroli", "Ascea", "Velia", "Pollica", "Camerota", "Maratea",
    "Matera", "Altamura", "Gravina in Puglia", "Ginosa", "Laterza", "Pisticci", "Bernalda", "Tricarico", "Miglionico", "Montescaglioso",
    "Taranto", "Grottaglie", "Manduria", "Martina Franca", "Massafra", "Castellaneta", "Crispiano", "Faggiano", "Leporano", "Pulsano",
    "Brindisi", "Ostuni", "Ceglie Messapica", "Carovigno", "Fasano", "Mesagne", "San Vito dei Normanni", "Francavilla Fontana", "Latiano", "San Pietro Vernotico",
    "Lecce", "Gallipoli", "Otranto", "Nardò", "Galatina", "Copertino", "Maglie", "Tricase", "Castrignano del Capo", "Santa Cesarea Terme",
    "Bari", "Polignano a Mare", "Monopoli", "Alberobello", "Locorotondo", "Cisternino", "Conversano", "Mola di Bari", "Bitonto", "Ruvo di Puglia",
    "Foggia", "San Giovanni Rotondo", "Manfredonia", "Vieste", "Peschici", "Rodi Garganico", "Lucera", "San Severo", "Cerignola", "Orta Nova",
    "Barletta", "Trani", "Andria", "Bisceglie", "Corato", "Molfetta", "Giovinazzo", "Terlizzi", "Ruvo di Puglia", "Canosa di Puglia",
    "Potenza", "Melfi", "Venosa", "Lagonegro", "Maratea", "Senise", "Pisticci", "Bernalda", "Matera", "Metaponto",
    "Cosenza", "Rende", "Rossano", "Corigliano Calabro", "Acri", "Cetraro", "Scalea", "Paola", "Amantea", "Belvedere Marittimo",
    "Catanzaro", "Lamezia Terme", "Soverato", "Catanzaro Lido", "Crotone", "Isola di Capo Rizzuto", "Roccella Ionica", "Gioia Tauro", "Locri", "Siderno",
    "Reggio Calabria", "Scilla", "Bova Marina", "Palmi", "Gioia Tauro", "Locri", "Gerace", "Stilo", "Monasterace", "Roccella Ionica",
    "Messina", "Taormina", "Milazzo", "Capo d'Orlando", "Patti", "Barcellona Pozzo di Gotto", "Sant'Agata di Militello", "Lipari", "Vulcano", "Stromboli",
    "Palermo", "Cefalù", "Monreale", "Bagheria", "Corleone", "Castellammare del Golfo", "Scopello", "Trapani", "Erice", "Marsala",
    "Agrigento", "Scala dei Turchi", "Valle dei Templi", "Sciacca", "Favara", "Licata", "Racalmuto", "Canicattì", "Grotte", "Sambuca di Sicilia",
    "Siracusa", "Noto", "Ragusa", "Modica", "Scicli", "Marzamemi", "Portopalo di Capo Passero", "Pachino", "Avola", "Rosolini",
    "Catania", "Acireale", "Taormina", "Caltagirone", "Randazzo", "Bronte",



];

// const containsForbiddenKeyword = (prompt) => {
//     return forbiddenKeywords.some(keyword => prompt.toLowerCase().includes(keyword.toLowerCase()));
// };

const containsForbiddenKeyword = (prompt) => {
    const regex = new RegExp(forbiddenKeywords.join("|"), "i"); // "i" for case-insensitive
    return regex.test(prompt);
};

const moderateContent = async (prompt) => {
    try {
        const response = await openai.moderations.create({ input: prompt });

        // Check if the expected 'results' key is present in the response.
        if (response && Array.isArray(response.results) && response.results.length > 0) {
            const firstResult = response.results[0];
            if (firstResult && typeof firstResult.categories === 'object') {
                const flagged = Object.values(firstResult.categories).some(value => value === true);
                return { error: flagged, message: flagged ? "Content violates our usage policies" : "" };
            }
        }
        // Default to no error if the response structure is unexpected
        return { error: false };
    } catch (error) {
        console.error('Error in moderation:', error);
        const message = error.response && error.response.status === 429 ?
            'Rate limit exceeded' : 'Error during moderation check';
        return { error: true, message, details: error.response || error.message };
    }
};

// // without moderation
// const generateImage = async (prompt) => {
//     try {
//         const response = await openai.createImage({
//             model: "dall-e-3",
//             prompt: prompt,
//             n: 1,
//             size: '1024x1024',
//         });
//         return response.data.data[0].url;
//     } catch (error) {
//         console.error('Error generating image:', error);
//         return '';
//     }
// };

const generateImage = async (prompt) => {
    try {
        const response = await openai.images.generate({
            model: "dall-e-3",
            prompt: prompt,
            n: 1,
            size: '1024x1024',
        });

        return { url: response.data[0].url, error: false };

    } catch (error) {
        if (error.response) {
            // Handle errors from server response
            console.error("Server responded with an error:", error.response.status, error.response.data);
            if (error.response.status === 429) {
                return { error: true, message: 'Rate limit exceeded', details: error.response.data };
            }
        } else if (error.request) {
            // Handle errors with making the request
            console.error("Request made but no response received:", error.request);
            return { error: true, message: 'No response received', details: error.request };
        } else {
            // Handle other errors
            console.error('An error occurred:', error.message);
            return { error: true, message: 'An error occurred', details: error.message };
        }
    }
};

export { openai, generateImage, moderateContent, containsForbiddenKeyword };
