import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, Navbar, Modal, Button } from 'react-bootstrap';
import { BsBell, BsCart, BsCheckCircle, BsListCheck, BsMap, BsPeople, BsPerson, BsXCircle } from 'react-icons/bs';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { useAuth, auth } from '../gateway/web2Login/oAuth';
import { useWallet } from '../gateway/web3Login/walletAuth';
import { useFetchOwnerTokens, useFetchUserAvatar, useFetchUserInfo } from '../Pages/UserProfile/useFetchUserInfo';
import { useAvatar } from '../Pages/UserProfile/AvatarContext';

import { truncateAddress } from '../utils';
import MOODLogo from '../assets/MoodWeatherLogo.svg';
import MOODWideLogo from '../assets/MoodWeather.svg';

const ResponsiveNavbar = () => {
    const { signedAccountId, logOut } = useWallet();
    const { isWeb2LoggedIn, setIsWeb2LoggedIn } = useAuth();
    const avatarData = useAvatar();
    const location = useLocation();

    const { userPlan, userPlanColor } = useFetchUserInfo();
    const { ownerTokens } = useFetchOwnerTokens(signedAccountId);

    const [activeButton, setActiveButton] = useState('board');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [referralLink, setReferralLink] = useState('');
    const [copySuccess, setCopySuccess] = useState('');

    const totalNFTs = ownerTokens.length;
    const avatar = avatarData ? avatarData.avatar : null;

    // const walletValue = 505;

    // useEffect(() => {
    //     const unsubscribe = onAuthStateChanged(auth, (user) => {
    //         setIsWeb2LoggedIn(!!user);
    //     });

    //     return () => unsubscribe();
    // }, [setIsWeb2LoggedIn]);

    useEffect(() => {
        const unsubscribe = listenForAuthChanges();
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setActiveButton('board');
                break;
            case '/activity':
                setActiveButton('activity');
                break;
            case '/market':
                setActiveButton('market');
                break;
            default:
                setActiveButton('');
                break;
        }
    }, [location.pathname]);

    const listenForAuthChanges = () => {
        return onAuthStateChanged(auth, (user) => {
            setIsWeb2LoggedIn(user);
        });
    };

    const handleInviteClick = () => {
        const link = `https://betamood.moodweather.io/login?referralId=${signedAccountId || isWeb2LoggedIn.email}`;
        setReferralLink(link);
        setShowModal(true);
    };

    const copyToClipboard = () => {
        const textArea = document.createElement("textarea");
        textArea.value = referralLink;
        document.body.appendChild(textArea);
        textArea.select();
        try {
            document.execCommand('copy');
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy');
        }
        document.body.removeChild(textArea);
        setTimeout(() => setCopySuccess(''), 1500);
    };

    const handleLogout = () => {
        if (signedAccountId) {
            localStorage.removeItem("image");
            localStorage.removeItem("quote");
            localStorage.removeItem("mood");
            localStorage.removeItem("title");
            localStorage.removeItem("selectedCollection");
            logOut();
        } else if (isWeb2LoggedIn) {
            signOut(auth).then(() => {
                setIsWeb2LoggedIn(false);
                setDropdownVisible(false);
            }).catch(console.error);
        }
    };

    const handleToggleDropdown = () => setDropdownVisible(!dropdownVisible);

    const handleOutsideClick = (event) => {
        if (dropdownVisible && !event.target.closest('.UserAreaContainerNoCSS')) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, [dropdownVisible]);

    // const totalEstValueNEAR = walletValue.reduce((total, status) => total + (status.value ? convertWeiToMegaEther(status.value) : 0), 0);
    // const totalEstValueUSD = walletValue.reduce((total, status) => total + (status.usd_value ? status.usd_value : 0), 0);
    // const formattedTotalEstValueNEAR = walletValue.toLocaleString();
    // const formattedTotalEstValueNEAR = totalEstValueNEAR.toLocaleString();
    // const formattedTotalEstValueUSD = totalEstValueUSD.toLocaleString();

    return (
        <React.Fragment>
            <div className="ResponsiveNavbar">
                <Navbar.Brand as={Link} to="/">
                    <div className="MoodWeatherLogo">
                        <LazyLoadImage
                            alt="MOOD Wide Logo"
                            effect="blur"
                            src={MOODWideLogo}
                            placeholderSrc={MOODWideLogo}
                            className="MOODWideLogo"
                        />
                        <LazyLoadImage
                            alt="MOOD Logo"
                            effect="blur"
                            src={MOODLogo}
                            placeholderSrc={MOODLogo}
                            className="MOODLogo"
                        />
                    </div>
                </Navbar.Brand>

                <div className="NavbarContainer">
                    <Nav className="NavbarMenu">
                        <Nav.Link as={Link} to="/" onClick={() => setActiveButton('board')}
                            className={activeButton === 'board' ? 'BoardButton active' : 'BoardButton'}>
                            <div className="BoardTag">Board</div>
                        </Nav.Link>

                        <Nav.Link as={Link} to="/activity" onClick={() => setActiveButton('activity')}
                            className={activeButton === 'activity' ? 'ActivityButton active' : 'ActivityButton'}>
                            <div className="ActivityTag">Activity</div>
                        </Nav.Link>

                        <Nav.Link as={Link} to="/market" onClick={() => setActiveButton('market')}
                            className={activeButton === 'market' ? 'MarketButton active' : 'MarketButton'}>
                            <div className="MarketTag">Market</div>
                        </Nav.Link>
                    </Nav>
                </div>

                {signedAccountId || isWeb2LoggedIn ? (
                    <div className="UserAreaContainer UserAreaContainerNoCSS" onClick={handleToggleDropdown}>
                        <div className="UserInfo">
                            <div className="UserLevel" style={{ background: userPlanColor }}>
                                {userPlan ? <div>{userPlan}</div> : <div> No Wallet </div>}
                            </div>
                            <div className="TotalNFTValue">
                                <div>{totalNFTs}</div>
                                <LazyLoadImage
                                    alt="NEAR Token"
                                    effect="blur"
                                    src={MOODLogo}
                                    placeholderSrc={MOODLogo}
                                    className="NFTCollectionValue"
                                />
                            </div>
                        </div>

                        {isWeb2LoggedIn?.photoURL ? (
                            <LazyLoadImage
                                alt="User Avatar"
                                effect="blur"
                                src={isWeb2LoggedIn.photoURL}
                                placeholderSrc={isWeb2LoggedIn.photoURL}
                                className="UserAvatar"
                                style={{ border: "3px solid rgb(208, 213, 219)" }}
                                referrerPolicy="no-referrer"
                            />
                        ) : signedAccountId ? (
                            <>
                                {avatar ? (
                                    <LazyLoadImage
                                        alt="User Avatar"
                                        effect="blur"
                                        src={avatar}
                                        placeholderSrc={avatar}
                                        className="UserAvatar"
                                        style={{ border: `3px solid ${userPlanColor}` }}
                                        referrerPolicy="no-referrer"
                                    />
                                ) : (
                                    <BsPerson className="UserAvatar" style={{ border: `3px solid ${userPlanColor}` }} alt="User Avatar" />
                                )}
                            </>
                        ) : (
                            <BsPerson className="UserAvatar" style={{ border: "3px solid rgb(208, 213, 219)" }} alt="User Avatar" />
                        )}
                    </div>
                ) : (
                    <>
                        <div className="UserAreaContainerNoCSS" >
                            <div className="LoginContainer" onClick={handleToggleDropdown}>
                                <BsPerson className="LogoutAvatar" alt="User Avatar" />
                            </div>
                        </div>
                    </>
                )}

                {dropdownVisible && (
                    <>
                        <div className="DropdownOverlay" onClick={handleToggleDropdown}></div>
                        <div className="DrowdownUserArea">
                            {signedAccountId || isWeb2LoggedIn ? (
                                <>
                                    <h5>{isWeb2LoggedIn ? isWeb2LoggedIn.email : truncateAddress(signedAccountId)}</h5>
                                    <Link to="/" className="UserAreaListMobile">
                                        <div className="CircleButton" style={{ background: userPlanColor }}><BsMap /></div>
                                        <div className="Label">Board</div>
                                    </Link>

                                    <Link to="/activity" className="UserAreaListMobile">
                                        <div className="CircleButton" style={{ background: userPlanColor }}><BsListCheck /></div>
                                        <div className="Label">Activity</div>
                                    </Link>

                                    <Link to="/market" className="UserAreaListMobile">
                                        <div className="CircleButton" style={{ background: userPlanColor }}><BsCart /></div>
                                        <div className="Label">Market</div>
                                    </Link>

                                    <Link className="UserAreaList" to={isWeb2LoggedIn ? `/profile/${isWeb2LoggedIn.email}` : `/profile/${signedAccountId}`}>
                                        <div className="CircleButton" style={{ background: userPlanColor }}><BsPerson /></div>
                                        <div className="Label">Profile</div>
                                    </Link>

                                    <div className="UserAreaList" onClick={handleInviteClick}>
                                        <div className="CircleButton" style={{ background: userPlanColor }}><BsPeople /></div>
                                        <div className="Label">Invite</div>
                                    </div>

                                    <div className="UserAreaList">
                                        <div className="CircleButton" style={{ background: userPlanColor }}><BsBell /></div>
                                        <div className="Label">Notification</div>
                                    </div>

                                    <div className="LogoutButton" style={{ background: userPlanColor }} onClick={handleLogout}>Logout</div>
                                </>
                            ) : (
                                <>
                                    <h5>MoodWeather</h5>
                                    <Link to="/" className="UserAreaListMobile">
                                        <div className="CircleButton" style={{ background: '#ffb43d' }}><BsMap /></div>
                                        <div className="Label">Board</div>
                                    </Link>

                                    <Link to="/activity" className="UserAreaListMobile">
                                        <div className="CircleButton" style={{ background: '#ffb43d' }}><BsListCheck /></div>
                                        <div className="Label">Activity</div>
                                    </Link>

                                    <Link to="/market" className="UserAreaListMobile">
                                        <div className="CircleButton" style={{ background: '#ffb43d' }}><BsCart /></div>
                                        <div className="Label">Market</div>
                                    </Link>

                                    <Link to="/login" className="LogoutButton" style={{ background: '#ffb43d' }}>Login</Link>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>

            <Modal centered show={showModal} onHide={() => setShowModal(false)} className="exclusive-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Invite friend{copySuccess && <span>'s link {copySuccess}</span>}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="invite-form-text" >Share this link with your friends to invite them:</p>
                    <input readOnly type="text" value={referralLink} className="invite-form-control" onClick={(e) => e.target.select()} />
                    <p className="invite-form-text" >If a user signs up using your referral link, you will receive rewards when they register and when they connect their wallet.</p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="CloseImageButton" onClick={() => setShowModal(false)}>
                        <BsXCircle /> Close
                    </div>
                    <div className="SubmitImageButton" onClick={copyToClipboard}>
                        <BsCheckCircle /> Copy Link
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default ResponsiveNavbar;
