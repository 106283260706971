import { useEffect, useState } from 'react';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import { replaceIpfsUrl } from './Home';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

export const useFetchNFTDetails = (tokenId) => {
    const { viewMethod } = useWallet();
    const [nftDetails, setNftDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchNFTDetails = async () => {
            if (tokenId && viewMethod) {
                setIsLoading(true);
                try {
                    const metadata = await viewMethod(NFT_CONTRACT_NAME, "nft_token", { token_id: tokenId });
                    setNftDetails(metadata);
                    // console.log("ffff111 metadata", metadata);

                } catch (error) {
                    console.error('Error fetching NFT details:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchNFTDetails();
    }, [tokenId, viewMethod]);

    return { nftDetails, isLoading };
};

export const fetchNftDetails = async (viewMethod, token_id, fetchJsonData) => {
    try {
        const nftDetails = await viewMethod(NFT_CONTRACT_NAME, 'nft_token', { token_id });
        if (!nftDetails.jsonData) {
            const modifiedUrl = replaceIpfsUrl(nftDetails.metadata.reference);
            const jsonData = await fetchJsonData(modifiedUrl);
            nftDetails.jsonData = jsonData || {};
        }
        // console.log("ffff111 nftDetails", nftDetails);
        return nftDetails;
    } catch (error) {
        console.error('Failed to fetch NFT details:', error);
        return null;
    }
};

export const useFetchCollectionDetails = (viewMethod, seriesId) => {
    const [collectionDetails, setCollectionDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // This function splits the seriesId if it contains a colon and returns the first part.
    const normalizeSeriesId = (id) => {
        const [normalizedId] = id.split(':');
        return normalizedId;
    };

    useEffect(() => {
        const fetchCollectionDetails = async () => {
            if (seriesId && viewMethod) {
                setIsLoading(true);
                try {
                    // Normalize the seriesId to handle cases where it contains a colon.
                    const normalizedId = normalizeSeriesId(seriesId);
                    const details = await viewMethod(NFT_CONTRACT_NAME, "get_series_details", { id: normalizedId });
                    setCollectionDetails(details);
                } catch (error) {
                    console.error('Error fetching Collection details:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchCollectionDetails();
    }, [viewMethod, seriesId]);

    return { collectionDetails, isLoading };
};
