import React, { createContext, useState, useContext, useEffect } from 'react';
import { useWallet } from '../../gateway/web3Login/walletAuth.js';
import { useFetchNFTDetails } from '../Home/useFetchNFTDetails.js';
import { replaceIpfsUrl } from '../Home/Home.js';
const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

const planColors = {
    Standard: '#ADD8E6', // Standard color
    Gold: '#FFD700', // Gold color
    Diamond: '#83CED2', // Diamond color
    Platinum: '#4682B4', // Platinum color
};

export function validateAccountId(accountId) {
    // Validates NEAR account ID format
    return accountId && typeof accountId == 'string' &&
        (accountId.endsWith('.near') || accountId.endsWith('.testnet')) ? accountId.trim() : null;
}

export const useFetchUserInfo = () => {
    const { signedAccountId, viewMethod } = useWallet();
    const [userPlan, setUserPlan] = useState('');
    const [userPlanColor, setUserPlanColor] = useState('#d0d5db');
    const [userAdsCounter, setUserAdsCounter] = useState('');

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (signedAccountId && viewMethod) {
                try {
                    const plan = await viewMethod(NFT_CONTRACT_NAME, "get_user_plan", { account_id: signedAccountId });
                    const counter = await viewMethod(NFT_CONTRACT_NAME, "get_user_ad_counter", { account_id: signedAccountId });
                    setUserPlan(plan);
                    setUserPlanColor(planColors[plan] || planColors.Standard);
                    setUserAdsCounter(counter);
                } catch (error) {
                    console.error('Error fetching user info:', error);
                }
            }
        };

        fetchUserInfo();
    }, [signedAccountId, viewMethod]);

    return { userPlan, userPlanColor, userAdsCounter };
};

export const useFetchAllUsersInfo = (ownerId) => {
    const { viewMethod } = useWallet();
    const [otherUsersAvatar, setOtherUsersAvatar] = useState(null);
    const { nftDetails } = useFetchNFTDetails(otherUsersAvatar);
    const [otherUsersPlan, setOtherUsersPlan] = useState('');
    const [otherUsersPlanColor, setOtherUsersPlanColor] = useState('#d0d5db');
    const [otherUsersAdsState, setOtherUsersAdsState] = useState('');
    const [otherUsersAdsCounter, setOtherUsersAdsCounter] = useState('');
    const modifiedUrl = replaceIpfsUrl(nftDetails?.metadata?.media);

    useEffect(() => {
        const fetchAllUsersAvatar = async () => {
            if (validateAccountId(ownerId) && ownerId && viewMethod) {
                try {
                    const avatar = await viewMethod(NFT_CONTRACT_NAME, "get_user_avatar", { account_id: validateAccountId(ownerId) });
                    const plan = await viewMethod(NFT_CONTRACT_NAME, "get_user_plan", { account_id: validateAccountId(ownerId) });
                    const adState = await viewMethod(NFT_CONTRACT_NAME, "get_user_ad_state", { account_id: validateAccountId(ownerId) });
                    const counter = await viewMethod(NFT_CONTRACT_NAME, "get_user_ad_counter", { account_id: validateAccountId(ownerId) });

                    setOtherUsersPlan(plan);
                    setOtherUsersAvatar(avatar || "null");
                    setOtherUsersPlanColor(planColors[plan] || planColors.Standard);
                    setOtherUsersAdsState(adState);
                    setOtherUsersAdsCounter(counter);
                } catch (error) {
                    console.error('Error fetching user avatar:', error);
                }
            }
        };

        fetchAllUsersAvatar();
    }, [ownerId, viewMethod]);

    return { otherUsersPlan, otherUsersPlanColor, otherUsersAdsState, otherUsersAdsCounter, otherUsersAvatar: nftDetails ? modifiedUrl : null };
};

export const useFetchOwnerTokens = (walletAddress) => {
    const { viewMethod } = useWallet();
    const [ownerTokens, setOwnerTokens] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchJsonData = async (url) => {
        if (!url) return null;
        try {
            const modifiedUrl = replaceIpfsUrl(url);
            const response = await fetch(modifiedUrl);
            return await response.json();
        } catch (error) {
            console.error("Error fetching JSON data:", error);
            return null;
        }
    };

    useEffect(() => {
        if (viewMethod && walletAddress && validateAccountId(walletAddress)) {
            fetchOwnerTokens();
        }
    }, [viewMethod, walletAddress]);

    const fetchOwnerTokens = async () => {
        setIsLoading(true);
        try {
            const nfts = await viewMethod(NFT_CONTRACT_NAME, "nft_tokens_for_owner", { account_id: walletAddress });
            if (nfts.length === 0) {
                console.log("No NFTs found!");
                setIsLoading(false);
                return;
            }

            const ownerTokensWithJson = await Promise.all(nfts.map(async (token) => {
                const jsonData = await fetchJsonData(token.metadata.reference);
                return { ...token, jsonData: jsonData || {} };
            }));
            setOwnerTokens(ownerTokensWithJson.reverse());
        } catch (error) {
            console.error('Error fetching owner NFTs:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return { ownerTokens, isLoading };
};
