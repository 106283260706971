import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import { replaceIpfsUrl } from '../Home/Home';
import NearIcon from '../../assets/NearIcon.svg';
import { NftContractModal } from './Components/NftModal';
import { BsFilter, BsSearch, BsTags } from 'react-icons/bs';
import { useFilters, useSearch } from './Components/useSearchFilter';
import { fetchNftPrice } from './Components/priceUtils';
import { FilterModal } from '../UserProfile/UserProfile';
import { fetchNftDetails } from '../Home/useFetchNFTDetails';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ExclusiveSpinner from '../../Components/ExclusiveSpinner';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

const FavoriteContent = () => {
    const initialFilters = useMemo(() => ({ Mood: [], Weather: [], City: [], Shared: [] }), []);
    const { signedAccountId, viewMethod } = useWallet();
    const [likedNFTs, setLikedNFTs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedNFT, setSelectedNFT] = useState(null);
    const [nftModalVisible, setNFTModalVisible] = useState(false);
    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
    const [nftPrices, setNftPrices] = useState({});
    const { filters, handleFilterChange, applyFilters, resetFilters } = useFilters(initialFilters);
    const { query, handleSearchChange, filterBySearch } = useSearch();
    const [columns, setColumns] = useState(6);

    const toggleFilterModal = useCallback(() => {
        setIsFilterModalVisible((prevState) => !prevState);
    }, []);

    const fetchJsonData = useCallback(async (url) => {
        if (!url) return null;
        try {
            const response = await fetch(url);
            return await response.json();
        } catch (error) {
            console.error("Error fetching JSON data:", error);
            return null;
        }
    }, []);

    const handleNFTClick = useCallback(async (nft) => {
        if (!nft.jsonData) {
            const modifiedUrl = replaceIpfsUrl(nft.metadata.reference);
            const jsonData = await fetchJsonData(modifiedUrl);
            nft = { ...nft, jsonData: jsonData || {} };
        }
        setSelectedNFT(nft);
        setNFTModalVisible(true);
    }, [fetchJsonData]);

    useEffect(() => {
        if (viewMethod && signedAccountId && fetchJsonData) {
            const fetchLikedNFTs = async () => {
                setIsLoading(true);
                try {
                    const likedTokens = await viewMethod(NFT_CONTRACT_NAME, 'get_liked_tokens', {
                        account_id: signedAccountId,
                    });

                    const likedNFTDetails = await Promise.all(likedTokens.map(async (token_id) => {
                        const nftDetails = await fetchNftDetails(viewMethod, token_id, fetchJsonData);
                        return nftDetails;
                    }));

                    setLikedNFTs(likedNFTDetails.reverse());
                } catch (error) {
                    console.error('Failed to fetch Favorite NFTs:', error);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchLikedNFTs();
        }
    }, [viewMethod, signedAccountId, fetchJsonData]);

    useEffect(() => {
        const fetchPrices = async () => {
            const prices = {};
            for (const nft of likedNFTs) {
                const priceData = await fetchNftPrice(viewMethod, nft.token_id);
                if (priceData) {
                    prices[nft.token_id] = priceData;
                }
            }
            setNftPrices(prices);
        };

        if (likedNFTs.length > 0) {
            fetchPrices();
        }
    }, [likedNFTs, viewMethod]);

    const renderLikedNFTs = useCallback((getCardWidth, nfts) => {
        const filteredNFTs = applyFilters(filterBySearch(nfts));
        return filteredNFTs.length > 0 ? (
            <div className="NFTsContainer">
                {filteredNFTs.map((nft, index) => {
                    const modifiedUrl = replaceIpfsUrl(nft?.metadata?.media);
                    const priceData = nftPrices[nft.token_id];
                    const price = priceData ? (priceData.isListedForSale ? `${priceData.listedPrice}` : `${priceData.seriesPrice}`) : '∞';
                    const marketIcon = priceData?.isListedForSale;

                    return (
                        <div
                            className="NFTCard"
                            key={nft.token_id || index}
                            style={{ flexBasis: getCardWidth() }}
                            onClick={() => handleNFTClick(nft)}
                        >
                            <div className="ImgContainer">
                                <LazyLoadImage
                                    className="NFTImg"
                                    alt={nft?.metadata?.title || "NFT"}
                                    effect="blur"
                                    src={modifiedUrl || ""} p
                                    laceholderSrc={modifiedUrl}
                                />
                            </div>
                            <p className="NFTName">{nft?.metadata?.title || "Unknown Title"}</p>
                            <div className="NFTInfo">
                                {marketIcon && <BsTags />}
                                {price}
                                <LazyLoadImage
                                    className="NFTIcon"
                                    alt="Near Icon"
                                    effect="blur"
                                    src={NearIcon}
                                    placeholderSrc={NearIcon}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        ) : (
            <p>No Favorite NFT(s) found</p>
        );
    }, [applyFilters, filterBySearch, handleNFTClick, nftPrices]);

    const getCardWidth = () => {
        switch (columns) {
            case 3:
                return '40.5%';
            case 4:
                return '23.05%';
            case 5:
                return '18.15%';
            case 6:
                return '14.85%';
            default:
                return '14.85%';
        }
    }

    return (
        <div className="user-container">
            <div className="container-tags">
                <h1 className="content-tag">Favorite NFT(s)</h1>
                <h1 className="artwork-desc">Discover the Best Works of Art Generated by AI with MoodWeather!</h1>
            </div>

            <div className="user-nfts">
                <div className="SearchTag">
                    <div className="SearchContainer">
                        <div className="SearchBox">
                            <input
                                type="text"
                                placeholder="Search favorite NFT(s)"
                                value={query}
                                onChange={handleSearchChange}
                            />
                            <div className="SearchButton">
                                <BsSearch />
                            </div>
                        </div>
                        <div className="FunnelBox">
                            <div className="FunnelButton" onClick={toggleFilterModal}>
                                <BsFilter />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ColumnsControl">
                    <div className="ColumnsLabel">Columns</div>
                    <input
                        type="range"
                        min="3"
                        max="6"
                        value={columns}
                        onChange={(e) => setColumns(parseInt(e.target.value))}
                    />
                    <span className="ColumnsNumber">{columns}</span>
                </div>
            </div>

            <div className="user-collections">
                {isLoading ? (
                    <ExclusiveSpinner />
                ) : renderLikedNFTs(getCardWidth, likedNFTs)}
            </div>

            <NftContractModal
                show={nftModalVisible}
                onHide={() => setNFTModalVisible(false)}
                nft={selectedNFT}
            />

            <FilterModal
                show={isFilterModalVisible}
                onHide={toggleFilterModal}
                onFilterChange={handleFilterChange}
                onResetFilters={resetFilters}
                filters={filters}
                tokens={likedNFTs}
            />
        </div>
    );
};

export default FavoriteContent;
