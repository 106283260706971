import "regenerator-runtime/runtime";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style.scss";
import React from "react";
import { useAuth } from "./gateway/web2Login/oAuth";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { HomeComponent, LoginComponent, MarketComponent, CollectionDetail, ActivityComponent, ProfileComponent } from './Pages';
import ResponsiveNavbar from "./Components/ResponsiveNavbar";
import Footer from "./Components/Footer";
import { useInitWallet, useWallet } from './gateway/web3Login/walletAuth';
import { AvatarProvider } from "./Pages/UserProfile/AvatarContext";
import { LikeProvider } from "./Pages/Market/Components/LikeContext";

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

export default function App() {
  useInitWallet({ createAccessKeyFor: NFT_CONTRACT_NAME, network: 'testnet' });

  const { signedAccountId } = useWallet();
  const { isWeb2LoggedIn } = useAuth();
  const isLoggedIn = isWeb2LoggedIn || !!signedAccountId;

  return (
    <AvatarProvider>
      <LikeProvider>
        <Router>
          <div className="fixed-width">
            <ResponsiveNavbar />
            <Routes>
              <Route path="/" element={<HomeComponent />} />
              <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <LoginComponent />} />
              <Route path="/activity" element={<ActivityComponent />} />
              <Route path="/market" element={<MarketComponent />} />
              <Route path="/market/collection/:id" element={<CollectionDetail />} />
              <Route path="/profile/:walletAddress" element={<ProfileComponent />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </LikeProvider>
    </AvatarProvider>
  );
}
