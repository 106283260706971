import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import MarketMenu from './Components/MarketMenu';
import ExploreContent from './ExploreContent';
import SalesContent from './SalesContent';
import FavoriteContent from './FavoriteContent';
import OffersContent from './OffersContent';  // Assuming OffersContent is also used

const MarketComponent = ({ setSelectedCollectionId }) => {
    const [search, setSearch] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState('');

    // Derive active tab directly from search params or default to 'explore'
    const active = search.get("tab") || 'explore';

    const handleSearchChange = (query) => {
        setSearchQuery(query);
    };

    // Effect for updating URL, only trigger on manual tab change via UI
    const setActive = (newActive) => {
        if (newActive !== active) {
            setSearch({ tab: newActive });
        }
    };

    const renderContent = () => {
        switch (active) {
            case 'sales':
                return <SalesContent />;
            case 'offers':
                return <OffersContent />;
            case 'favorite':
                return <FavoriteContent />;
            case 'explore':
            default:
                return <ExploreContent onSearchChange={handleSearchChange} searchQuery={searchQuery} setSelectedCollectionId={setSelectedCollectionId} />;
        }
    };

    return (
        <div className="MainHomeContainer">
            <MarketMenu active={active} setActive={setActive} />
            <div className="MarketContainer">
                {renderContent()}
            </div>
        </div>
    );
};

export default MarketComponent;
