// External Imports
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { BsSun, BsCloud, BsSearch, BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { GoogleMap, InfoWindow, LoadScript } from '@react-google-maps/api';

// Local Imports
import WeatherComponent from './WeatherComponent';
import MoodComponent from './MoodComponent';
import { useGoogleMapsScript } from './customHooks';

// Asset Imports
import markersData from './markersData.json';
import cloudy from '../../../assets/weather/map/cloudy.svg';
import emojiSmile from '../../../assets/emojie/map/emoji-smile.svg';
import emojiLaughing from '../../../assets/emojie/map/emoji-grimace.svg';
import emojiSunglasses from '../../../assets/emojie/map/emoji-sunglasses.svg';
import emojiExpressionless from '../../../assets/emojie/map/emoji-expressionless.svg';
import emojiTear from '../../../assets/emojie/map/emoji-tear.svg';
import emojiAngry from '../../../assets/emojie/map/emoji-angry.svg';

// import mapStyles from './mapStyles.json';

const MapComponent = ({ setUserCity, cityMoodData, toggleMainContainerDisplay, isMainContainerExpanded }) => {
    const [markers, setMarkers] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [weatherData, setWeatherData] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [mapCenter, setMapCenter] = useState({ lat: 42.5, lng: 12.5 });
    const [activeButton, setActiveButton] = useState('mood');
    const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
    const [AdvancedMarkerElement, setAdvancedMarkerElement] = useState(null);
    const mapRef = useRef(null);
    const markersRef = useRef([]);

    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    // Set initial map height based on current window size
    function getMapHeight() {
        const isMobile = window.innerWidth <= 880;
        return isMobile ? '60vh' : 'calc(82vh)';
    }

    const [mapHeight, setMapHeight] = useState(getMapHeight());

    const handleResize = () => {
        setMapHeight(getMapHeight());
    };

    // Add event listener for window resize
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const mapContainerStyle = {
        width: '100%',
        maxWidth: '1268px',
        height: mapHeight,
        borderRadius: '15px 15px 0 0',
    };

    // Hook to load Google Maps script
    useGoogleMapsScript(() => {
        setIsGoogleMapsLoaded(true);
        const loadAdvancedMarker = async () => {
            const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
            setAdvancedMarkerElement(() => AdvancedMarkerElement);
        };
        loadAdvancedMarker();
    });

    // Effect to load markers from JSON data
    useEffect(() => setMarkers(markersData), []);

    useEffect(() => {
        if (isGoogleMapsLoaded && mapRef.current && AdvancedMarkerElement) {
            markersRef.current.forEach(marker => marker.setMap(null));
            markersRef.current = [];

            // filterMarkers().forEach(markerData => {
            markers.forEach(markerData => {
                const { lat, lng, city } = markerData;
                const iconUrl = activeButton === 'weather' ? cloudy : getPredominantMoodIcon(city);
                if (cityMoodData[city]) {
                    const marker = new AdvancedMarkerElement({
                        position: { lat, lng },
                        map: mapRef.current,
                        title: city,
                        content: createMarkerIcon(iconUrl)
                    });

                    marker.addListener('click', () => onMarkerClick(city));
                    markersRef.current.push(marker);
                }
            });
        }
    }, [isGoogleMapsLoaded, AdvancedMarkerElement, markers, cityMoodData, activeButton]);

    const createMarkerIcon = (iconUrl) => {
        const icon = document.createElement('div');
        icon.style.width = '25px';
        icon.style.height = '30px';
        icon.style.backgroundImage = `url(${iconUrl})`;
        icon.style.backgroundSize = 'contain';
        return icon;
    };

    // Function to fetch weather data
    const fetch5DWeather = async (city) => {
        try {
            const REACT_APP_ACCUWEATHER_API_KEY = process.env.REACT_APP_ACCUWEATHER_API_KEY;
            const response = await axios.get(`https://dataservice.accuweather.com/locations/v1/cities/search?apikey=${REACT_APP_ACCUWEATHER_API_KEY}&q=${city}`);

            if (response.data.length > 0) {
                const weatherResponse = await axios.get(`https://dataservice.accuweather.com/forecasts/v1/daily/5day/${response.data[0].Key}?apikey=${REACT_APP_ACCUWEATHER_API_KEY}&metric=true`);
                setWeatherData(weatherResponse.data);
            }
        } catch (error) {
            // console.error('Error fetching weather data', error);
        }
    };

    // Start Helper Functions
    const filterMarkers = () => {
        if (!searchInput) {
            return markers;
        }
        return markers.filter(marker => marker.city.toLowerCase().includes(searchInput.toLowerCase()));
    };

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchInput(value);

        if (value.length === 0) {
            return null;
        }

        if (value.length > 1) {
            const filteredCities = markers.filter(marker =>
                marker.city.toLowerCase().includes(value.toLowerCase())
            ).map(marker => marker.city);
            setSuggestions(filteredCities);
        } else {
            setSuggestions([]);
        }
    };

    const handleSelectSuggestion = (city) => {
        setSearchInput(city);
        setSuggestions([]);
        handleSearchSubmit(city);
    };

    const handleSearchSubmit = (city = searchInput) => {
        setUserCity(city);
        fetch5DWeather(city);

        const searchedCity = markers.find(marker => marker.city.toLowerCase() === city.toLowerCase());
        if (searchedCity) {
            setMapCenter({ lat: searchedCity.lat, lng: searchedCity.lng });
            setSelectedMarker(searchedCity);
        } else {
            setSelectedMarker(null);
        }
    };

    const handleButtonClick = (type) => {
        setActiveButton(type);
    };

    const onMarkerClick = (city) => {
        setUserCity(city);
        fetch5DWeather(city);

        const selectedCity = markers.find(marker => marker.city === city);
        if (selectedCity) {
            setSelectedMarker(selectedCity);
            setMapCenter({ lat: selectedCity.lat, lng: selectedCity.lng });
        }
    };

    const getPredominantMoodIcon = (city) => {
        const moods = cityMoodData[city];
        if (!moods) return emojiSmile; // Default icon

        let highestMood = Object.keys(moods).reduce((a, b) => moods[a] > moods[b] ? a : b);

        const moodIconPaths = {
            "Happy": emojiSmile,
            "Laughing": emojiLaughing,
            "Cool": emojiSunglasses,
            "Sunglasses": emojiSunglasses,
            "Expressionless": emojiExpressionless,
            "Dizzy": emojiTear,
            "Angry": emojiAngry,
        };

        return moodIconPaths[highestMood] || emojiSmile; // Default icon if no match
    };
    // End Helper Functions

    // Start rendering Functions
    const renderSuggestions = () => {
        if (suggestions.length === 0) {
            return null;
        }

        return (
            <div className="DrowdownCities">
                {suggestions.map((city, index) => (
                    <div
                        className="CitiesList"
                        key={index}
                        onClick={() => handleSelectSuggestion(city)}
                    >
                        {city}
                    </div>
                ))}
            </div>
        );
    };

    const renderMoodInfoWindow = () => {
        if (!selectedMarker) return null;

        const moods = cityMoodData[selectedMarker.city];
        if (!moods) return null;

        const totalMoods = Object.values(moods).reduce((sum, count) => sum + count, 0);
        let isFirst = true;

        return (
            <InfoWindow
                position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                onCloseClick={() => setSelectedMarker(null)}
            >
                <div>
                    <h3>{selectedMarker.city}</h3>
                    <MoodComponent moods={moods} totalMoods={totalMoods} isFirst={isFirst} />
                </div>
            </InfoWindow>
        );
    };

    const renderMoodMap = () => {
        if (!isGoogleMapsLoaded) return <p>Loading map...</p>;

        const filteredMarkers = filterMarkers();

        const options = {
            // styles: mapStyles,
            disableDefaultUI: true,
            zoomControl: true,
            minZoom: 5,
            maxZoom: 10,
            restriction: {
                latLngBounds: {
                    north: 85,
                    south: -85,
                    east: 180,
                    west: -180,
                },
                strictBounds: true,
            },
            mapId: 'f5e2b09fd94b83d6',
        };

        return (
            // <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={mapCenter}
                zoom={6.3}
                options={options}
                onLoad={map => (mapRef.current = map)}
            >
                {filteredMarkers.map((marker, index) => (
                    <React.Fragment key={index}>
                        {/* Markers are created in the useEffect */}
                    </React.Fragment>
                ))}
                {renderMoodInfoWindow()}
            </GoogleMap>
            // </LoadScript>
        );
    };

    const renderWeatherInfoWindow = () => {
        if (!selectedMarker || !weatherData) return null;

        return (
            <InfoWindow
                position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                onCloseClick={() => setSelectedMarker(null)}
            >
                <div>
                    <h3>{selectedMarker.city}</h3>
                    <WeatherComponent weatherData={weatherData} city={selectedMarker.city} />
                </div>
            </InfoWindow>
        );
    };

    const renderWeatherMap = () => {
        if (!isGoogleMapsLoaded) return <p>Loading map...</p>;

        const filteredMarkers = filterMarkers().filter(marker => cityMoodData[marker.city]);

        const options = {
            // styles: mapStyles,
            disableDefaultUI: true,
            zoomControl: true,
            minZoom: 5,
            maxZoom: 10,
            restriction: {
                latLngBounds: {
                    north: 85,
                    south: -85,
                    east: 180,
                    west: -180,
                },
                strictBounds: true,
            },
            mapId: 'f5e2b09fd94b83d6',
        };

        return (
            // <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={mapCenter}
                zoom={6.3}
                options={options}
                onLoad={map => (mapRef.current = map)}
            >
                {filteredMarkers.map((marker, index) => (
                    <React.Fragment key={index}>
                        {/* Markers are created in the useEffect */}
                    </React.Fragment>
                ))}
                {renderWeatherInfoWindow()}
            </GoogleMap>
            // </LoadScript>
        );
    };
    // End rendering Functions

    return (
        <>
            <div className="MapSearchContainer">
                <div className="SearchCityBox">
                    <input
                        className="SearchCityInput"
                        placeholder="Nations, Cities ..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                    />
                    {renderSuggestions()}

                    <div className="SendButtonSearchCity" onClick={() => handleSearchSubmit()}>
                        <div className="ShapeButtonSearchCity" />
                        <BsSearch className="SendIconSearchCity" />
                    </div>
                </div>

                <div className="ChangeMarkersButtonsBox">
                    <button
                        className={`WatherButton ${activeButton === 'weather' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('weather')}>
                        <BsCloud className="WeatherIcon" />
                    </button>
                    <button
                        className={`EmojiButton ${activeButton === 'mood' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('mood')}>
                        <BsSun className="EmojiIcon" />
                    </button>
                </div>
            </div>

            <div className="MapWideContainer"
                style={{ transform: isMainContainerExpanded ? 'translate3d(1140px, 26px, 1px)'  : 'translate3d(580px, 26px, 10px)' }}
            >
                <button className="ToggleMapWideButton" onClick={toggleMainContainerDisplay}>
                    {isMainContainerExpanded ? (
                        <>
                            <BsChevronLeft /> Show List
                        </>
                    ) : (
                        <>
                            <BsChevronRight />
                        </>
                    )}
                </button>
            </div>

            {activeButton === 'mood' ?
                markers.length > 0 ? renderMoodMap() : <p>No location available</p>
                :
                markers.length > 0 ? renderWeatherMap() : <p>No location available</p>
            }
        </>
    );
};

export default MapComponent;
