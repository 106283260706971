import React, { useEffect, useMemo, useState } from 'react';
import { BsBrush, BsSearch, BsFilter, BsXCircle, BsCheckCircle, BsPerson, BsFileImage, BsChatDots, BsCart, BsTags, BsFillGearFill } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import { onAuthStateChanged } from 'firebase/auth';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { useAuth, auth } from '../../gateway/web2Login/oAuth';
import { NftContractModal } from '../Market/Components/NftModal';
import { truncateAddress, convertWeiToMegaEther, formatDate } from '../../utils';
import NearIcon from '../../assets/NearIcon.svg';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import { useFetchOwnerTokens, useFetchUserInfo, useFetchAllUsersInfo, validateAccountId } from './useFetchUserInfo';
import { useFilters, useSearch } from '../Market/Components/useSearchFilter';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { replaceIpfsUrl } from '../Home/Home';
import { useAvatar } from './AvatarContext';
import { fetchNftPrice } from '../Market/Components/priceUtils';
import ExclusiveSpinner from '../../Components/ExclusiveSpinner';
import { getMoodIcon } from '../Home/Map/IconOptions';

const NFT_CONTRACT_NAME = process.env.NFT_CONTRACT_NAME;

const ProfileComponent = () => {
    const initialFilters = useMemo(() => ({ Mood: [], Weather: [], City: [], Shared: [] }), []);
    const { signedAccountId, viewMethod, logIn } = useWallet();
    const { avatar } = useAvatar();
    const { isWeb2LoggedIn, setIsWeb2LoggedIn } = useAuth();
    const { walletAddress } = useParams();
    const { userPlan, userPlanColor, userAdsCounter } = useFetchUserInfo();
    const { otherUsersPlan, otherUsersPlanColor, otherUsersAdsCounter, otherUsersAvatar } = useFetchAllUsersInfo(walletAddress);
    const { ownerTokens, isLoading } = useFetchOwnerTokens(walletAddress);
    const { filters, handleFilterChange, applyFilters, resetFilters } = useFilters(initialFilters);
    const { query, handleSearchChange, filterBySearch } = useSearch();
    const [selectedNFT, setSelectedNFT] = useState(null);
    const [nftModalVisible, setNFTModalVisible] = useState(false);
    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
    const [editAvatar, setEditAvatar] = useState(false);
    const [showFollows, setShowFollows] = useState(false);
    const [registrationDate, setRegistrationDate] = useState(null);
    const [userMoodContract, setUserMoodContract] = useState('');
    const [activities, setActivities] = useState([]);
    const [walletStatus, setWalletStatus] = useState([]);
    const [nftPrices, setNftPrices] = useState({});
    const [columns, setColumns] = useState(6);
    const [action, setAction] = useState(() => { });
    const [label, setLabel] = useState('Loading...');

    useEffect(() => {
        if (!signedAccountId) {
            setAction(() => logIn);
            setLabel('Login or create Wallet');
        }
    }, [logIn, setAction, setLabel]);

    useEffect(() => {
        const unsubscribe = listenForAuthChanges();
        return () => unsubscribe();
    }, []);

    const listenForAuthChanges = () => {
        return onAuthStateChanged(auth, (user) => {
            setIsWeb2LoggedIn(user);
        });
    };

    const handleNFTClick = (nft) => {
        setSelectedNFT(nft);
        setNFTModalVisible(true);
    };

    const formatTransactionType = (type) => {
        return type.charAt(0).toUpperCase() + type.slice(1);
    };

    const toggleFilterModal = () => {
        setIsFilterModalVisible(!isFilterModalVisible);
    };

    const updateFilters = (filterType, value, isChecked) => {
        handleFilterChange(filterType, value, isChecked);
    };

    const filteredOwnerTokens = useMemo(() => {
        const searchFiltered = filterBySearch(ownerTokens);
        return applyFilters(searchFiltered);
    }, [ownerTokens, query, filters]);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const updateSize = () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        const fetchRegistrationDate = async () => {
            if (viewMethod && walletAddress && validateAccountId(walletAddress)) {
                try {
                    const date = await viewMethod(NFT_CONTRACT_NAME, 'get_user_first_ad_date', { account_id: validateAccountId(walletAddress) });
                    setRegistrationDate(date);
                } catch (error) {
                    console.error('Error fetching registration date:', error);
                }
            };
        }
        fetchRegistrationDate();

    }, [viewMethod, walletAddress]);

    useEffect(() => {
        if (viewMethod && walletAddress && validateAccountId(walletAddress)) {
            const fetchUserMood = async () => {
                try {
                    const lastUserMood = await viewMethod(NFT_CONTRACT_NAME, 'get_last_user_mood', { account_id: validateAccountId(walletAddress) });
                    setUserMoodContract(lastUserMood || "nothing");
                } catch (error) {
                    console.error('Error fetching user mood:', error);
                }
            };

            fetchUserMood();
        }
    }, [viewMethod, walletAddress]);

    useEffect(() => {
        const fetchPrices = async () => {
            const prices = {};
            for (const token of ownerTokens) {
                const priceData = await fetchNftPrice(viewMethod, token.token_id);
                if (priceData) {
                    prices[token.token_id] = priceData;
                }
            }
            setNftPrices(prices);
        };

        if (ownerTokens.length > 0) {
            fetchPrices();
        }
    }, [ownerTokens, viewMethod]);

    const renderRegistrationDate = () => {
        return registrationDate ? formatDate(registrationDate) : '∞';
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const getCardWidth = () => {
        switch (columns) {
            case 3:
                return '40.5%';
            case 4:
                return '23.05%';
            case 5:
                return '18.15%';
            case 6:
                return '14.85%';
            default:
                return '14.85%';
        }
    }

    const HowToInteract = () => (
        <div className="service-slider-container">
            <h1>How to interact...</h1>
            <Slider {...settings}>
                <div className="service-slide">
                    <div className="service-card">
                        <BsFileImage className="icon" />
                        <h2>Personalize NFTs</h2>
                        <p>Generate unique NFTs by sharing your current emotion. Images will be created based on:</p>
                        <ul>
                            <li>The phrase of the day</li>
                            <li>Your current emotion</li>
                            <li>The weather condition</li>
                            <li>Your geographical location</li>
                        </ul>
                    </div>
                </div>

                <div className="service-slide">
                    <div className="service-card">
                        <BsSearch className="icon" />
                        <h2>Board</h2>
                        <p>Posts from all users are divided into bulletin boards, search the one that interests you the most on the map based on:</p>
                        <ul>
                            <li>Location</li>
                            <li>Prevailing Emotion</li>
                            <li>Weather Conditions</li>
                        </ul>
                    </div>
                </div>

                <div className="service-slide">
                    <div className="service-card">
                        <BsChatDots className="icon" />
                        <h2>Chat</h2>
                        <p>Interact with the global chat or privately with your friends</p>
                    </div>
                </div>

                <div className="service-slide">
                    <div className="service-card">
                        <BsCart className="icon" />
                        <h2>Market</h2>
                        <p>Sell the NFTs you've obtained or buy and bid on NFTs from other users</p>
                    </div>
                </div>
            </Slider>
        </div>
    );

    const renderWeb2Profile = () => {
        return (
            <div className="userweb2">
                <div className="userweb2-container">
                    <div className="user-panel">
                        <div className="user-section">
                            <div className="avatar-section">
                                <LazyLoadImage
                                    alt="User Avatar"
                                    effect="blur"
                                    src={isWeb2LoggedIn?.photoURL}
                                    placeholderSrc={isWeb2LoggedIn?.photoURL}
                                    className="avatar-image"
                                    style={{ border: "3px solid rgb(208, 213, 219)" }}
                                    referrerPolicy="no-referrer"
                                />
                            </div>
                            <div className="info-section">
                                <div className="username">{isWeb2LoggedIn?.email}</div>
                            </div>
                        </div>

                        <div className="user-section">
                            <div className="info-section">
                                <div className="info-item" style={{ backgroundColor: '#daa653', color: '#000' }}>
                                    To fully use this dApp, you must log in with a NEAR wallet, so login or create a new wallet and discover our new world of decentralized social media...
                                </div>

                                <Button
                                    className="login-web2toweb3"
                                    variant="outline-secondary"
                                    style={{ width: '220px' }}
                                    onClick={action}
                                >
                                    {label} <LazyLoadImage
                                        alt="Near Icon"
                                        effect="blur"
                                        src={NearIcon}
                                        placeholderSrc={NearIcon}
                                        className="login-icons"
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <HowToInteract />
            </div >
        );
    };

    const renderWeb2NoProfile = () => (
        <div className="userweb2">
            <div className="userweb2-container">
                <div className="user-panel">
                    <div className="user-section">
                        <div className="avatar-section">
                        </div>
                        <div className="info-section">
                            <div className="username">
                                This page cannot be viewed without logging in with a Gmail, Facebook or Twitter account, and other users cannot see your interactions!
                            </div>
                            <Link to="/login" className="info-item" style={{ backgroundColor: '#daa653', color: '#000' }}>
                                To fully use this dApp, you must log in with a NEAR wallet, so go to the login page and discover our new world of decentralized social media...
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <HowToInteract />
        </div >
    );

    const renderWeb3Profile = () => (
        <div className="user-container">
            <div className="user-panel">
                <div className="user-section">
                    {signedAccountId === walletAddress ? (
                        <div className="avatar-section" onClick={() => setEditAvatar(true)}>
                            {avatar ? (
                                <LazyLoadImage
                                    alt="User Avatar"
                                    effect="blur"
                                    src={avatar}
                                    placeholderSrc={avatar}
                                    className="avatar-image"
                                    style={{ border: `3px solid ${userPlanColor}` }}
                                />
                            ) : (
                                <BsPerson className="avatar-image" style={{ border: `3px solid ${userPlanColor}`, color: `${userPlanColor}` }} alt="User Avatar" />
                            )}
                            <div className="edit-avatar-button">
                                <BsBrush />
                            </div>
                        </div>
                    ) : otherUsersAvatar && otherUsersPlanColor ? (
                        <div className="avatar-section">
                            <LazyLoadImage
                                alt="User Avatar"
                                effect="blur"
                                src={otherUsersAvatar}
                                placeholderSrc={otherUsersAvatar}
                                className="avatar-image"
                                style={{ border: `3px solid ${otherUsersPlanColor}` }}
                            />
                        </div>
                    ) : (
                        <div className="avatar-section">
                            <BsPerson className="avatar-image" style={{ border: `3px solid ${otherUsersPlanColor}`, color: `${otherUsersPlanColor}` }} alt="User Avatar" />
                        </div>
                    )}

                    <div className="info-section">
                        <div className="info-item">
                            <div className="username">{truncateAddress(walletAddress)}</div>
                            <div className="subscription-date">Registration: {renderRegistrationDate()}</div>
                            <div className="friends-count">
                                <div className="users-followers" onClick={() => setShowFollows(true)}>250 Followers</div>
                                <div className="users-following" onClick={() => setShowFollows(true)}>150 Following</div>
                                <div className="users-likers">100 Likers</div>
                            </div>
                            <div className="feeling-section">
                                <span>Last feeling: </span>
                                <div className="feeling-status">{userMoodContract} {userMoodContract && getMoodIcon(userMoodContract)}</div>
                            </div>
                        </div>

                        {Array.isArray(walletStatus) && walletStatus.map((status, index) => (
                            <div key={index} className="status-item">
                                <div className="items">
                                    <span className="items-span">Holding:</span>
                                    <span className="items-span">{status.holdings_count?.toLocaleString() ?? '-'}</span>
                                </div>
                                <div className="items">
                                    <span className="items-span">Listed: </span>
                                    <span className="items-span">{`${status.holdings_listed_count?.toLocaleString() ?? '-'}/${status.holdings_count?.toLocaleString() ?? '-'}`}</span>
                                </div>
                                <div className="items">
                                    <span className="items-span">Est Value:</span>
                                    <span className="items-span">
                                        <LazyLoadImage
                                            alt="Near Icon"
                                            effect="blur"
                                            src={NearIcon}
                                            placeholderSrc={NearIcon}
                                            className="items-image"
                                        /> {status.value ? `${convertWeiToMegaEther(status.value).toLocaleString()}` : '-'}
                                    </span>
                                </div>
                                <div className="items">
                                    <span className="items-span">Vol Bought:</span>
                                    <span className="items-span">
                                        <LazyLoadImage
                                            alt="Near Icon"
                                            effect="blur"
                                            src={NearIcon}
                                            placeholderSrc={NearIcon}
                                            className="items-image"
                                            style={{ border: `3px solid ${otherUsersPlanColor}` }}
                                        /> {status.bought_volume ? `${convertWeiToMegaEther(status.bought_volume).toLocaleString()}` : '-'}
                                    </span>
                                </div>

                                {/* <div>Est Value: {status.value ? `${convertWeiToMegaEther(status.value).toLocaleString()} NEAR` : '-'} - {status.usd_value ? `$${status.usd_value.toLocaleString()}` : '-'}</div>
                            <div>Vol Bought: {status.bought_volume ? `${convertWeiToMegaEther(status.bought_volume).toLocaleString()} NEAR` : '-'} - {status.bought_usd_volume ? `$${status.bought_usd_volume.toLocaleString()}` : '-'}</div>
                            <div>Vol Sold: {status.sold_volume ? `${convertWeiToMegaEther(status.sold_volume).toLocaleString()} NEAR` : '-'} - {status.sold_usd_volume ? `$${status.sold_usd_volume.toLocaleString()}` : '-'}</div>
                            <div>Realized P/L: {status.realized_profit_loss ? `${convertWeiToMegaEther(status.realized_profit_loss).toLocaleString()} NEAR` : '-'} - {status.realized_usd_profit_loss ? `$${status.realized_usd_profit_loss.toLocaleString()}` : '-'}</div>
                            <div>Unrealized P/L: {status.unrealized_profit_loss ? `${convertWeiToMegaEther(status.unrealized_profit_loss).toLocaleString()} NEAR` : '-'} - {status.unrealized_usd_profit_loss ? `$${status.unrealized_usd_profit_loss.toLocaleString()}` : '-'}</div> */}
                            </div>
                        ))}
                    </div>
                </div>

                {signedAccountId === walletAddress ? (
                    <div className="level-section">
                        <div className="level-circle" style={{ background: userPlanColor }}>{userAdsCounter}</div>
                        <div className="level-text">{userPlan ? <>{userPlan} Plan</> : <> No Wallet </>}</div>
                        <div className="users-follow-unfollow" style={{ background: userPlanColor }}>Follow</div>
                        <div className="users-settings"><BsFillGearFill />Settings</div>
                    </div>
                ) : signedAccountId !== otherUsersAvatar ? (
                    <div className="level-section">
                        <div className="level-circle" style={{ background: otherUsersPlanColor }}>{otherUsersAdsCounter}</div>
                        <div className="level-text">{otherUsersPlan ? <>{otherUsersPlan} Plan</> : <> No Wallet </>}</div>
                        <div className="users-follow-unfollow" style={{ background: otherUsersPlanColor }}>Unfollow</div>
                    </div>
                ) : (
                    <div className="level-section">
                    </div>
                )}
            </div>

            <div className="artwork-tag">Artworks</div>

            <div className="user-nfts">
                <div className="SearchTag">
                    <div className="SearchContainer">
                        <div className="SearchBox">
                            <input
                                type="text"
                                placeholder="Search artwork(s)"
                                value={query || ''}
                                onChange={handleSearchChange}
                            />
                            <div className="SearchButton">
                                <BsSearch />
                            </div>
                        </div>
                        <div className="FunnelBox">
                            <div className="FunnelButton" onClick={toggleFilterModal}>
                                <BsFilter />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ColumnsControl">
                    <div className="ColumnsLabel">Columns</div>
                    <input
                        type="range"
                        min="3"
                        max="6"
                        value={columns}
                        onChange={(e) => setColumns(parseInt(e.target.value))}
                    />
                    <span className="ColumnsNumber">{columns}</span>
                </div>
            </div>

            <div className="user-collections">
                {isLoading ? (
                    <ExclusiveSpinner />
                ) : filteredOwnerTokens.length > 0 ? (
                    <div className="NFTsContainer">
                        {filteredOwnerTokens.map((nft, index) => {
                            const modifiedUrl = replaceIpfsUrl(nft?.metadata?.media);
                            const priceData = nftPrices[nft.token_id];
                            const price = priceData ? (priceData.isListedForSale ? `${priceData.listedPrice}` : `${priceData.seriesPrice}`) : '∞';
                            const marketIcon = priceData?.isListedForSale;

                            return (
                                <div
                                    className="NFTCard"
                                    key={index}
                                    style={{ flexBasis: getCardWidth() }}
                                    onClick={() => handleNFTClick(nft)}
                                >
                                    <div className="ImgContainer">
                                        <LazyLoadImage
                                            className="NFTImg"
                                            alt={nft.metadata.title}
                                            effect="blur"
                                            src={modifiedUrl}
                                            placeholderSrc={modifiedUrl}
                                        />
                                    </div>
                                    <p className="NFTName">{nft.metadata.title}</p>
                                    {/* <p className="CollectionName">listedCount {listedCount} | totalCount {totalCount}</p> */}

                                    <div className="NFTInfo">
                                        {marketIcon && <BsTags />}
                                        {price}
                                        <LazyLoadImage
                                            className="NFTIcon"
                                            alt="Near Icon"
                                            effect="blur"
                                            src={NearIcon}
                                            placeholderSrc={NearIcon}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p>No Artworks found</p>
                )}
            </div>

            <div className="user-transactions">
                {/* <h2 className="title">Transaction history</h2> */}
                <div className="user-nfts-transactions">
                    {/* {activities.length > 0 ? ( */}
                    <table>
                        <thead>
                            <tr>
                                <th>Transaction</th>
                                {/* <th>Item</th> */}
                                {/* <th>Rarity</th> */}
                                {/* <th>From</th> */}
                                {/* <th>To</th> */}
                                <th className="mobile-hide">Date</th>
                                <th>Balance</th>
                                <th>Tx</th>
                            </tr>
                        </thead>
                        <tbody>
                            {activities.map((activity, index) => {
                                const priceInMegaEther = activity.price ? convertWeiToMegaEther(activity.price) : '-';

                                return (
                                    <tr key={index}>
                                        <td>{formatTransactionType(activity.type)}</td>
                                        <td>{activity.nft.name}</td>
                                        {/* <td>{activity.nft.rarity || '-'}</td> */}
                                        <td>
                                            {activity.sender
                                                ? <Link to={`/profile/${activity.sender}`}>{truncateAddress(activity.sender)}</Link>
                                                : '-'}
                                        </td>
                                        <td>
                                            {activity.receiver
                                                ? <Link to={`/profile/${activity.receiver}`}>{truncateAddress(activity.receiver)}</Link>
                                                : '-'}
                                        </td>
                                        <td className="mobile-hide">
                                            <a href={`https://nearblocks.io/txns/${activity.tx_id}`}
                                                target="_blank" rel="noopener noreferrer">
                                                {new Date(activity.block_time).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}
                                            </a>
                                        </td>
                                        <td>
                                            {
                                                activity.price ?
                                                    (
                                                        <div>
                                                            <LazyLoadImage
                                                                alt="Near Icon"
                                                                effect="blur"
                                                                src={NearIcon}
                                                                placeholderSrc={NearIcon}
                                                                className="tx-history"
                                                            />
                                                            {`${priceInMegaEther}`}
                                                        </div>
                                                    )
                                                    : '-'
                                            }
                                            {/* <img className="tx-history" src={NearIcon} alt="Near Icon" />
                                                {activity.usd_price ? `$${activity.usd_price.toFixed(2)}` : '-'}
                                                {activity.price ? `${priceInMegaEther}` : '-'} */}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {/* ) : (
                     <></>
                     <p>No Transactions found</p>
                    )} */}
                </div>
            </div>
        </div>
    );

    let profileContent = null;
    if (truncateAddress(walletAddress)) {
        profileContent = renderWeb3Profile();
    } else if (isWeb2LoggedIn) {
        profileContent = renderWeb2Profile();
    } else {
        profileContent = renderWeb2NoProfile();
    }

    return (
        <>
            {profileContent}

            <NftContractModal
                show={nftModalVisible}
                onHide={() => setNFTModalVisible(false)}
                nft={selectedNFT}
            />

            <FilterModal
                show={isFilterModalVisible}
                onHide={() => setIsFilterModalVisible(false)}
                onFilterChange={updateFilters}
                onResetFilters={resetFilters}
                filters={filters}
                tokens={ownerTokens}
            />

            <UserAvatar
                show={editAvatar}
                onHide={() => setEditAvatar(false)}
            />

            <Follows
                show={showFollows}
                onHide={() => setShowFollows(false)}
            />
        </>
    );
};

export default ProfileComponent;

export const FilterModal = ({
    show,
    onHide,
    onFilterChange,
    onResetFilters,
    filters,
    tokens,
}) => {
    // Safely accumulate attribute values from tokens
    const attributeValues = tokens?.reduce((acc, token) => {
        token?.jsonData?.attributes?.forEach(attr => {
            if (!acc[attr.trait_type]) acc[attr.trait_type] = new Set();
            acc[attr.trait_type].add(attr.value);
        });
        return acc;
    }, {});

    // Convert Sets to Arrays and ensure we can handle empty cases gracefully
    const entries = Object.entries(attributeValues || {}).map(([key, valueSet]) => [key, Array.from(valueSet)]);

    return (
        <Modal centered keyboard={true} show={show} onHide={onHide} backdrop="static" className="exclusive-modal">
            <Modal.Header closeButton>
                <Modal.Title>Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="row-style">
                        {entries.length > 0 ? entries.map(([traitType, values]) => (
                            <Form.Group key={traitType} controlId={`formGroup-${traitType}`} className="group-style">
                                <Form.Label className="label-style">{traitType}</Form.Label>
                                <div className="filter-items">
                                    {values.map((value, index) => {
                                        const checkboxId = `checkbox-${traitType}-${index}`;
                                        return (
                                            <Form.Check
                                                key={checkboxId}
                                                id={checkboxId}
                                                type="checkbox"
                                                label={value}
                                                value={value}
                                                onChange={(e) => onFilterChange(traitType, value, e.target.checked)}
                                                checked={filters[traitType]?.includes(value)}
                                            />
                                        );
                                    })}
                                </div>
                            </Form.Group>
                        )) : <p>No attributes available for filtering.</p>}
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div className="CloseImageButton" onClick={onHide}>
                    Close
                </div>
                <div className="SubmitImageButton" onClick={onResetFilters}>
                    Reset
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export const UserAvatar = ({ show, onHide }) => {
    const { signedAccountId, callMethod } = useWallet();
    const { ownerTokens } = useFetchOwnerTokens(signedAccountId);
    const { userPlanColor } = useFetchUserInfo();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const { avatar, updateAvatar } = useAvatar();
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [selectedAvatarImage, setSelectedAvatarImage] = useState(null);

    const handleSelectImage = (token) => {
        const modifiedUrl = replaceIpfsUrl(token.metadata.media);
        setSelectedAvatar(token.token_id);
        setSelectedAvatarImage(modifiedUrl);
        setError('');
    };

    const handleUpdateAvatar = async () => {
        if (!selectedAvatar) {
            setError('Please select an avatar.');
            return;
        }

        setIsLoading(true);

        try {
            await callMethod(NFT_CONTRACT_NAME, "set_user_avatar", { token_id: selectedAvatar });
            updateAvatar(selectedAvatarImage);
            onHide();
        } catch (error) {
            console.error('Error setting user avatar:', error);
            setError('Failed to update avatar. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? (
                <ExclusiveSpinner />
            ) : (
                <Modal centered keyboard={true} show={show} onHide={onHide} backdrop="static" className="exclusive-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Choose your avatar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="AddCollectionCotainer">
                            {avatar || selectedAvatarImage ? (
                                <LazyLoadImage
                                    alt="User Avatar"
                                    effect="blur"
                                    src={selectedAvatarImage || avatar}
                                    placeholderSrc={NearIcon}
                                    className="avatar-image"
                                    style={{ border: `9px solid ${userPlanColor}` }}
                                    referrerPolicy="no-referrer"
                                />
                            ) : (
                                <BsPerson className="avatar-image" style={{ border: "3px solid #000" }} alt="User Avatar" />
                            )}

                            <div className="UploadCollectionContainer">
                                <div className="UploadCollectionImage">
                                    {ownerTokens.map((token, index) => {
                                        const modifiedUrl = replaceIpfsUrl(token.metadata.media);

                                        return (
                                            <div
                                                key={index}
                                                className={`ImageContainer ${selectedAvatar === token.token_id ? 'selected' : ''}`}
                                                onClick={() => handleSelectImage(token)}
                                            >
                                                <LazyLoadImage
                                                    alt={`Avatar ${index}`}
                                                    effect="blur"
                                                    src={modifiedUrl}
                                                    placeholderSrc={modifiedUrl}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            {error && <div className="error-message">{error}</div>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="CloseImageButton" onClick={onHide}>
                            <BsXCircle /> Cancel
                        </div>
                        <div className="SubmitImageButton" disabled={isLoading} onClick={handleUpdateAvatar}>
                            <BsCheckCircle /> Update
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export const Follows = ({ show, onHide }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            {isLoading ? (
                <ExclusiveSpinner />
            ) : (
                <Modal centered keyboard={true} show={show} onHide={onHide} backdrop="static" className="exclusive-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Follows</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="user-follow-container">

                            <div className="SearchTag">
                                <div className="SearchContainer">
                                    <div className="SearchBox">
                                        <input
                                            type="text"
                                            placeholder="Search artwork(s)"
                                            value={''}
                                        />
                                        <div className="SearchButton">
                                            <BsSearch />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="user-follow-list">
                                <div className="user-follow-list-container">
                                    <div className="user-follow-row">
                                        <BsPerson className="user-follow-list-avatar" alt="User Avatar" />
                                        <div className="block-user-address">Address</div>
                                        <div className="block-user-button">Remove</div>
                                    </div>
                                    <div className="user-follow-row">
                                        <BsPerson className="user-follow-list-avatar" alt="User Avatar" />
                                        <div className="block-user-address">Address</div>
                                        <div className="block-user-button">Remove</div>
                                    </div>
                                    <div className="user-follow-row">
                                        <BsPerson className="user-follow-list-avatar" alt="User Avatar" />
                                        <div className="block-user-address">Address</div>
                                        <div className="block-user-button">Remove</div>
                                    </div>
                                    <div className="user-follow-row">
                                        <BsPerson className="user-follow-list-avatar" alt="User Avatar" />
                                        <div className="block-user-address">Address</div>
                                        <div className="block-user-button">Remove</div>
                                    </div>
                                    <div className="user-follow-row">
                                        <BsPerson className="user-follow-list-avatar" alt="User Avatar" />
                                        <div className="block-user-address">Address</div>
                                        <div className="block-user-button">Remove</div>
                                    </div>
                                    <div className="user-follow-row">
                                        <BsPerson className="user-follow-list-avatar" alt="User Avatar" />
                                        <div className="block-user-address">Address</div>
                                        <div className="block-user-button">Remove</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};
