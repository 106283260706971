import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useWallet } from '../../gateway/web3Login/walletAuth';
import { replaceIpfsUrl } from '../Home/Home';
import NearIcon from '../../assets/NearIcon.svg';
import { convertWeiToMegaEther } from '../../utils';
import { NftContractModal } from './Components/NftModal';
import { BsFilter, BsSearch } from 'react-icons/bs';
import { MarketFilterModal, useFilters, useSearch } from './Components/useSearchFilter';
import { fetchNftSalesByContractId, fetchNftSalesByownerId } from './Components/priceUtils';
import { fetchNftDetails } from '../Home/useFetchNFTDetails';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const TabsComponent = ({ activeTab, setActiveTab }) => {
    return (
        <div className="tabs-container">
            <button
                className={`tab-button-global ${activeTab === 'global' ? 'active' : ''}`}
                onClick={() => setActiveTab('global')}
            >
                <span className="tab-text">Global Sales</span>
            </button>
            <button
                className={`tab-button-my ${activeTab === 'user' ? 'active' : ''}`}
                onClick={() => setActiveTab('user')}
            >
                <span className="tab-text">My Sales</span>
            </button>
        </div>
    );
};

const SalesContent = () => {
    const initialFilters = useMemo(() => ({ Mood: [], Weather: [], City: [], Shared: [] }), []);
    const { filters, handleFilterChange, applyFilters, resetFilters } = useFilters(initialFilters);
    const { query, handleSearchChange, filterBySearch } = useSearch();
    const { signedAccountId, viewMethod } = useWallet();
    const [globalSales, setGlobalSales] = useState([]);
    const [userSales, setUserSales] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedNFT, setSelectedNFT] = useState(null);
    const [nftModalVisible, setNFTModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('global');
    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);

    const toggleFilterModal = useCallback(() => {
        setIsFilterModalVisible((prevState) => !prevState);
    }, []);

    const fetchJsonData = useCallback(async (url) => {
        if (!url) return null;
        try {
            const response = await fetch(url);
            return await response.json();
        } catch (error) {
            console.error("Error fetching JSON data:", error);
            return null;
        }
    }, []);

    const handleNFTClick = useCallback(async (nft) => {
        if (!nft.jsonData) {
            const modifiedUrl = replaceIpfsUrl(nft.metadata.reference);
            const jsonData = await fetchJsonData(modifiedUrl);
            nft = { ...nft, jsonData: jsonData || {} };
        }
        setSelectedNFT(nft);
        setNFTModalVisible(true);
    }, [fetchJsonData]);

    // Update useEffect that fetches global sales
    useEffect(() => {
        if (viewMethod && fetchJsonData) {
            const fetchGlobalSales = async () => {
                setIsLoading(true);
                try {
                    const globalResult = await fetchNftSalesByContractId(viewMethod);

                    const globalSalesWithNFTs = await Promise.all(globalResult.map(async (sale) => {
                        const nftDetails = await fetchNftDetails(viewMethod, sale.token_id, fetchJsonData);

                        return { ...sale, nftDetails };
                    }));

                    setGlobalSales(globalSalesWithNFTs.reverse());
                } catch (error) {
                    console.error('Failed to fetch sales:', error);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchGlobalSales();
        }
    }, [viewMethod, fetchJsonData]);

    useEffect(() => {
        if (viewMethod && signedAccountId && fetchJsonData) {
            const fetchUserSales = async () => {
                setIsLoading(true);
                try {
                    const result = await fetchNftSalesByownerId(viewMethod, signedAccountId);

                    const userSalesWithNFTs = await Promise.all(result.map(async (sale) => {
                        const nftDetails = await fetchNftDetails(viewMethod, sale.token_id, fetchJsonData);
                        return { ...sale, nftDetails };
                    }));

                    setUserSales(userSalesWithNFTs.reverse());
                } catch (error) {
                    console.error('Failed to fetch sales:', error);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchUserSales();
        }
    }, [viewMethod, signedAccountId, fetchJsonData]);

    const renderSales = useCallback((sales, filters, applyFilters, query, filterBySearch) => {
        const filteredSales = applyFilters(filterBySearch(sales));
        return filteredSales.length > 0 ? (
            <div className="NFTsContainer">
                {filteredSales.map((sale, index) => {
                    const nft = sale.nftDetails;
                    const modifiedUrl = replaceIpfsUrl(nft?.metadata?.media);
                    const formattedSaleCondition = parseFloat(convertWeiToMegaEther(sale.sale_conditions)).toFixed(5);

                    return (
                        <div className="NFTCard" key={sale.token_id || index} onClick={() => handleNFTClick(nft)}>
                            <div className="ImgContainer">
                                <LazyLoadImage
                                    alt={nft?.metadata?.title || "NFT"}
                                    effect="blur"
                                    src={modifiedUrl || ""}
                                    placeholderSrc={modifiedUrl || ""}
                                    className="NFTImg"
                                />
                            </div>
                            <p className="NFTName">{nft?.metadata?.title || "Unknown Title"}</p>
                            <div className="NFTInfo">
                                <p className="NFTBasePrice">{formattedSaleCondition}</p>
                                <LazyLoadImage
                                    alt="Near Icon"
                                    effect="blur"
                                    src={NearIcon}
                                    placeholderSrc={NearIcon}
                                    className="NFTIcon"
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        ) : (
            <p>No Sales found</p>
        );
    }, [handleNFTClick]);

    return (
        <div className="user-container">
            <div className="user-nfts">
                <TabsComponent activeTab={activeTab} setActiveTab={setActiveTab} />

                <div className="SearchContainer">
                    <div className="SearchBox">
                        <input
                            type="text"
                            placeholder="Search for sellers"
                            value={query}
                            onChange={handleSearchChange}
                        />
                        <div className="SearchButton">
                            <BsSearch />
                        </div>
                    </div>
                    <div className="FunnelBox">
                        <div className="FunnelButton" onClick={toggleFilterModal}>
                            <BsFilter />
                        </div>
                    </div>
                </div>
            </div>

            <div className="user-collections">
                <div className="container-tags">
                    <h1 className="artwork-tag">{activeTab === 'global' ? 'Global Sales' : 'My Sales'}</h1>
                </div>
                {isLoading ? (
                    <div className="spinner-backdrop">
                        <div className="spinner-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                ) : activeTab === 'global' ? renderSales(globalSales, filters, applyFilters, query, filterBySearch) : renderSales(userSales, filters, applyFilters, query, filterBySearch)}
            </div>

            <NftContractModal
                show={nftModalVisible}
                onHide={() => setNFTModalVisible(false)}
                nft={selectedNFT}
            />

            <MarketFilterModal
                show={isFilterModalVisible}
                onHide={toggleFilterModal}
                onFilterChange={handleFilterChange}
                onResetFilters={resetFilters}
                filters={filters}
                tokens={activeTab === 'global' ? globalSales : userSales}
            />
        </div>
    );
};
export default SalesContent;
