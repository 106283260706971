import axios from "axios";

const getWeather = async (userCity) => {
    if (!userCity) {
        console.error("No city provided for weather lookup");
        return null;
    }

    try {
        const REACT_APP_ACCUWEATHER_API_KEY = process.env.REACT_APP_ACCUWEATHER_API_KEY;
        const locationResponse = await axios.get(`https://dataservice.accuweather.com/locations/v1/cities/search?apikey=${REACT_APP_ACCUWEATHER_API_KEY}&q=${userCity}`);

        if (locationResponse.data.length > 0) {
            const locationKey = locationResponse.data[0].Key;
            const weatherResponse = await axios.get(`https://dataservice.accuweather.com/currentconditions/v1/${locationKey}?apikey=${REACT_APP_ACCUWEATHER_API_KEY}&metric=true`);
            // const currentCondition = weatherResponse.data;
            const WeatherText = weatherResponse.data[0].WeatherText;
            // console.log("user weather: ", currentCondition);
            console.log("user city: ", userCity);
            console.log("user weather: ", WeatherText);

            return WeatherText;
        } else {
            console.error('No location found for the specified city');
            return null;
        }
    } catch (error) {
        console.error('Error fetching weather data:', error);
        return null;
    }
};

export default getWeather;